import { FilterBar } from "../../../components/life-filter-bar/FilterBar";
import FilterBarMobile from "../../../components/life-filter-bar/filter-bar-mobile";
import vector from '../../../assets/Vector.png'
import {
  MainContainer,
  Opacity,
  SkeletonContainer,
} from "./styles";
import { PlanCard } from "../../../components/life-plan-card/PlanCard";
import Footer from "../../../components/Footer/FooterNew";
import { Pagination } from "../../../components/pagination/Pagination";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IciciLifeQuote, IciciLifeQuoteGPP, IciciLifeQuoteGift, IciciLifeQuoteSJB, IciciLifeQuoteROP, TataLifeQuote, HdfcLifeQuote, HdfcLifePlusQuote, lifeEnquiryAPI, resetLifeQuoteRedux, TataLifeMrsQuote, removeLifeQuoteBySlug, BajajLifeQuote} from "../../../Redux/LifeRedux";
import { SkeletonComponent } from "../../../components/skeleton-component/SkeletonComponent";
// import queryString from 'query-string';
import { ViewDetailsPopup } from "../../../components/view-details-popup/ViewDetailsPopup";
import { TbSortAscendingNumbers, TbSortDescendingNumbers } from 'react-icons/tb';
import './main.css'

export const LifeMainScreen = () => {
  const [detailsInfo, setDetailsInfo] = useState(-1);
  var combinedRedux = useSelector((state) => state.life.combinedRedux);
  const [resetRedux, setResetRedux] = useState(false);
  const lifeRedux = useSelector((state) => state.life);
  // const [addons, setAddons] = useState([]);
  const [updateCover, setUpdateCover] = useState(false);
  const dispatch = useDispatch();
  const [firstCall, setFirstCall] = useState(false);
  const [reduxState, setReduxState] = useState(combinedRedux);
  const [loading, setLoading] = useState(false);
  const [moreFilters, setMoreFilters] = useState(false);
  const [selectedView, setSelectedView] = useState("list");
  const lifeQuote = useSelector((state) => state.life.lifeQuote);
  const [lifeQuoteState, setLifeQuoteState] = useState([]);
  const [viewMoreDetails, setViewMoreDetails] = useState(true);

  // For the Responsive Screen
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [viewMobileFilter, setViewMobileFilter] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const hideFilter = () => {
    setViewMobileFilter(!viewMobileFilter)
  }
  
  // for the rop checkbox 
  const [lifeQuoteData, setLifeQuoteData] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [insurancePlans, setInsurancePlans] = useState([]);

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  //   if (!isChecked && lifeQuoteData) {
  //     dispatch(IciciLifeQuoteROP(lifeQuoteData));
  //   }
  // };

    const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked && lifeQuoteData) {
      dispatch(IciciLifeQuoteROP(lifeQuoteData));

      // Add the "Insurance Plans with Premium Return" to the list
      setInsurancePlans([...insurancePlans, lifeQuoteData]);
    } else {
      // Remove the "Insurance Plans with Premium Return" from the list
      // setInsurancePlans(insurancePlans.filter((plan) => plan !== lifeQuoteData));
      lifeQuoteState?.map((res) => {
        if (res.slug === "life-icici-rop") {
          dispatch(removeLifeQuoteBySlug(res.slug));
        }
      });
  };
}


  // SORTING
const [sortingOrder, setSortingOrder] = useState('asc');
const [sortCsr, setSortCsr] = useState('asc');
const [sortedLifeQuoteState, setSortedLifeQuoteState] = useState([]);

const parseClaimSettlementRatio = (ratioString) => {
  const ratioWithoutPercentage = parseFloat(ratioString.replace('%', ''));
  return isNaN(ratioWithoutPercentage) ? 0 : ratioWithoutPercentage;
};

const sortLifeQuoteStateByPremium = (quoteState, sortingOrder) => {
  return quoteState.sort((a, b) => {
    return (sortingOrder === 'asc' ? 1 : -1) * (a.totalPriceWithoutTax - b.totalPriceWithoutTax);
  });
};

const sortLifeQuoteStateByCsr = (quoteState, sortCsr) => {
  return quoteState.sort((a, b) => {
    const csrA = parseClaimSettlementRatio(a.claimSettlementRatio);
    const csrB = parseClaimSettlementRatio(b.claimSettlementRatio);
    return (sortCsr === 'asc' ? csrA - csrB : csrB - csrA);
  });
};


useEffect(() => {
  const sortedList = sortLifeQuoteStateByPremium(lifeQuoteState, sortingOrder);
  setSortedLifeQuoteState([...sortedList]);
}, [lifeQuoteState, sortingOrder]);

useEffect(() => {
  const sortedList = sortLifeQuoteStateByCsr(lifeQuoteState, sortCsr);
  setSortedLifeQuoteState([...sortedList]);
}, [lifeQuoteState, sortCsr]);

const handlePremiumIcon = () => {
  setSortingOrder(sortingOrder === 'asc' ? 'desc' : 'asc');
};

const handleCsrIcon = () => {
  setSortCsr(sortCsr === 'asc' ? 'desc' : 'asc');
};


// // default sorting
// const sortLifeQuoteState = (quoteState) => {
//   return quoteState.sort((a, b) => {
//     const aSumAssured = a.sumAssured;
//     const bSumAssured = b.sumAssured;
//     const aCsr = parseClaimSettlementRatio(a.claimSettlementRatio);
//     const bCsr = parseClaimSettlementRatio(b.claimSettlementRatio);
//     const aPremium = a.totalPayableAmount;
//     const bPremium = b.totalPayableAmount;
//     const aPremiumPolicyTerm = a.premiumPaymentTerm;
//     const bPremiumPolicyTerm = b.premiumPaymentTerm;

//     // Count the number of fields that are greater in both items
//     let aGreaterCount =
//       (aSumAssured > bSumAssured) +
//       (aCsr > bCsr) +
//       (aPremium > bPremium) +
//       (aPremiumPolicyTerm > bPremiumPolicyTerm); // Include premiumPolicyTerm comparison
//     let bGreaterCount =
//       (bSumAssured > aSumAssured) +
//       (bCsr > aCsr) +
//       (bPremium > aPremium) +
//       (bPremiumPolicyTerm > aPremiumPolicyTerm); // Include premiumPolicyTerm comparison

//     // Sort based on the number of greater fields
//     if (aGreaterCount > bGreaterCount) {
//       return -1;
//     } else if (aGreaterCount < bGreaterCount) {
//       return 1;
//     }

//     // If the number of greater fields is the same, compare each field individually
//     if (aSumAssured !== bSumAssured) {
//       return aSumAssured - bSumAssured;
//     }

//     if (aCsr !== bCsr) {
//       return aCsr - bCsr;
//     }

//     if (aPremium !== bPremium) {
//       return aPremium - bPremium;
//     }

//     // Compare the premiumPolicyTerm field
//     return aPremiumPolicyTerm - bPremiumPolicyTerm;
//   });
// };

const sortLifeQuoteState = (quoteState) => {
  return quoteState.sort((a, b) => {
    const aPremium = a.totalPriceWithoutTax;
    const bPremium = b.totalPriceWithoutTax;

    // Sort by price (lowest price first)
    return aPremium - bPremium;
  });
};


useEffect(() => {
  const sortedList = sortLifeQuoteState(lifeQuoteState);
  setSortedLifeQuoteState([...sortedList]);
}, [lifeQuoteState]);

// SORTING END

  useEffect(() => {
    const fetchData = async () => {
      const nObj = { ...combinedRedux };
      const { _id, ...rest } = nObj;
      const hasEnquiryId = rest && rest.hasOwnProperty("enquiryId");

      if (hasEnquiryId) {
        console.log("lifeQuote is", lifeQuote);

        if (lifeQuote.length === 0) {
          await callQuoteAPIs();
        }
      } else {
        const queryParameter = new URLSearchParams(window.location.search);
        const enquiryIds = queryParameter.get("enquiryId");
        if (enquiryIds) {
          const obj = { enquiryId: enquiryIds };
          await dispatch(lifeEnquiryAPI(obj));
          setFirstCall(true);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (firstCall) {
      callQuoteAPIs();
    }
  }, [firstCall]);

  useEffect(() => {
    setLifeQuoteState([...lifeQuote]);
  }, [lifeQuote]);

  useEffect(() => {
    if (lifeQuote.length > 0) {
      setLoading(false);
    }
  }, [lifeQuote]);

  useEffect(() => {
    if (combinedRedux && resetRedux) {
      callQuoteAPIs();
      setResetRedux(false);
    }
  }, [combinedRedux, resetRedux]);

  const callQuoteAPIs = async () => {
    setResetRedux(false);
    // console.log("CallQuoteAPIs are ----->", combinedRedux);

    try {
    // start
    if (combinedRedux && combinedRedux.enquiryId) {
      // Make the lifeEnquiryAPI call and wait for its response
      const response = await dispatch(lifeEnquiryAPI({ enquiryId: combinedRedux.enquiryId }));
      const lifeQuoteData = response.payload.data;

      // Reset lifeQuoteRedux
      dispatch(resetLifeQuoteRedux(''));

      switch (combinedRedux.planType) {
        case "term":
          // Dispatch all term-related API calls and wait for their responses
          await Promise.all([
            dispatch(IciciLifeQuote(lifeQuoteData)),
            dispatch(IciciLifeQuoteSJB(lifeQuoteData)),
            dispatch(TataLifeQuote(lifeQuoteData)),
            dispatch(TataLifeMrsQuote(lifeQuoteData)),
            dispatch(HdfcLifeQuote(lifeQuoteData)),
            dispatch(HdfcLifePlusQuote(lifeQuoteData)),
            dispatch(BajajLifeQuote(lifeQuoteData)),
            isChecked ? dispatch(IciciLifeQuoteROP(lifeQuoteData)) : Promise.resolve(), // Optional dispatch
          ]);
          break;
        case "endowment":
          // Dispatch all endowment-related API calls and wait for their responses
          await Promise.all([
            dispatch(IciciLifeQuoteGPP(lifeQuoteData)),
            dispatch(IciciLifeQuoteGift(lifeQuoteData)),
          ]);
          break;
        default:
          break;
      }

      // Proceed to the next step here, as all API calls have completed
    }
  } catch (error) {
    console.error("Error in fetchAndProcessQuotes:", error);
  }
  // end
  };



  
  const ViewBar = ({ currentPlan, totalPlans, activeView }) => {
    return (
    <div className="h-div-apart">
      <div className="menuText">
        <div className="textOne">
          <p style={{ width: '240px' }}>Company & Plan Name</p>
          <p className="mainText">Sum Assured</p>
          <p className="mainText">Cover Till Age</p>
          <p className="mainText">Policy Term</p>
          <p className="mainText">Premium Payment <br /> Term</p>
          {combinedRedux?.planType === "endowment" && <p className="mainText">Total <br /> Benefit</p>}
          {combinedRedux?.planType === "endowment" && <p className="mainText">Total Benefit/<br /> Premium Ratio</p>}

          <p className="mainText csr-column" onClick={handleCsrIcon}>Claim Settlement Ratio
            {sortCsr === 'asc' ? (
              <TbSortAscendingNumbers style={{ color: '#430643', fontSize: '20px', marginLeft: '10px' }} />
            ) : (
              <TbSortDescendingNumbers style={{ color: '#430643', fontSize: '20px', marginLeft: '10px' }} />
            )}
          </p>
          {combinedRedux?.planType === "endowment" && <p className="mainText">Customer <br /> IRR</p>}

          <p className="mainText premium-column" onClick={handlePremiumIcon}>
            Premium
            {sortingOrder === 'desc' ? (
              <TbSortDescendingNumbers style={{ color: '#41025F', fontSize: '20px', marginLeft: '10px' }} />
            ) : (
              <TbSortAscendingNumbers style={{ color: '#41025F', fontSize: '20px', marginLeft: '10px' }} />
            )}
          </p>
        </div>
      </div>
    </div>
    );
  };

  const ViewBarForMobile = ({ currentPlan, totalPlans, activeView }) => {
     return (
      <div className="sorting">
          <div>
            <label onClick={hideFilter} htmlFor="dropdown" className='labIcon'>Filters <span><img style={{paddingLeft:'2px'}} src={vector} alt='ram' /></span></label>
          </div>
          <div>
          <p onClick={handleCsrIcon} className="sort-item">
              CSR
              {sortCsr === 'asc' ? (
                <TbSortAscendingNumbers style={{ color: '#430643', fontSize: '15px', marginLeft: '2px' }} />
              ) : (
                <TbSortDescendingNumbers style={{ color: '#430643', fontSize: '15px', marginLeft: '2px' }} />
              )}
            </p>
            <p onClick={handlePremiumIcon} className="sort-item" style ={{marginLeft:"10px"}}>
              Premium
              {sortingOrder === 'desc' ? (
                <TbSortDescendingNumbers style={{ color: '#41025F', fontSize: '15px', marginLeft: '2px' }} />
              ) : (
                <TbSortAscendingNumbers style={{ color: '#41025F', fontSize: '15px', marginLeft: '2px' }} />
              )}
            </p>

          </div>
      </div>

     )
  };

  return (
    <div className={screenWidth > 768 && screenWidth < 1024 ? "tabletContainer" : ''}>
      <MainContainer>
      {
        screenWidth <= 1024  ? (
          viewMobileFilter ? 
        <FilterBarMobile
          loading={loading}
          setLoading={setLoading}
          more={moreFilters}
          onShowMore={setMoreFilters}
          CallQuoteAPIs={callQuoteAPIs}
          updateCover={updateCover}
          isCheckedMenu={isChecked}
          handleCheckboxChange={handleCheckboxChange}
          slugData = {lifeQuote}
          policyTermData = {lifeQuoteState[0]?.policyTerm}
        /> : null
        ) : (
          <FilterBar
          loading={loading}
          setLoading={setLoading}
          more={moreFilters}
          onShowMore={setMoreFilters}
          CallQuoteAPIs={callQuoteAPIs}
          updateCover={updateCover}
          isCheckedMenu={isChecked}
          handleCheckboxChange={handleCheckboxChange}
          slugData = {lifeQuote}
          policyTermData = {lifeQuoteState[0]?.policyTerm}
        />
        )
      }

        { loading ? (
          <SkeletonContainer>
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
          </SkeletonContainer>
        ) : (
          <>
          {
            screenWidth > 1024 ? 
            <ViewBar
              activeView={"list"}
              currentPlan={15}
              totalPlans={450}
              className="viewbar"
            />
            : <ViewBarForMobile />
          }
            {selectedView === "list" ? (
              sortedLifeQuoteState &&
              sortedLifeQuoteState.length > 0 &&
              sortedLifeQuoteState.map((item, index) => {
                if (item != null) {
                  return (
                    <PlanCard
                      index={index}
                      view={selectedView}
                      key={item.id}
                      item={item}
                      viewMoreDetails={viewMoreDetails}
                      setViewMoreDetails={setViewMoreDetails}
                      responseData={item.responseData}
                      setDetailsInfo={setDetailsInfo}               
                      // setAddons={setAddons}
                    />
                  );
                }
              })
            ) : (
              <div className="grid gtc-4 mt-1">
                {
                  //  [...motorQuote]
                  // .sort((a, b) => (a.price) < (b.price) ? 1 : -1)
                  sortedLifeQuoteState &&
                    sortedLifeQuoteState.length > 0 &&
                    sortedLifeQuoteState.map((item, index) => {
                      if (item != null) {
                        return (
                          <PlanCard
                            index={index}
                            view={selectedView}
                            key={item.id}
                            item={item}
                            viewMoreDetails={viewMoreDetails}
                            setViewMoreDetails={setViewMoreDetails}
                            responseData={item.responseData}
                            setDetailsInfo={setDetailsInfo}
                            // setAddons={setAddons}
                          />
                        );
                      }
                    })
                }
              </div>
            )}
          </>
        )}
      </MainContainer>
      {/* <Pagination current={1} total={12} /> */}
      <Pagination total={1} currentPage={1} pageSize={10} />
      <Footer />

      <>
        {detailsInfo > -1 && (
          <>
            <Opacity />
            <ViewDetailsPopup
              // addons={addons}
              // setAddons={setAddons}
              item={detailsInfo}
              setDetailsInfo={setDetailsInfo}
              setUpdateCover={setUpdateCover}
            />
          </>
        )}
        {/* <Opacity />
                <ViewDetailsPopup /> */}
      </>
    </div>
  );
};
