import React from 'react';
import { MainDiv } from "./styles"
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  IciciLifeQuote,
  lifeEnquiryAPI,
  resetRedux,
  HDFCLifeQuote,
  lifeQuoteUpdate,
  updateFirstPageData,
  lifeQuoteUpdateForRop,
  addonsUpdateHdfc,
  addonsUpdateHdfcPlus,
  addonsUpdateTataSrs,
  addonsUpdateTataMrs,
  BajajLifeQuote,
} from '../../Redux/LifeRedux';
import { useEffect, useState } from 'react';
import './gridCss.css';
import { SkeletonCard } from "../../pages/life-insurance/skeleton-card/SkeletonCard";
import {Modal} from 'antd'
import handleAlert from './../hanleAlert';
import calculatePayouts from "../../utilities/DBO"
import { InfoCircleOutlined } from '@ant-design/icons';
import formatSumAssured from "../../utilities/calculateSum";
import PlainCardMobile from "./plain-card-mobile";



export const PlanCard = ({item, viewMoreDetails, setViewMoreDetails}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // for screen fontSize

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



// console.log("🚀 ~ file: PlanCard.js:21 ~ item:", item)
const payout = calculatePayouts(item.sumAssured, item.mode, item.LumpsumPercentage)
const combinedRedux = useSelector((state) => state.life.combinedRedux);

   const [loading,setLoading]=useState(false)
   const [cover,setCover]=useState("")
  const [adhbValue, setAdhbValue] = useState(combinedRedux?.ADHB ? combinedRedux.ADHB : 0 );
  const [ciValue, setCiValue] = useState(combinedRedux?.CIBenefit ? combinedRedux?.CIBenefit : 0);
  const [selectedOption, setSelectedOption] = useState(''); // This will store the selected option

  // for hdfc addons
    const [inad, setInad] = useState(combinedRedux?.isINAD === true ? true : false);
    const [c4ci, setC4ci] = useState(combinedRedux?.isC4CI === true ? true : false);
    const [pprPac, setPprPac] = useState(combinedRedux?.isPPR_PAC === true ? true : false);
    const [hprCc, setHprCc] = useState(combinedRedux?.isHPR_CC === true ? true : false);
    const [hprCci, setHprCci] = useState(combinedRedux?.isHPR_CCI === true ? true : false);
    const [pprAdc, setPprAdc] = useState(combinedRedux?.isPPR_ADC === true ? true : false);
    const [hdfcRider, setHdfcRider] = useState(null)
    const [isRop, setIsRop] = useState(combinedRedux?.isRop === true ? true : false);
    const [isWopCI, setIsWopCI] = useState(combinedRedux?.isWopCI === true ? true : false);
    
    // for hdfc Plus addons
    const [hprPlusCc, setHprPlusCc] = useState(combinedRedux?.isHPR_CCPlus === true ? true : false);
    const [inadPlus, setInadPlus] = useState(combinedRedux?.isINADPlus === true ? true : false);
    const [c4ciPlus, setC4ciPlus] = useState(combinedRedux?.isC4CIPlus === true ? true : false);
    const [hdfcPlusRider , setHdfcPlusRider] = useState(null)
    const [isRopPlus, setIsRopPlus] = useState(combinedRedux?.isRopPlus === true ? true : false);
    const [isWopCIPlus, setIsWopCIPlus] = useState(combinedRedux?.isWopCIPlus === true ? true : false);

    // for TATA SRS addons
    const [isTATA_adb, setIsTATA_adb] = useState(combinedRedux?.isTATA_adb === true ? true : false);
    const [isTATA_tpd, setIsTATA_tpd] = useState(combinedRedux?.isTATA_tpd === true ? true : false);
    const [isTATA_cbp, setIsTATA_cbp] = useState(combinedRedux?.isTATA_cbp === true ? true : false);
    const [isTATA_hcb, setIsTATA_hcb] = useState(combinedRedux?.isTATA_hcb === true ? true : false);
    const [tataRider, setTataRider] = useState(null)

    // for TATA MRSS addons
    const [isTATA_MRS_adb, setIsTATA_MRS_adb] = useState(combinedRedux?.isTATA_MRS_adb === true ? true : false);
    const [isTATA_MRS_tpd, setIsTATA_MRS_tpd] = useState(combinedRedux?.isTATA_MRS_tpd === true ? true : false);
    // const [isTATA_MRS_cbp, setIsTATA_MRS_cbp] = useState(combinedRedux?.isTATA_MRS_cbp === true ? true : false);
    const [isTATA_MRS_hcb, setIsTATA_MRS_hcb] = useState(combinedRedux?.isTATA_MRS_hcb === true ? true : false);
    const [tataMrsRider, setTataMrsRider] = useState(null)


    useEffect(() => {
      if (item.slug === "life-hdfc") {
        setHdfcRider(item.existedRider);
      }
    }, [item])

    useEffect (() => {
      if (item.slug === "life-hdfc-plus") {
        setHdfcPlusRider(item.existedRider);

      }
    }, [item])

    useEffect(() => {
      if (item.slug === "tata-life-srp") {
        setTataRider(item?.riders);
      }
    }, [item])

    useEffect(() => {
      if (item.slug === "tata-mrss") {
        setTataMrsRider(item?.riders);
      }
    }, [item])

    useEffect(() => {
    if (c4ci === true && hprCci === true) {
      alert('Both Critical Illness Plus Rider and Comprehensive CI Cannot be Selected together')
    }
  }, [hprCci, c4ci])


    const excludeFromHBAndCi = ['life-icici-sjb', 'bajaj-life']
    const onlyForPayout = ['life-icici', 'life-icici-rop']

    const[show,setShow]=useState(false)
     let handleModal=()=>{
    setShow(!show)
  }
    const navigate = useNavigate();
    const [firstCall, setFirstCall] = useState(false);
    const [reduxState, setReduxState] = useState(combinedRedux);
    const lifeQuote = useSelector((state) => state.life.lifeQuote);
    const dispatch = useDispatch();
    const [errorTooltip, setErrorTooltip] = useState(''); // Error tooltip text
    
    // handle details
    const handleDetails = () => {
        setViewMoreDetails(!viewMoreDetails);
    }

    const handleAdhbInputChange = (e) => {
       const newValue = e.target.value;
    // Check if the entered value is within the allowed range
      if (newValue <= 20000000) {
        if (newValue > combinedRedux.sumAssured) {
          handleAlert(item?.sluginfo?.name, "ADHB value should not exceed the base sum assured.")
          setAdhbValue(combinedRedux.sumAssured);
        } else{
        setAdhbValue(newValue);  
        }
        
      }
      updateSelectedOption(Number(e.target.value), ciValue);
    };

    const handleCiInputChange = (e) => {
      const newValue = e.target.value;
    // Check if the entered value is within the allowed range
      if (newValue <= 10000000) {
        if (newValue > combinedRedux.sumAssured) {
          handleAlert(item?.sluginfo?.name, "CI value should not exceed the base sum assured.")
          setCiValue(combinedRedux.sumAssured);
        } else {
          setCiValue(newValue);  
        }

      }
      updateSelectedOption(adhbValue, Number(e.target.value));
    };

    // const updateSelectedOption = (adhb, ci) => {
    //   if (adhb !== 0 && ci !== 0) {
    //     setSelectedOption('all');
    //   } else if (adhb === 0 && ci !== 0) {
    //     setSelectedOption('CI');
    //   } else if (ci === 0 && adhb !== 0) {
    //     setSelectedOption('ADHB');
    //   } else {
    //     setSelectedOption('Life'); // Default value when both are 0
    //   }
    // };

    
  const updateSelectedOption = (adhb, ci) => {

    if (
      (ci === 0 || (ci >= 100000 && ci <= 10000000)) &&
      (adhb === 0 || (adhb >= 100000 && adhb <= 20000000))
    ) {
      if (adhb !== 0 && ci !== 0) {
        setSelectedOption('all');
        setErrorTooltip('');
      } else if (adhb === 0 && ci !== 0) {
        setSelectedOption('CI');
        setErrorTooltip('');
      } else if (ci === 0 && adhb !== 0) {
        setSelectedOption('ADHB');
        setErrorTooltip('');
      } else {
        setSelectedOption('Life'); // Default value when both are 0
        setErrorTooltip('');
      }
    } else {
      // handleAlert(item?.sluginfo?.name, "Please enter CI value between 1 lakh and 1 crore, and ADHB value between 1 lakh and 2 crore.")
      setErrorTooltip("Critical illness add on varies from 1lac to 1cr. Accidental death varies from 1lac to 2cr.");
      setSelectedOption('Life'); 
    }
  };

    // for prevent to pass any value
    const handleKeyPress = (event) => {
  const keyCode = event.which || event.keyCode;

  // Check if the entered key is not numeric (keycodes 48 to 57) or is an empty space
  if ((keyCode < 48 || keyCode > 57) || event.target.value === " ") {
    event.preventDefault(); // Prevent input of non-numeric characters and empty spaces
  }
};
useEffect(() => {
  setAdhbValue(combinedRedux?.ADHB || 0);
  setCiValue(combinedRedux?.CIBenefit || 0);
  setSelectedOption(combinedRedux?.selectedOption || 'Life');
}, [
  combinedRedux?.ADHB,
  combinedRedux?.CIBenefit,
  combinedRedux?.selectedOption,
  combinedRedux
]);


   const applyAddOns = async () => {
    const queryParameter = new URLSearchParams(window.location.search);
    const enquiryIds = queryParameter.get("enquiryId");

    let newCover = "Life"; // Default value

    if (selectedOption === 'all') {
      newCover = "All in One";
    } else if (selectedOption === 'ADHB') {
      newCover = "Life Plus";
    } else if (selectedOption === 'CI') {
      newCover = "Life and Health";
    }



    setLoading(true);

    try {
      dispatch(
        updateFirstPageData({
          LifeCoverOption: newCover,
          ADHB: adhbValue,
          CIBenefit: ciValue,
          enquiryId: enquiryIds,
        })
      ).then ((res) => {
        if (item.slug === "life-icici-rop") {
          dispatch(lifeQuoteUpdateForRop(res.payload));
        } else if (item.slug === "life-icici") {
          dispatch(lifeQuoteUpdate(res.payload));
        }
      });
    } catch (error) {
      console.error("Error updating database:", error);
    } finally {
      setLoading(false);
    }
  };

    useEffect(() => {
        var nObj = reduxState;
        nObj = removeKey("_id", nObj);
        if (nObj && nObj.hasOwnProperty("enquiryId")) {
        if (lifeQuote.length < 1) {
        CallQuoteAPIs();
        }
        } else {
        const queryParameter = new URLSearchParams(window.location.search)
        var enquiryIds = queryParameter.get("enquiryId");
        // if(combinedReduxInsure == null) {
        var obj = { enquiryId: enquiryIds };
        dispatch(lifeEnquiryAPI(obj));
        setFirstCall(true);
        }
       
    }, [])

    const removeKey = (key, object) => {
        const { [key]: gone, ...rest } = object;
        return rest;
    };
     
      const CallQuoteAPIs = () => {
        resetRedux(false);
        dispatch(IciciLifeQuote(combinedRedux))
    };

  // addons of the hdfc
  const applyAddOnsForHdfc = async (inad, c4ci, pprPac, hprCc, hprCci, pprAdc, isRop, isWopCI) => {
  const queryParameter = new URLSearchParams(window.location.search);
  const enquiryIds = queryParameter.get("enquiryId");

  setLoading(true);

  try {
    const res = await dispatch(
      updateFirstPageData({
        isINAD: inad,
        isC4CI: c4ci,
        isPPR_PAC: pprPac,
        isHPR_CC: hprCc,
        isHPR_CCI: hprCci,
        isPPR_ADC: pprAdc,
        isRop: isRop,
        isWopCI: isWopCI,
        enquiryId: enquiryIds,
      })
    );
    
    if (item.slug === "life-hdfc") {
      dispatch(addonsUpdateHdfc(res.payload));
    }

    console.log("Data: ", res.payload.data, "Life Quote Updated.");
  } catch (error) {
    console.error("Error updating database:", error);
  } finally {
    setLoading(false);
  }
};

    // Checkbox change handlers
    useEffect(() => {
      setInad(!!combinedRedux?.isINAD);
      setC4ci(!!combinedRedux?.isC4CI);
      setPprPac(!!combinedRedux?.isPPR_PAC);
      setHprCc(!!combinedRedux?.isHPR_CC);
      setHprCci(!!combinedRedux?.isHPR_CCI);
      setPprAdc(!!combinedRedux?.isPPR_ADC);
      setIsRop(!!combinedRedux?.isRop);
      setIsWopCI(!!combinedRedux?.isWopCI);
    }, [
      combinedRedux?.isINAD,
      combinedRedux?.isC4CI,
      combinedRedux?.isPPR_PAC,
      combinedRedux?.isHPR_CC,
      combinedRedux?.isHPR_CCI,
      combinedRedux?.isPPR_ADC,
      combinedRedux?.isRop,
      combinedRedux?.isWopCI,
      combinedRedux,
    ]);


    const handleInad = () => {
      setInad(!inad);
        applyAddOnsForHdfc(!inad, c4ci, pprPac, hprCc, hprCci, pprAdc, isRop, isWopCI);
    };

    const handleC4ci = () => {
      setC4ci(!c4ci);
        applyAddOnsForHdfc(inad, !c4ci, pprPac, hprCc, hprCci, pprAdc, isRop, isWopCI);
    };

    const handlePprPac = () => {
      setPprPac(!pprPac);
      applyAddOnsForHdfc(inad, c4ci, !pprPac, hprCc, hprCci, pprAdc, isRop, isWopCI);
    }

    const handleHprCc = () => {
      setHprCc(!hprCc);
        applyAddOnsForHdfc(inad, c4ci, pprPac, !hprCc, hprCci, pprAdc, isRop, isWopCI);
    }

    const handleHprCci = () => {
      setHprCci(!hprCci);
      applyAddOnsForHdfc(inad, c4ci, pprPac, hprCc, !hprCci, pprAdc, isRop, isWopCI);
    }

    const handlePprAdc = () => {
      setPprAdc(!pprAdc);
      applyAddOnsForHdfc(inad, c4ci, pprPac, hprCc, hprCci, !pprAdc, isRop, isWopCI);
    }

    // for rop and wop
    const handleRop = () => {
    // Check if policyTerm is between 5 and 40
    if (item?.policyTerm < 5 || item?.policyTerm > 40) {
        // Display an alert
        alert("Policy term must be between 5 and 40.");
        return; // Exit the function
    }

    
      setIsRop(!isRop);
      applyAddOnsForHdfc(inad, c4ci, pprPac, hprCc, hprCci, pprAdc, !isRop, isWopCI);
    }

    const handleWopCI = () => {
      setIsWopCI(!isWopCI);
      applyAddOnsForHdfc(inad, c4ci, pprPac, hprCc, hprCci, pprAdc, isRop, !isWopCI);
    }


    // hdfc Plus addons rider
  const applyAddOnsForHdfcPlus = async (inad, c4ci, hprCc, isRopPlus, isWopCIPlus) => {
  const queryParameter = new URLSearchParams(window.location.search);
  const enquiryIds = queryParameter.get("enquiryId");

  setLoading(true);

  try {
    const res = await dispatch(
      updateFirstPageData({
        isINADPlus: inad,
        isC4CIPlus: c4ci,
        isHPR_CCPlus: hprCc,
        isRopPlus: isRopPlus,
        isWopCIPlus: isWopCIPlus,
        enquiryId: enquiryIds,
      })
    );
    
    if (item.slug === "life-hdfc-plus") {
      dispatch(addonsUpdateHdfcPlus(res.payload));
    }

    console.log("Data: ", res.payload.data, "Life Quote Updated.");
  } catch (error) {
    console.error("Error updating database:", error);
  } finally {
    setLoading(false);
  }
};

useEffect(() => {

  setInadPlus(!!combinedRedux?.isINADPlus);
  setC4ciPlus(!!combinedRedux?.isC4CIPlus);
  setHprPlusCc(!!combinedRedux?.isHPR_CCPlus);
  setIsRopPlus(!!combinedRedux?.isRopPlus);
  setIsWopCIPlus(!!combinedRedux?.isWopCIPlus);
}, [
  combinedRedux?.isINADPlus,
  combinedRedux?.isC4CIPlus,
  combinedRedux?.isHPR_CCPlus,
  combinedRedux?.isRopPlus,
  combinedRedux?.isWopCIPlus,
  combinedRedux
]);


    const handleInadPlus = () => {
      setInadPlus(!inadPlus);
      applyAddOnsForHdfcPlus(!inadPlus, c4ciPlus, hprPlusCc, isRopPlus, isWopCIPlus);
    };

    const handleC4ciPlus = () => {
      setC4ciPlus(!c4ciPlus);
      applyAddOnsForHdfcPlus(inadPlus, !c4ciPlus, hprPlusCc, isRopPlus, isWopCIPlus);
    };

    const handleHprPlusCc = () => {
      setHprPlusCc(!hprPlusCc);
      applyAddOnsForHdfcPlus(inadPlus, c4ciPlus, !hprPlusCc, isRopPlus, isWopCIPlus);
    }

    // for rop and wop
    // const handleRopPlus = () => {
    //   setIsRopPlus(!isRopPlus);
    //   applyAddOnsForHdfcPlus(inadPlus, c4ciPlus, hprPlusCc, !isRopPlus, isWopCIPlus);
    // }


    const handleRopPlus = () => {
    // Check if policyTerm is between 5 and 40
    if (item?.policyTerm < 5 || item?.policyTerm > 40) {
        alert("Policy term must be between 5 and 40.");
        return;
    }

    // If policyTerm is within the range, proceed with toggling isRopPlus and applying addons
    setIsRopPlus(!isRopPlus);
    applyAddOnsForHdfcPlus(inadPlus, c4ciPlus, hprPlusCc, !isRopPlus, isWopCIPlus);
}


    const handleWopCIPlus = () => {
      setIsWopCIPlus(!isWopCIPlus);
      applyAddOnsForHdfcPlus(inadPlus, c4ciPlus, hprPlusCc, isRopPlus, !isWopCIPlus);
    }



    // for tata srs addons
  const riderHandlerTataSrs = async (isTATA_adb, isTATA_tpd, isTATA_cbp, isTATA_hcb) => {
  const queryParameter = new URLSearchParams(window.location.search);
  const enquiryIds = queryParameter.get("enquiryId");

  setLoading(true);

  try {
    const res = await dispatch(
      updateFirstPageData({
        isTATA_adb: isTATA_adb,
        isTATA_tpd: isTATA_tpd,
        isTATA_cbp: isTATA_cbp,
        isTATA_hcb: isTATA_hcb,
        enquiryId: enquiryIds,
      })
    );
    
    if (item.slug === "tata-life-srp") {
      dispatch(addonsUpdateTataSrs(res.payload));
    }
  } catch (error) {
    console.error("Error updating database:", error);
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  setIsTATA_adb(!!combinedRedux?.isTATA_adb);
  setIsTATA_tpd(!!combinedRedux?.isTATA_tpd);
  setIsTATA_cbp(!!combinedRedux?.isTATA_cbp);
  setIsTATA_hcb(!!combinedRedux?.isTATA_hcb);
}, [
  combinedRedux?.isTATA_adb,
  combinedRedux?.isTATA_tpd,
  combinedRedux?.isTATA_cbp,
  combinedRedux?.isTATA_hcb,
  combinedRedux
]);


    //  for tata srs checkbox
    const handleTataAdb = () => {
      setIsTATA_adb(!isTATA_adb);
      riderHandlerTataSrs(!isTATA_adb, isTATA_tpd, isTATA_cbp, isTATA_hcb);
    }

    const handleTataTpd = () => {
      setIsTATA_tpd(!isTATA_tpd);
      riderHandlerTataSrs(isTATA_adb, !isTATA_tpd, isTATA_cbp, isTATA_hcb);
    }

    const handleTataCbp = () => {
      setIsTATA_cbp(!isTATA_cbp);
      riderHandlerTataSrs(isTATA_adb, isTATA_tpd, !isTATA_cbp, isTATA_hcb);
    }

    const handleTataHcb = () => {
      setIsTATA_hcb(!isTATA_hcb);
      riderHandlerTataSrs(isTATA_adb, isTATA_tpd, isTATA_cbp, !isTATA_hcb);
    }
    // for tata srs addons
  const riderHandlerTataMrs = async (isTATA_MRS_adb, isTATA_MRS_tpd, isTATA_MRS_hcb) => {
  const queryParameter = new URLSearchParams(window.location.search);
  const enquiryIds = queryParameter.get("enquiryId");

  setLoading(true);

  try {
    const res = await dispatch(
      updateFirstPageData({
        isTATA_MRS_adb: isTATA_MRS_adb,
        isTATA_MRS_tpd: isTATA_MRS_tpd,
        isTATA_MRS_hcb: isTATA_MRS_hcb,
        enquiryId: enquiryIds,
      })
    );
    
    if (item.slug === "tata-mrss") {
      dispatch(addonsUpdateTataMrs(res.payload));
    }
  } catch (error) {
    console.error("Error updating database:", error);
  } finally {
    setLoading(false);
  }
};

    //  for tata MRSS checkbox
    useEffect(() => {
    setIsTATA_MRS_adb(!!combinedRedux?.isTATA_MRS_adb);
    setIsTATA_MRS_tpd(!!combinedRedux?.isTATA_MRS_tpd);
    setIsTATA_MRS_hcb(!!combinedRedux?.isTATA_MRS_hcb);
}, [
    combinedRedux?.isTATA_MRS_adb,
    combinedRedux?.isTATA_MRS_tpd,
    combinedRedux?.isTATA_MRS_hcb,
    combinedRedux
]);

    const handleTataMrsAdb = () => {
      setIsTATA_MRS_adb(!isTATA_MRS_adb);
      riderHandlerTataMrs(!isTATA_MRS_adb, isTATA_MRS_tpd, isTATA_MRS_hcb);
    }

    const handleTataMrsTpd = () => {
      setIsTATA_MRS_tpd(!isTATA_tpd);
      riderHandlerTataMrs(isTATA_MRS_adb, !isTATA_MRS_tpd, isTATA_MRS_hcb);
    }
    // const handleTataMrsCbp = () => {
    //   setIsTATA_MRS_cbp(!isTATA_MRS_cbp);
    //   riderHandlerTataMrs(isTATA_MRS_adb, isTATA_MRS_tpd, !isTATA_MRS_cbp, isTATA_MRS_hcb);
    // }

    const handleTataMrsHcb = () => {
      setIsTATA_MRS_hcb(!isTATA_MRS_hcb);
      riderHandlerTataMrs(isTATA_MRS_adb, isTATA_MRS_tpd, !isTATA_MRS_hcb);
    }


        return (
          <div>
            {screenWidth <= 1024 ? (
              <PlainCardMobile
                item={item}
                viewMoreDetails={viewMoreDetails}
                handleDetails={handleDetails}
                ciValue={ciValue}
                handleCiInputChange={handleCiInputChange}
                handleKeyPress={handleKeyPress}
                adhbValue={adhbValue}
                handleAdhbInputChange={handleAdhbInputChange}
                applyAddOns={applyAddOns}
                payout={payout}
                onlyForPayout={onlyForPayout}
                errorTooltip={errorTooltip}
                excludeFromHBAndCi={excludeFromHBAndCi}
                inad={inad}
                c4ci={c4ci}
                pprPac={pprPac}
                hprCc={hprCc}
                hprCci={hprCci}
                pprAdc={pprAdc}
                hdfcRider={hdfcRider}
                hprPlusCc={hprPlusCc}
                inadPlus={inadPlus}
                c4ciPlus={c4ciPlus}
                hdfcPlusRider={hdfcPlusRider}
                isTATA_adb={isTATA_adb}
                isTATA_tpd={isTATA_tpd}
                isTATA_cbp={isTATA_cbp}
                isTATA_hcb={isTATA_hcb}
                tataRider={tataRider}
                handleInad={handleInad}
                handleC4ci={handleC4ci}
                handlePprPac={handlePprPac}
                handleHprCc={handleHprCc}
                handleHprCci={handleHprCci}
                handlePprAdc={handlePprAdc}
                handleInadPlus={handleInadPlus}
                handleC4ciPlus={handleC4ciPlus}
                handleHprPlusCc={handleHprPlusCc}
                handleTataAdb={handleTataAdb}
                handleTataTpd={handleTataTpd}
                handleTataCbp={handleTataCbp}
                handleTataHcb={handleTataHcb}
                isTATA_MRS_adb={isTATA_MRS_adb}
                isTATA_MRS_tpd={isTATA_MRS_tpd}
                isTATA_MRS_hcb={isTATA_MRS_hcb}
                tataMrsRider={tataMrsRider}
                riderHandlerTataMrs={riderHandlerTataMrs}
                handleTataMrsAdb={handleTataMrsAdb}
                handleTataMrsTpd={handleTataMrsTpd}
                handleTataMrsHcb={handleTataMrsHcb}
                isRop={isRop}
                isWopCI={isWopCI}
                handleRop={handleRop}
                handleWopCI={handleWopCI}
                isRopPlus={isRopPlus}
                isWopCIPlus={isWopCIPlus}
                handleRopPlus={handleRopPlus}
                handleWopCIPlus={handleWopCIPlus}
              />
            ) : (
              <div>
                <MainDiv
                  className={
                    item.slug === 'life-icici-rop' ? 'ropContainer' : ''
                  }
                >
                  <div className="logo-container" style={{ width: '240px' }}>
                    <div className="head">
                      <img
                        className="head-img"
                        src={item?.sluginfo?.logo}
                        alt="logo"
                      />
                      {/* <p className="sec">{item?.sluginfo?.name}</p> */}
                      <p className="sec">
                        {item?.sluginfo?.name
                          .split(' ')
                          .map((word, index, arr) => {
                            const isLife = word.toLowerCase() === 'life';
                            const isPlus = word.toLowerCase() === 'plus';
                            return (
                              <React.Fragment key={index}>
                                {isLife || isPlus ? (
                                  <span
                                    style={{
                                      color: '#ff3300',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {word}
                                  </span>
                                ) : (
                                  word
                                )}
                                {index !== arr.length - 1 ? ' ' : null}
                              </React.Fragment>
                            );
                          })}
                      </p>
                    </div>
                    {/* <div className="text-sec"> */}

                    {/* <p style={{fontSize:'10px', marginBottom:'3px'}}>{item.slug === "life-icici-rop" ? 'RETURN OF PREMIUM PLAN':"A TERM PLAN"}</p> */}
                    {/* <button className="btn-sec" onClick={() => handleAlert(item?.sluginfo?.name, "This Plan's Features Coming Soon..")}>Details</button>  */}
                    {/* </div> */}
                  </div>
                  <hr />
                  {/* Sum Assured */}
                  <div style={{ width: '160px' }}>
                    <p>
                      ₹
                      {formatSumAssured(
                        item?.sumAssured ? item?.sumAssured : '-'
                      )}
                    </p>
                  </div>
                  <hr />
                  {/* Cover till age */}
                  <div
                    className={
                      combinedRedux?.planType === 'endowment'
                        ? 'plainText'
                        : 'plainTextEnd'
                    }
                  >
                    <p>{item?.coverTillAge ? item?.coverTillAge : '-'} Years</p>
                  </div>
                  <hr />
                  {/* Policy Term */}
                  <div
                    className={
                      combinedRedux?.planType === 'endowment'
                        ? 'plainText'
                        : 'plainTextEnd'
                    }
                  >
                    <p>{item?.policyTerm} Years</p>
                  </div>
                  <hr />
                  {/* Premium Payment Term */}
                  <div
                    className={
                      combinedRedux?.planType === 'endowment'
                        ? 'plainText'
                        : 'plainTextEnd'
                    }
                  >
                    <p>{item?.premiumPaymentTerm} Years</p>
                  </div>
                  <hr />
                  {combinedRedux?.planType === 'endowment' ? (
                    <>
                      <div
                        className={
                          combinedRedux?.planType === 'endowment'
                            ? 'plainText'
                            : 'plainTextEnd'
                        }
                      >
                        <p>{item?.totalBenefit ? item?.totalBenefit : '-'}</p>
                      </div>
                      <hr />
                      <div
                        className={
                          combinedRedux?.planType === 'endowment'
                            ? 'plainText'
                            : 'plainTextEnd'
                        }
                      >
                        <p>{item?.totalBenefit ? item?.totalBenefit : '-'}</p>
                      </div>
                      <hr />
                    </>
                  ) : null}
                  {/* mode */}
                  {/* <div className = {combinedRedux?.planType === "endowment" ? "plainText" : "plainTextEnd"}>
                    <p>{item?.mode ? item?.mode : "-"}</p>
                </div>
                <hr /> */}
                  {/* Claim Settle Ratio */}
                  <div
                    className={
                      combinedRedux?.planType === 'endowment'
                        ? 'plainText'
                        : 'plainTextEnd'
                    }
                  >
                    <p className="bold">
                      {item?.claimSettlementRatio
                        ? item?.claimSettlementRatio
                        : '-'}
                    </p>
                  </div>
                  <hr />
                  {/* Cost Per Day */}
                  {/* <div className = {combinedRedux?.planType === "endowment" ? "plainText" : "plainTextEnd"}>
                    <p className="bold">₹{typeof item?.costPerDay === 'number' ? item?.costPerDay.toFixed(2) : '-'}</p>
                </div>
                <hr /> */}
                  {/* Tax and amount */}
                  <div
                    style={{ width: '150px', marginLeft: '2rem' }}
                    className="btn-container no-flex"
                  >
                    {/* {loading ? <button className="price-btn">Loading...</button> : */}
                    <button onClick={handleModal} className="price-btn">
                      ₹
                      {parseInt(item?.totalPriceWithoutTax).toLocaleString(
                        'en-IN',
                        { maximumFractionDigits: 2 }
                      )}{' '}
                      + ₹
                      {parseInt(item.serviceTax).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                      })}{' '}
                      Tax
                      <br /> Pay {item.mode}
                    </button>
                    {/* } */}
                    <Modal
                      className="modal"
                      open={show}
                      onOk={handleModal}
                      onCancel={handleModal}
                      width={800}
                      radius={20}
                    >
                      <SkeletonCard
                        continueBtn={true}
                        item={item}
                        title={'Save Details'}
                        prevPolicy={true}
                      />
                    </Modal>
                  </div>
                </MainDiv>
                <div className="detailModal">
                  <div className="detailModal__header">
                    <div>
                      {item.slug === 'life-hdfc' ? (
                        <div>
                          <div>
                            <input
                              type="checkbox"
                              id="isRop"
                              className="check"
                              value={isRop}
                              checked={isRop}
                              onChange={handleRop}
                            />
                            <label className="checkText">
                              Return of Premium{' '}
                            </label>
                          </div>
                        </div>
                      ) : item.slug === 'life-hdfc-plus' ? (
                        <div>
                          <div>
                            <input
                              type="checkbox"
                              id="isRopPlus"
                              className="check"
                              value={isRopPlus}
                              checked={isRopPlus}
                              onChange={handleRopPlus}
                            />
                            <label className="checkText">
                              Return of Premium{' '}
                            </label>
                          </div>
                        </div>
                      ) : !excludeFromHBAndCi.includes(item.slug) ? (
                        <p>
                          ✓ Waiver of Premium Cover{' '}
                          <span
                            style={{ color: '#41025F', fontWeight: 'bolder' }}
                          >
                            free
                          </span>
                        </p>
                      ) : null}

                      {!excludeFromHBAndCi.includes(item.slug) &&
                        viewMoreDetails && (
                          <div style={{ marginTop: '10px' }}>
                            {item.slug === 'tata-mrss' ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    id="isTATA_MRS_adb"
                                    className="check"
                                    value={isTATA_MRS_adb}
                                    checked={isTATA_MRS_adb}
                                    onChange={handleTataMrsAdb}
                                  />
                                  <label className="checkText">
                                    Extra Payout for Accidental Death Benefit{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {tataMrsRider?.map((rider) =>
                                        rider.RiderCode == 'ADBLIR1NW1'
                                          ? `₹${
                                              parseFloat(rider.RiderPremium) +
                                              parseFloat(rider.RiderST)
                                            }`
                                          : null
                                      )}
                                    </span>
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="checkbox"
                                    id="isTATA_MRS_tpd"
                                    className="check"
                                    value={isTATA_MRS_tpd}
                                    checked={isTATA_MRS_tpd}
                                    onChange={handleTataMrsTpd}
                                  />
                                  <label className="checkText">
                                    Extra Payout for Accidental Disability
                                    Benefit{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {tataMrsRider?.map((rider) =>
                                        rider.RiderCode === 'TPDLIR1NW1'
                                          ? `₹${
                                              parseFloat(rider.RiderPremium) +
                                              parseFloat(rider.RiderST)
                                            }`
                                          : null
                                      )}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ) : item.slug === 'life-hdfc-plus' ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    id="inadPlus"
                                    className="check"
                                    value={inadPlus}
                                    checked={inadPlus}
                                    onChange={handleInadPlus}
                                  />
                                  <label className="checkText">
                                    Income benefit on accidental disability{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {hdfcPlusRider?.INAD
                                        ? `₹${
                                            parseFloat(
                                              hdfcPlusRider['INAD'].price
                                            ) +
                                            parseFloat(
                                              hdfcPlusRider['INAD'].tax
                                            )
                                          }`
                                        : null}
                                    </span>
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="checkbox"
                                    id="c4ciPlus"
                                    className="check"
                                    value={c4ciPlus}
                                    checked={c4ciPlus}
                                    onChange={handleC4ciPlus}
                                  />
                                  <label className="checkText">
                                    Extra payout On Critical illness plus rider{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {hdfcPlusRider?.C4CI
                                        ? `₹${
                                            parseFloat(
                                              hdfcPlusRider['C4CI'].price
                                            ) +
                                            parseFloat(
                                              hdfcPlusRider['C4CI'].tax
                                            )
                                          }`
                                        : null}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ) : item.slug === 'tata-life-srp' ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    id="tata_adb"
                                    className="check"
                                    value={isTATA_adb}
                                    checked={isTATA_adb}
                                    onChange={handleTataAdb}
                                  />
                                  <label className="checkText">
                                    Extra Payout for Accidental Death Benefit{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {tataRider?.map((rider) =>
                                        rider.RiderCode === 'ADBLIR1NV1'
                                          ? `₹${
                                              parseFloat(rider.RiderPremium) +
                                              parseFloat(rider.RiderST)
                                            }`
                                          : null
                                      )}
                                    </span>
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="checkbox"
                                    id="tata_tpd"
                                    className="check"
                                    value={isTATA_tpd}
                                    checked={isTATA_tpd}
                                    onChange={handleTataTpd}
                                  />
                                  <label className="checkText">
                                    Extra Payout for Accidental Disability
                                    Benefit{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {tataRider?.map((rider) =>
                                        rider.RiderCode === 'TPDLIR1NV1'
                                          ? `₹${
                                              parseFloat(rider.RiderPremium) +
                                              parseFloat(rider.RiderST)
                                            }`
                                          : null
                                      )}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ) : item.slug === 'life-hdfc' ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    id="inad"
                                    className="check"
                                    value={inad}
                                    checked={inad}
                                    onChange={handleInad}
                                  />
                                  <label className="checkText">
                                    Income benefit on accidental disability{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {hdfcRider?.INAD
                                        ? `₹${
                                            parseFloat(
                                              hdfcRider['INAD'].price
                                            ) +
                                            parseFloat(hdfcRider['INAD'].tax)
                                          }`
                                        : null}
                                    </span>
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="checkbox"
                                    id="c4ci"
                                    className="check"
                                    value={c4ci}
                                    checked={c4ci}
                                    onChange={handleC4ci}
                                  />
                                  <label className="checkText">
                                    Extra payout On Critical illness plus rider{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {hdfcRider?.C4CI
                                        ? `₹${
                                            parseFloat(
                                              hdfcRider['C4CI'].price
                                            ) +
                                            parseFloat(hdfcRider['C4CI'].tax)
                                          }`
                                        : null}
                                    </span>
                                  </label>
                                </div>
                                <div>
                                  <input
                                    type="checkbox"
                                    id="pprAdc"
                                    className="check"
                                    value={pprAdc}
                                    checked={pprAdc}
                                    onChange={handlePprAdc}
                                  />
                                  <label className="checkText">
                                    Extra payout On Accidental death cover{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {hdfcRider?.PPR_ADC
                                        ? `₹${
                                            parseFloat(
                                              hdfcRider['PPR_ADC'].price
                                            ) +
                                            parseFloat(hdfcRider['PPR_ADC'].tax)
                                          }`
                                        : null}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <label
                                  htmlFor={`${item.slug}+2`}
                                  className="checkText"
                                >
                                  Extra payout On Critical Illness:
                                  {item.CIRPremium > 0 ? (
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      ₹{item.CIRPremium + item.ServiceTax_CIR}
                                    </span>
                                  ) : null}
                                </label>
                                <input
                                  type="text"
                                  id="ciInput"
                                  className="addonsInput"
                                  placeholder="Enter CI Amount"
                                  value={ciValue}
                                  onChange={handleCiInputChange}
                                  onKeyPress={handleKeyPress}
                                />
                                <label className="newLabel">
                                  {formatSumAssured(ciValue) < 100
                                    ? formatSumAssured(ciValue) // Show value without ₹ symbol and "only"
                                    : `₹${formatSumAssured(ciValue)} only`}{' '}
                                  {/* Show value with ₹ symbol and "only" */}
                                </label>
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                    <div>
                      {item.slug === 'life-hdfc' ? (
                        <div>
                          <div>
                            <input
                              type="checkbox"
                              id="isWopCI"
                              className="check"
                              value={isWopCI}
                              checked={isWopCI}
                              onChange={handleWopCI}
                            />
                            <label className="checkText">
                              Waiver of Premium{' '}
                            </label>
                          </div>
                        </div>
                      ) : item.slug === 'life-hdfc-plus' ? (
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div>
                            <input
                              type="checkbox"
                              id="isWopCIPlus"
                              className="check"
                              value={isWopCIPlus}
                              checked={isWopCIPlus}
                              onChange={handleWopCIPlus}
                            />
                            <label className="checkText">
                              Waiver of Premium{' '}
                            </label>
                          </div>
                        </div>
                      ) : !excludeFromHBAndCi.includes(item.slug) ? (
                        <p>
                          ✓ 100% Payout on Terminal illness{' '}
                          <span
                            style={{ color: '#41025F', fontWeight: 'bolder' }}
                          >
                            free
                          </span>
                        </p>
                      ) : null}
                      {!excludeFromHBAndCi.includes(item.slug) &&
                        viewMoreDetails && (
                          <div style={{ marginTop: '10px' }}>
                            {item.slug === 'tata-mrss' ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    id="isTATA_MRS_hcb"
                                    className="check"
                                    value={isTATA_MRS_hcb}
                                    checked={isTATA_MRS_hcb}
                                    onChange={handleTataMrsHcb}
                                  />
                                  <label className="checkText">
                                    Extra Payout Hospital Care Benefit{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {tataMrsRider?.map((rider) =>
                                        rider.RiderCode === 'HCBLIR1NW1'
                                          ? `₹${
                                              parseFloat(rider.RiderPremium) +
                                              parseFloat(rider.RiderST)
                                            }`
                                          : null
                                      )}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ) : item.slug === 'life-hdfc-plus' ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    id="hprPlusCc"
                                    className="check"
                                    value={hprPlusCc}
                                    checked={hprPlusCc}
                                    onChange={handleHprPlusCc}
                                  />
                                  <label className="checkText">
                                    Extra payout on health plus rider{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {hdfcPlusRider?.HPR_CC
                                        ? `₹${
                                            parseFloat(
                                              hdfcPlusRider['HPR_CC'].price
                                            ) +
                                            parseFloat(
                                              hdfcPlusRider['HPR_CC'].tax
                                            )
                                          }`
                                        : null}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ) : item.slug === 'tata-life-srp' ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    id="tata_cbp"
                                    className="check"
                                    value={isTATA_cbp}
                                    checked={isTATA_cbp}
                                    onChange={handleTataCbp}
                                  />
                                  <label className="checkText">
                                    Extra Payout for Criticare Plus Benefit{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {tataRider?.map((rider) =>
                                        rider.RiderCode === 'CBPLIR1NV1'
                                          ? `₹${
                                              parseFloat(rider.RiderPremium) +
                                              parseFloat(rider.RiderST)
                                            }`
                                          : null
                                      )}
                                    </span>
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="checkbox"
                                    id="tata_hcb"
                                    className="check"
                                    value={isTATA_hcb}
                                    checked={isTATA_hcb}
                                    onChange={handleTataHcb}
                                  />
                                  <label className="checkText">
                                    Extra Payout Hospital Care Benefit{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {tataRider?.map((rider) =>
                                        rider.RiderCode === 'HCBLIR1NV1'
                                          ? `₹${
                                              parseFloat(rider.RiderPremium) +
                                              parseFloat(rider.RiderST)
                                            }`
                                          : null
                                      )}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ) : item.slug === 'life-hdfc' ? (
                              <div>
                                <div>
                                  <input
                                    type="checkbox"
                                    id="pprPac"
                                    className="check"
                                    value={pprPac}
                                    checked={pprPac}
                                    onChange={handlePprPac}
                                  />
                                  <label className="checkText">
                                    Extra payout on Personal Accident cover{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {hdfcRider?.PPR_PAC?.price
                                        ? `₹${
                                            parseFloat(
                                              hdfcRider['PPR_PAC'].price
                                            ) +
                                            parseFloat(hdfcRider['PPR_PAC'].tax)
                                          }`
                                        : null}
                                    </span>
                                  </label>
                                </div>
                                <div>
                                  <input
                                    type="checkbox"
                                    id="hprCc"
                                    className="check"
                                    value={hprCc}
                                    checked={hprCc}
                                    onChange={handleHprCc}
                                  />
                                  <label className="checkText">
                                    Extra payout on health plus rider{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {hdfcRider?.HPR_CC
                                        ? `₹${
                                            parseFloat(
                                              hdfcRider['HPR_CC'].price
                                            ) +
                                            parseFloat(hdfcRider['HPR_CC'].tax)
                                          }`
                                        : null}
                                    </span>
                                  </label>
                                </div>
                                <div>
                                  <input
                                    type="checkbox"
                                    id="hprCci"
                                    className="check"
                                    value={hprCci}
                                    checked={hprCci}
                                    onChange={handleHprCci}
                                  />
                                  <label className="checkText">
                                    Extra payout on Comprehensive Critical
                                    Illness{' '}
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      {hdfcRider?.HPR_CCI
                                        ? `₹${
                                            parseFloat(
                                              hdfcRider['HPR_CCI'].price
                                            ) +
                                            parseFloat(hdfcRider['HPR_CCI'].tax)
                                          }`
                                        : null}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <label
                                  htmlFor={`${item.slug}+1`}
                                  className="checkText"
                                >
                                  Extra payout On Accidental Death:
                                  {item.ADBRPremium > 0 ? (
                                    <span
                                      style={{
                                        color: '#41025F',
                                        fontWeight: 'bolder',
                                      }}
                                    >
                                      ₹{item.ADBRPremium + item.ServiceTax_ADB}
                                    </span>
                                  ) : null}
                                </label>
                                <input
                                  type="text"
                                  id="adhbInput"
                                  value={adhbValue}
                                  placeholder="Enter ADHB Amount"
                                  className="addonsInput"
                                  onChange={handleAdhbInputChange}
                                  onKeyPress={handleKeyPress}
                                />
                                <button
                                  className="addonsButton"
                                  onClick={applyAddOns}
                                >
                                  Get Addons
                                </button>
                                <label className="newLabel">
                                  {formatSumAssured(adhbValue) < 100
                                    ? formatSumAssured(adhbValue) // Show value without ₹ symbol and "only"
                                    : `₹${formatSumAssured(
                                        adhbValue
                                      )} only`}{' '}
                                  {/* Show value with ₹ symbol and "only" */}
                                </label>
                              </div>
                            )}
                          </div>
                        )}
                    </div>

                    <div className="lastDetailBox">
                      {!excludeFromHBAndCi.includes(item.slug) ? (
                        <p onClick={handleDetails}>
                          {!viewMoreDetails ? '+2 More' : 'Show less'}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="specialPlan">
                    <div>
                      {errorTooltip && (
                        <p className="paraAdd">
                          <InfoCircleOutlined /> {'  '}
                          {errorTooltip}
                        </p>
                      )}
                    </div>
                    <div>
                      {onlyForPayout.includes(item.slug) ? (
                        item.DeathBenefitOption === 'Lump-Sum-Income' ? (
                          <p>
                            {' '}
                            <span
                              style={{ color: '#560756', fontWeight: 'bolder' }}
                            >
                              Note:
                            </span>{' '}
                            {payout.incomePercentage}% of your sum assured is
                            your monthly income, which is{' '}
                            <span
                              style={{ color: '#41025F', fontWeight: 'bolder' }}
                              id="monthly-income"
                            >
                              ₹{payout.incomeValue}
                            </span>{' '}
                            per month, and {payout.lumpsumPercentage}% is your
                            lump-sum payout, which is{' '}
                            <span
                              style={{ color: '#41025F', fontWeight: 'bolder' }}
                              id="lump-sum-payout"
                            >
                              ₹{payout.lumpsumValue}
                            </span>
                            .
                          </p>
                        ) : null
                      ) : null}
                    </div>

                    {/* <p className="specialPlan_text"><span className="specialPlan_span">★ Special Plan</span> Coming Soon..</p> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
}