import React from 'react';
import './policyDetailsSection.css';
import insuranceCompanies from './../../../utilities/insuranceCompany';

const branchData = [
  {
    id: '3',
    branch:
      'Bangalore No. 25/1, 2nd fl., bldng no. 2, Shankarnarayana bldng no. 1, MG Rd',
  },
  {
    id: '53',
    branch:
      'Nagpur 8th floor, Shriram Tower, 702 A-Wing, Kingsway, Sadar, Nagpur Nagpur',
  },
];

 const motorPolicies = [
   {
     value: 'Motor Commercial Vehicle (Goods Carrier) Policy',
     label: 'Motor Commercial Vehicle (Goods Carrier) Policy',
   },
   {
     value: 'Motor Commercial Vehicle (Passenger) Policy',
     label: 'Motor Commercial Vehicle (Passenger) Policy',
   },
   { value: 'Motor Miscellaneous Policy', label: 'Motor Miscellaneous Policy' },
   {
     value: 'Motor Private Car Package Policy',
     label: 'Motor Private Car Package Policy',
   },
   {
     value: 'Motor Third Party Liability Policy',
     label: 'Motor Third Party Liability Policy',
   },
   { value: 'Motor Two Wheeler Policy', label: 'Motor Two Wheeler Policy' },
   { value: 'Private Car Liability Only', label: 'Private Car Liability Only' },
   { value: 'STANDALONE POLICY', label: 'STANDALONE POLICY' },
   { value: 'Two Wheeler Liability Only', label: 'Two Wheeler Liability Only' },
 ];
 

const PolicyDetailsSection = ({ formData, handleChange }) => {
  return (
    <div className="policy-details-section">
      <h2>Policy Details</h2>
      <div className="policy-details-grid">
        <div className="form-group required">
          <label htmlFor="insurerName" className="policyDetailsLabel">
            Insurer Name
          </label>
          <select
            id="insurerName"
            name="insurerName"
            className="policyDetailsSelect"
            value={formData.insurerName}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            {insuranceCompanies.map((company) => (
              <option key={company.value} value={company.value}>
                {company.label}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="insurerCity" className="policyDetailsLabel">
            Insurer City
          </label>
          <input
            type="text"
            id="insurerCity"
            className="policyDetailsInput"
            name="insurerCity"
            value={formData.ownerDetails.city}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="insurerBranch" className="policyDetailsLabel">
            Insurer Branch
          </label>
          <select
            id="insurerBranch"
            name="insurerBranch"
            className="policyDetailsSelect"
            value={formData.insurerBranch}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            {branchData.map((branch) => (
              <option key={branch.id} value={branch.id}>
                {branch.branch}
              </option>
            ))}
          </select>
          <button className="add-btn">+</button>
        </div>

        <div className="form-group required">
          <label htmlFor="busBookDate" className="policyDetailsLabel">
            Bus. Book Date
          </label>
          <input
            type="date"
            id="busBookDate"
            name="busBookDate"
            className="policyDetailsInput"
            value={formData.busBookDate}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group required">
          <label htmlFor="startDate" className="policyDetailsLabel">
            Start Date
          </label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            className="policyDetailsInput"
            value={formData.startDate}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group required">
          <label htmlFor="department" className="policyDetailsLabel">
            Department
          </label>
          <select
            id="department"
            name="department"
            className="policyDetailsSelect"
            value={formData.department}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Credit">Credit</option>
            <option value="Engineering">Engineering</option>
            <option value="Fire">Fire</option>
            <option value="Health">Health</option>
            <option value="Liability">Liability</option>
            <option value="Marine Cargo">Marine Cargo</option>
            <option value="Marine Hull">Marine Hull</option>
            <option value="Miscellaneous">Miscellaneous</option>
            <option value="motor">Motor</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="days" className="policyDetailsLabel">
            Days
          </label>
          <input
            type="number"
            id="days"
            name="days"
            className="policyDetailsInput"
            value={formData.days}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="endDate" className="policyDetLabel">
            Expiry Date
          </label>
          <input
            type="date"
            id="endDate"
            className="policyDetailsInput"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="policyType" className="policyDetailsLabel">
            Policy Type
          </label>
          <select
            id="policyType"
            name="policyType"
            className="policyDetailsSelect"
            value={formData.policyType}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            {formData.department === 'motor' ? (
              motorPolicies.map((policy) => (
                <option key={policy.value} value={policy.value}>
                  {policy.label}
                </option>
              ))
            ) : (
              <option value="NA">NA</option>
            )}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="isRenewable" className="policyDetailsLabel">
            Is Renewable
          </label>
          <select
            id="isRenewable"
            name="isRenewable"
            className="policyDetailsSelect"
            value={formData.isRenewable}
            onChange={handleChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="policyNo" className="policyDetailsLabel">
            Policy No.
          </label>
          <input
            type="text"
            id="policyNo"
            name="policyNo"
            className="policyDetailsInput"
            value={formData.policyNo}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="hypothecation" className="policyDetailsLabel">
            Hypothecation
          </label>
          <select
            id="hypothecation"
            name="hypothecation"
            className="policyDetailsSelect"
            value={formData.hypothecation}
            onChange={handleChange}
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="projectName" className="policyDetailsLabel">
            Project Name
          </label>
          <input
            type="text"
            id="projectName"
            name="projectName"
            className="policyDetailsInput"
            value={formData.projectName}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="policyExcess" className="policyDetailsLabel">
            Policy Excess
          </label>
          <input
            type="text"
            id="policyExcess"
            name="policyExcess"
            className="policyDetailsInput"
            value={formData.policyExcess}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="insuredLocation" className="policyDetailsLabel">
            Insured Location
          </label>
          <input
            type="text"
            id="insuredLocation"
            name="insuredLocation"
            className="policyDetailsInput"
            value={formData.insuredLocation}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="policyRemarks" className="policyDetailsLabel">
            Policy/Enquiry Remarks
          </label>
          <input
            type="text"
            id="policyRemarks"
            name="policyRemarks"
            className="policyDetailsInput"
            value={formData.policyRemarks}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PolicyDetailsSection;
