import React, {useState, useEffect} from 'react';
import './MotorPolicyDetails.css';
import { apiGETCall1, apiPostCall } from '../../../utilities/site-apis';
import axios from 'axios';

const MotorPolicyDetails = ({ formData, setFormData,  handleChange }) => {
  console.log("🚀 ~ MotorPolicyDetails ~ formData:", formData)
  // Fetch makes on component mount
  useEffect(() => {
    fetchMakes();
  }, []);

  // Fetch makes from API
  const fetchMakes = async () => {
    try {
      const response = await axios.get(
        'http://localhost:3001/api/v1/vehicleInsurance/make/list'
      );

      // Assuming response.data contains an array of makes
      if (response && response.data) {
        setFormData((prevData) => ({
          ...prevData,
          make: response.data.data, // 'makes' contains the list of vehicle makes
        }));
      } else {
        console.error('Unexpected response structure for makes:', response);
      }
    } catch (error) {
      console.error('Error fetching makes:', error);
    }
  };

  // Fetch models based on selected makeId
  const fetchModels = async (makeId) => {
    try {
      const response = await axios.get(
        `http://localhost:3001/api/v1/vehicleInsurance/makeModel/list?makeId=${makeId}`
      );

      // Assuming response.data contains an array of models
      if (response && response.data) {
        setFormData((prevData) => ({
          ...prevData,
          model: response.data.data,
        }));
      } else {
        console.error('Unexpected response structure for models:', response);
      }
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  // Handle make selection change
  // const handleMakeChange = (e) => {
  //   const makeId = e.target.value;
  //   console.log('Selected Make ID:', makeId);

  //   handleChange(e); // Update the form data for "make"

  //   if (makeId) {
  //     fetchModels(makeId); // Fetch models for the selected make
  //   }
  // };

  return (
    <div className="motor-policy-details">
      <h2>Motor Policy Details</h2>
      <div className="motor-policy-grid">
        <div className="form-group">
          <label htmlFor="cvrNoteDate">Cvr Note Rcv. Date</label>
          <input
            type="date"
            id="cvrNoteDate"
            name="cvrNoteDate"
            value={formData.cvrNoteDate || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="mtrPolicyStatus">Policy Status</label>
          <select
            id="mtrPolicyStatus"
            name="mtrPolicyStatus"
            value={formData.mtrPolicyStatus || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Active">Active</option>
            <option value="Expired">Expired</option>
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="regNo">Reg. No.</label>
          <input
            type="text"
            id="regNo"
            name="regNo"
            value={formData.regNo || ''}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group required">
          <label htmlFor="typeOfVehicle">Type of Vehicle</label>
          <select
            id="typeOfVehicle"
            name="typeOfVehicle"
            value={formData.typeOfVehicle || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Car">Car</option>
            <option value="Bike">Bike</option>
            <option value="Truck">Truck</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            name="category"
            value={formData.category || ''}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="Private">Private</option>
            <option value="Commercial">Commercial</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="noOfPassenger">No. of Passenger</label>
          <input
            type="number"
            id="noOfPassenger"
            name="noOfPassenger"
            value={formData.noOfPassenger || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="engineNo">Engine No.</label>
          <input
            type="text"
            id="engineNo"
            name="engineNo"
            value={formData.engineNo || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="vehicleClass">Vehicle Class</label>
          <input
            type="text"
            id="vehicleClass"
            name="vehicleClass"
            value={formData.vehicleClass || ''}
            onChange={handleChange}
          />
        </div>

        {/* <div className="form-group required">
          <label htmlFor="make">Make</label>
          <select
            id="make"
            name="make"
            value={formData.make || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Honda">Honda</option>
            <option value="Toyota">Toyota</option>
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="model">Model</label>
          <select
            id="model"
            name="model"
            value={formData.model || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Accord">Accord</option>
            <option value="Civic">Civic</option>
          </select>
        </div> */}
        <div className="form-group required">
          <label htmlFor="make">Make</label>
          <select
            value={formData.makeId}
            onChange={(e) => {
              handleChange(e);
                 
              const selectedMakeId = e.target.value;
              fetchModels(selectedMakeId);
              setFormData((prevData) => ({
                ...prevData,
                makeId: selectedMakeId,
              }));
            }}
          >
            <option value="">Select a make</option>
            {formData?.make?.map((make) => (
              <option key={make._id} value={make._id}>
                {make.name} {/* Display name but value is _id */}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="model">Model</label>
          <select
            onChange={(e) => {
              handleChange(e);
              setFormData((prevData) => ({
                ...prevData,
                modelId: e.target.value,
              }));
            }}
            required
          >
            <option value="">--Select--</option>
            {/* Dynamically populate model options */}
            {formData?.model &&
              formData?.model?.map((model) => (
                <option key={model._id} value={model._id}>
                  {model.modelName}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="variant">Variant</label>
          <input
            type="text"
            id="variant"
            name="variant"
            value={formData.variant || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="chasisNo">Chasis No.</label>
          <input
            type="text"
            id="chasisNo"
            name="chasisNo"
            value={formData.chasisNo || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="cc">CC</label>
          <input
            type="text"
            id="cc"
            name="cc"
            value={formData.cc || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="yearOfManufacturing">Year of Manufacturing</label>
          <select
            id="yearOfManufacturing"
            name="yearOfManufacturing"
            value={formData.yearOfManufacturing || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="dateOfReg">Date of Reg.</label>
          <input
            type="date"
            id="dateOfReg"
            name="dateOfReg"
            value={formData.dateOfReg || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="invoiceDate">Invoice Date</label>
          <input
            type="date"
            id="invoiceDate"
            name="invoiceDate"
            value={formData.invoiceDate || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="seats">Seats</label>
          <input
            type="number"
            id="seats"
            name="seats"
            value={formData.seats || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group required">
          <label htmlFor="fuel">Fuel</label>
          <select
            id="fuel"
            name="fuel"
            value={formData.fuel || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Petrol">Petrol</option>
            <option value="Diesel">Diesel</option>
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="rtoState">RTO State</label>
          <select
            id="rtoState"
            name="rtoState"
            value={formData.rtoState || ''}
            onChange={handleChange}
            required
          >
            <option value="ALL">ALL</option>
            <option value="CA">CA</option>
            <option value="NY">NY</option>
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="rto">RTO</label>
          <select
            id="rto"
            name="rto"
            value={formData.rto || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="RTO1">RTO 1</option>
            <option value="RTO2">RTO 2</option>
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="zone">Zone</label>
          <select
            id="zone"
            name="zone"
            value={formData.zone || ''}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Zone1">Zone 1</option>
            <option value="Zone2">Zone 2</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="ncb">NCB(%)</label>
          <input
            type="number"
            id="ncb"
            name="ncb"
            value={formData.ncb || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="odd">ODD(%)</label>
          <input
            type="number"
            id="odd"
            name="odd"
            value={formData.odd || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="wheels">Wheels</label>
          <input
            type="text"
            id="wheels"
            name="wheels"
            value={formData.wheels || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="inspectionAgency">Inspection Agency</label>
          <input
            type="text"
            id="inspectionAgency"
            name="inspectionAgency"
            value={formData.inspectionAgency || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="roadType">Road Type</label>
          <select
            id="roadType"
            name="roadType"
            value={formData.roadType || ''}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="Paved">Paved</option>
            <option value="Unpaved">Unpaved</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="color">Color</label>
          <input
            type="text"
            id="color"
            name="color"
            value={formData.color || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="transmissionType">Transmission Type</label>
          <select
            id="transmissionType"
            name="transmissionType"
            value={formData.transmissionType || ''}
            onChange={handleChange}
          >
            <option value="">--Select--</option>
            <option value="Manual">Manual</option>
            <option value="Automatic">Automatic</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="withoutNilDep">Without Nil Dep.</label>
          <select
            id="withoutNilDep"
            name="withoutNilDep"
            value={formData.withoutNilDep || 'YES'}
            onChange={handleChange}
          >
            <option value="YES">YES</option>
            <option value="NO">NO</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default MotorPolicyDetails;
