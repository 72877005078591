  import React, { useState } from 'react';
  import CustomerInfoSection from '../../components/saibaSection/CustomerInfoSection/CustomerInfoSection';
  import PolicyDetailsSection from '../../components/saibaSection/PolicyDetailsSection/PolicyDetailsSection';
  import './PolicyForm.css';
  import PolicyParticularDetails from '../../components/saibaSection/PolicyParticularDetails/PolicyParticularDetails';
  import PaymentModeSection from '../../components/saibaSection/paymentModeSection/PaymentModeSection';
  import ServicingDetailsSection from '../../components/saibaSection/ServicingDetails/servicingDetails';
  import BrokerageDetailsSection from '../../components/saibaSection/BrokerDetailsSection/brokrageDetailsSection';
  import PartnerBrokerSection from '../../components/saibaSection/PartnerBrokerSection/PartnerBrokerSection';
  import CoBrokerSection from '../../components/saibaSection/CoBrokerSection/CoBrockerSection';
  import CampaignPolicyStatusSection from '../../components/saibaSection/CampaignPolicyStatusSection/CampaignPolicyStatusSection';
  import MotorPolicyDetails from '../../components/saibaSection/motorPolicyDetails/MotorPolicyDetails';
  import axios from 'axios';
  import { useNavigate } from 'react-router-dom';
  import { v4 as uuid } from 'uuid';

  const PolicyForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      controlNo: '',
      enquiryId: uuid(),
      customerID: '',
      bizType: 'new',
      customerName: '',
      policyHolderName: '',
      insuredName: '',
      nomineeDetailsFullName: '',
      nomineeDetailsDob: '',
      nomineeDetailsRelation: '',
      insurerCity: 'ALL',
      insuredDOB: '',
      insurerBranch: '',
      ownerDetails: {
        fullName: '',
        email: '',
        mobileNo: '',
        address: '',
        registrationAddressSame: true,
        registrationAddress: '',
        maritalStatus: '',
        gender: '',
        dob: '',
        state: '',
        city: '',
        pincode: '',
        aadharNumber: '',
        isVehicleFinanced: 'No',
        financier: '',
        panNumber: '',
        tokenIdKotak: '',
        aadharImage: '',
        district: '',
        street: '',
        streetNumber: '',
      },

      insurance: {
        insurancePolicyName: '',
        insurancePolicyNumber: '',
        insurancePolicyExpiryDate: '',
        insurancePolicyType: '',
        isClaimMadeinLastYear: false,
        isPolicyExpired: false,
        tpStartDate: '',
        tpEndDate: '',
        tpInsurerName: '',
        tpPolicyNumber: '',
      },

      // insurer name same as customer name
      // part II
      insurerCity: '',
      insurerBranch: '',
      busBookDate: '',
      startDate: '',
      department: '',
      days: '',
      endDate: '',
      policyType: '',
      isRenewable: 'Yes',
      policyNo: '',
      hypothecation: 'No',
      projectName: '',
      policyExcess: '',
      insuredLocation: '',
      policyRemarks: '',

      //  part III
      sNo: 1,
      coverage: '',
      extensions: '',
      sumInsured: 0,
      premRate: 0,
      netOdPrem: 0,
      locationRemarks: '',
      gstTax: 0.0,

      // part IV
      // Payment details
      paymentMode: '',
      amount: '',
      bank: '',
      chequeNumber: '',
      dated: '',
      bankBranch: '',
      payModeRemark: '',

      // part V
      posMispRef: 'Ref',
      posMispRefCode: '',
      posMispRefName: '',
      cscPeCode: '',
      cscPeName: '',
      rmCe1Code: '',
      rmCe1Name: '',
      solicitorCe2Code: '',
      solicitorCe2Name: '',
      tcCode: '',
      tcName: '',

      // part VI
      brokerageType: 'Brokerage',
      brokerageRemarks: '',
      gstSTax: 18.0,
      brokerageRate: '',
      brokerageAmount: 0,
      tpBrokerageRate: 0,
      tpBrokerageAmount: 0,
      rewardOn: 'Brokerage',
      rewardAmount: 0,
      rewardPercent: 0,
      tpRewardPercent: 0,
      tpAmount: 0,
      exp: 0,
      expTP: 0,
      refODPrem: 0,
      refTP: 0,
      csc: 0,
      refPosAt: 'NP',

      // part VII
      partnerBrokerName: '',
      partnerBrokerCode: '',
      network: '',
      crossReferral: '',

      // part VIII
      coBrokerName: '',
      coBrokerBranchCode: '',
      coBrokerShare: '',

      // part IX
      campaignName: '',
      status: 'Logged in',
      policyStatusRemarks: '',

      // Motor Policy Details
      cvrNoteDate: '',
      mtrPolicyStatus: '',
      regNo: '',
      typeOfVehicle: '',
      category: '',
      noOfPassenger: '',
      engineNo: '',
      vehicleClass: '',
      makeId: '',
      modelId: '',
      model: [],
      make: [],
      chasisNo: '',
      cc: '',
      yearOfManufacturing: '',
      dateOfReg: '',
      invoiceDate: '',
      seats: '',
      fuel: '',
      rtoState: '',
      rto: '',
      zone: '',
      ncb: '',
      odd: '',
      wheels: '',
      color: '',
      inspectionAgency: '',
      roadType: '',
      transmissionType: '',
      withoutNilDep: '',
      selectedMake: '',
      selectedModel: '',
    });

    const [addedParticularDetails, setAddedParticularDetails] = useState([]);
    const [coBrokers, setCoBrokers] = useState([]);
    const [isCoBrokerChecked, setIsCoBrokerChecked] = useState(false);
    const [isCheckedPartnerBroker, setIsCheckedPartnerBroker] = useState(false);

    const handleAddParticularDetail = (newData) => {
      setAddedParticularDetails([...addedParticularDetails, newData]);
    };

    const handleDeleteParticularDetail = (indexToDelete) => {
      const filteredData = addedParticularDetails.filter(
        (_, index) => index !== indexToDelete
      );
      setAddedParticularDetails(filteredData);
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

const handleSubmit = async (e) => {
  e.preventDefault();

  console.log('Submitting form datatatat...', formData);

  // Create a fullFormData object matching the schema
  const fullFormData = {
    controlNo: formData.controlNo,
    enquiryId: uuid(),
    type: formData.bizType,
    customerId: 'SIM-333',
    status: 'quotationGenerated',
    custCode: formData.custCode,
    customerName: formData.customerName,
    insuredName: formData.insuredName,
    insuredDOB: formData.insuredDOB,
    nomineeDetails: {
      fullName: formData.nomineeDetailsFullName,
      dob: formData.nomineeDetailsDob,
      relation: formData.nomineeDetailsRelation,
    },
    ownerDetails: {
      fullName: formData.customerName,
      email: formData.ownerDetails.email,
      mobileNo: formData.ownerDetails.mobileNo,
      address: formData.ownerDetails.address,
      registrationAddressSame: formData.ownerDetails.registrationAddressSame,
      registrationAddress: formData.ownerDetails.registrationAddress,
      maritalStatus: formData.ownerDetails.maritalStatus,
    },
    insurerName: formData.insurerName,
    insurerCity: formData.insurerCity,
    insurerBranch: formData.insurerBranch,
    policyHolderName: formData.customerName,
    insuranceType: formData.department,
    policyDetails: {
      busBookDate: formData.busBookDate,
      startDate: formData.startDate,
      department: formData.department,
      days: formData.days,
      endDate: formData.endDate,
      policyType: formData.policyType,
      isRenewable: formData.isRenewable,
      policyNo: formData.policyNo,
      hypothecation: formData.hypothecation,
      projectName: formData.projectName,
      policyExcess: formData.policyExcess,
      insuredLocation: formData.insuredLocation,
      policyRemarks: formData.policyRemarks,
    },

    insurance: {
      insurancePolicyName: formData.policyType,
      insurancePolicyNumber: formData.policyNo,
      insurancePolicyExpiryDate: formData.endDate,
      insurancePolicyType: '',
      isClaimMadeinLastYear: formData.isClaimMadeinLastYear,
      isPolicyExpired: formData.isPolicyExpired,
      tpStartDate: '',
      tpEndDate: '',
      tpInsurerName: '',
      tpPolicyNumber: '',
    },

    paymentDetails: {
      paymentMode: formData.paymentMode,
      amount: formData.amount,
      bank: formData.bank,
      chequeNumber: formData.chequeNumber,
      dated: formData.dated,
      bankBranch: formData.bankBranch,
      payModeRemark: formData.payModeRemark,
    },

    // motorPolicyDetails:
    cvrNoteDate: formData.cvrNoteDate,
    mtrPolicyStatus: formData.mtrPolicyStatus,
    regNo: formData.regNo,
    regDate: formData.regDate,
    typeOfVehicle: formData.typeOfVehicle,
    category: formData.category,
    noOfSeats: formData.noOfPassenger,
    engineNo: formData.engineNo,
    vehicleClass: formData.vehicleClass,
    makeId: formData.makeId,
    modelId: formData.modelId,
    make: formData.make,
    model: formData.model,
    modelVariant: formData.variant,
    variant: formData.variant,
    chasisNo: formData.chasisNo,
    cc: formData.cc,
    yearOfManufacturing: formData.yearOfManufacturing,
    dateOfReg: formData.dateOfReg,
    invoiceDate: formData.invoiceDate,
    seats: formData.seats,
    fuelType: formData.fuel,
    rtoState: formData.rtoState,
    rto: formData.rto,
    zone: formData.zone,
    ncb: formData.ncb,
    odd: formData.odd,
    wheels: formData.wheels,
    color: formData.color,
    inspectionAgency: formData.inspectionAgency,
    roadType: formData.roadType,
    transmissionType: formData.transmissionType,
    withoutNilDep: formData.withoutNilDep,
    // },

    raw: {
      addedParticularDetails: addedParticularDetails.map((detail) => ({
        sNo: detail.sNo,
        coverage: detail.coverage,
        extensions: detail.extensions,
        sumInsured: detail.sumInsured,
        premRate: detail.premRate,
        netOdPrem: detail.netOdPrem,
        locationRemarks: detail.locationRemarks,
        gstTax: detail.gstTax,
      })),

      brokerageDetails: {
        brokerageType: formData.brokerageType,
        brokerageRemarks: formData.brokerageRemarks,
        gstSTax: formData.gstSTax,
        brokerageRate: formData.brokerageRate,
        brokerageAmount: formData.brokerageAmount,
        tpBrokerageRate: formData.tpBrokerageRate,
        tpBrokerageAmount: formData.tpBrokerageAmount,
        rewardOn: formData.rewardOn,
        rewardAmount: formData.rewardAmount,
        rewardPercent: formData.rewardPercent,
        tpRewardPercent: formData.tpRewardPercent,
        tpAmount: formData.tpAmount,
      },

      partnerBrokerDetails: {
        partnerBrokerName: formData?.partnerBrokerName,
        partnerBrokerCode: formData?.partnerBrokerCode,
        network: formData?.network,
        crossReferral: formData?.crossReferral,
      },

      coBrokers: coBrokers?.map((broker) => ({
        coBrokerName: broker?.coBrokerName,
        coBrokerBranchCode: broker.coBrokerName === 'self' ? 'NA' : 'NA',
        coBrokerShare: broker?.coBrokerShare,
      })),

      campaigningDetails: {
        campaignName: formData.campaignName,
        policyStatus: formData.policyStatus,
        policyStatusRemarks: formData.policyStatusRemarks,
      },
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/new-policy`,
      fullFormData
    );

    const enquiryIdData = response.data.data.motorForm.enquiryId
    if (enquiryIdData) {
      navigate(`/policyUpload/${enquiryIdData}`);
    }
    console.log('Form successfully submitted', response.data);
  } catch (error) {
    console.error('Error submitting form', error);
    // Show an error message to the user
  }
};

    // for Partner Broker Section
    const handleCheckboxChangePartnerBroker = () => {
      setIsCheckedPartnerBroker(!isCheckedPartnerBroker);
    };

    // for co Workers
    const handleCheckboxChange = () => {
      setIsCoBrokerChecked(!isCoBrokerChecked);
    };

    const handleAddCoBroker = () => {
      setCoBrokers([
        ...coBrokers,
        {
          coBrokerName: formData.coBrokerName,
          coBrokerBranchCode: formData.coBrokerBranchCode,
          coBrokerShare: formData.coBrokerShare,
          sno: coBrokers.length + 1,
        },
      ]);

      // Reset the Co-Broker fields in the form
      setFormData({
        ...formData,
        coBrokerName: '',
        coBrokerBranchCode: '',
        coBrokerShare: '',
      });
    };

    const handleRemoveCoBroker = (indexToDelete) => {
      setCoBrokers(coBrokers.filter((_, index) => index !== indexToDelete));
    };

      const handleExit = () => {
        setFormData({}); // Clears the form
        console.log('Exiting form...');
      };

    return (
      <div>
        <form onSubmit={handleSubmit} className="policy-form">
          <p className="brokerPolicyHeading">Non-Life Standard Policy Entry </p>
          <CustomerInfoSection
            formData={formData}
            handleChange={handleChange}
          />
          <PolicyDetailsSection
            formData={formData}
            handleChange={handleChange}
          />

          {formData.department === 'motor' ? (
            <MotorPolicyDetails
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
            />
          ) : null}
          {/* Policy Particular Details Section */}
          <PolicyParticularDetails
            formData={formData}
            handleAddData={handleAddParticularDetail}
            addedData={addedParticularDetails}
            handleDelete={handleDeleteParticularDetail}
          />

          <PaymentModeSection formData={formData} handleChange={handleChange} />
          <ServicingDetailsSection
            formData={formData}
            handleChange={handleChange}
          />
          <BrokerageDetailsSection
            formData={formData}
            handleChange={handleChange}
          />
          <PartnerBrokerSection
            formData={formData}
            handleChange={handleChange}
            handleCheckboxChange={handleCheckboxChangePartnerBroker}
            isChecked={isCheckedPartnerBroker}
          />
          <CoBrokerSection
            formData={formData}
            handleChange={handleChange}
            handleAddCoBroker={handleAddCoBroker}
            handleRemoveCoBroker={handleRemoveCoBroker}
            coBrokers={coBrokers}
            handleCheckboxChange={handleCheckboxChange}
            isChecked={isCoBrokerChecked}
          />
          <CampaignPolicyStatusSection
            formData={formData}
            handleChange={handleChange}
          />

          <div className="saveExitBtnGrp">
            <button type="submit" className="submit-btn-policy-broker">
              Save
            </button>
            <button
              type="button"
              onClick={handleExit}
              className="exit-btn-policy-broker"
            >
              Exit
            </button>
          </div>
        </form>
      </div>
    );
  };

  export default PolicyForm;
