import { MainContainer, Opacity } from "./styles"
import Select from 'react-select';
import PrevIcon from '../../../assets/car-insurance/dashboard-icons/prev-icon.svg'
import reload from "../../../assets/reload.png"
import Taxi from "../../../assets/taxi.png"
import excelImage from '../../../assets/excel.svg'
import policyImage from '../../../assets/policy.svg'

import EmailCommunication from './EmailCommunication';
import PosModelData from "./pos/PosModel.js";
import BrandImg from '../../../assets/logo.png'
import { useEffect, useState } from "react"
import PopUp from "./popup.js";
import { handleAlert } from "../../../components/hanleAlert.js"
import HomeImg from '../../../assets/car-insurance/dashboard-icons/Home.svg'
import PoliciesImg from '../../../assets/car-insurance/dashboard-icons/Policies-menu.svg'
import QuotationImg from '../../../assets/car-insurance/dashboard-icons/Quotations-menu.svg'
import RenewalImg from '../../../assets/car-insurance/dashboard-icons/Renewal-menu.svg'
import RmImg from '../../../assets/car-insurance/dashboard-icons/Rm-menu.svg'
import CustomerImg from '../../../assets/car-insurance/dashboard-icons/Customers-menu.svg'
import NotificationImg from '../../../assets/car-insurance/dashboard-icons/Notifications.svg'
import ProfileImg from '../../../assets/car-insurance/dashboard-icons/Profile.svg'
import LogoutImg from '../../../assets/car-insurance/dashboard-icons/Logout.svg'
import QuotationsImg from '../../../assets/car-insurance/dashboard-icons/quotation.svg'
import PolicyImg from '../../../assets/car-insurance/dashboard-icons/Policies.svg'
import RenewalsImg from '../../../assets/car-insurance/dashboard-icons/Renewal.svg'
import ExpiredImg from '../../../assets/car-insurance/dashboard-icons/Expired.svg'
import RevenueImg from '../../../assets/car-insurance/dashboard-icons/Revenue - 2.svg'
import ALImg from '../../../assets/car-insurance/dashboard-icons/Leads.svg'
import RmsImg from '../../../assets/car-insurance/dashboard-icons/RM.svg'
import PosImg from '../../../assets/car-insurance/dashboard-icons/Pos count.svg'
import PrevArrowImg from '../../../assets/commons/prev-arrow.svg'
import EmployeeImg from '../../../assets/car-insurance/dashboard-icons/Employee count.svg'
import InactiveCustomerImg from '../../../assets/car-insurance/dashboard-icons/Inactive customers.svg'
import PdfDownloadImg from '../../../assets/car-insurance/dashboard-icons/pdfDownload.jpg'
import SearchImg from '../../../assets/car-insurance/dashboard-icons/search.svg'
import { TrackerCard } from "./track-card/TrackCard"
import { MenuItem, SubMenu } from "./menu-item/MenuItem"
import { NotificationComp } from "./notification-comp/NotificationComp"
import { Pagination } from "../../../components/pagination/Pagination"
import { useNavigate } from "react-router-dom";
import { resetRedux } from '../../../Redux/InsuranceRedux';
import { resetRedux as resetReduxes, resetMotorQuoteRedux, } from '../../../Redux/CarsRedux';
import { resetRedux as resetReduxAuth, updateUserDetails, GetUserList, updateUserRoles, GetRmList } from '../../../Redux/AuthRedux';
import { posListData, updateStatus, updateRMStatus, PosApprovedData, RMApprovedData } from '../../../Redux/posRedux'
import { GetQuotations, GetLifeQuotations, GetCommonQuotations, GetPolicies, motorEnquiryAPI, getStatics, getPosUser, GetNotifications, GetHealthQuotations,GetBikeQuotations,GetMarineQuotations,GetOtherQuotations } from '../../../Redux/DashboardRedux';
import { useSelector, useDispatch } from "react-redux";
import { EditModal } from "./edit-modal/EditModal"
import { SkeletonComponent } from './SkeletonComponent';
import { ViewDetailsPopup } from "./DashboardPopup/ViewDetailsPopup"
import { Profile } from './Profile';
import ArrowRight from '../../../assets/car-insurance/dashboard-icons/right-theme-arrow.svg'
import { axiosGETCall1, axiosPOSTCall1, axiosPUTCall1 } from "../../../utilities/utils/apiPost"
import { apiPutCall, apiGETCall1 } from '../../../utilities/site-apis'
import 'antd/dist/antd.css';
import './model.css'
import RequestModelData from "../../../components/rejectModel";
import RMRequestModelData from "../../../components/RMRequestModel.js"
import { GoPencil } from "react-icons/go";
// import { apiPostCall1 } from "../../../utilities/site-apis"
// import PupUp from "./popup.js";
import PolicyModel from "../../../components/policyModel/PolicyModel";
import PolicyUploadAdmin from "../../../components/policy-upload-dash-admin/policy-upload-admin.jsx";
const placeholder = 'https://placehold.co/600x400?text=S'
const moment = require('moment');


const downloadImage = (element) => {
    // saveAs(element.irdaImg, 'image.jpg') // Put your image url here.
}



export const AgentDashboard = () => {
    const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false); // Modal visibility state
    const [selectedItem, setSelectedItem] = useState(null);
    const [policyEnquiryId, setPolicyEnquiryId] = useState(null);

    const [selectedMenu, setSelectedMenu] = useState()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userDetails } = useSelector((state) => state.auth);
    const { usersList, rmList } = useSelector((state) => state.auth);
    const { posList, rmData } = useSelector((state) => state.posRedux);
    const { posApproved } = useSelector((state) => state.posRedux);
    // const { } = useSelector((state) => state.pos)
    const { quotationsList, isFetching, lifeQuotationsList, policyList, statics, posUser, notifications } = useSelector((state) => state.dashboardRedux);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [editTrigger, setEditTrigger] = useState(false)
    const [leadStatus, setLeadStatus] = useState('');
    const [policyStatus, setPolicyStatus] = useState('');
    const [insurer, setInsurer] = useState('');
    const [popUpData, setPopUpData] = useState({});
    const [phone, setPhone] = useState('');
    const [userId, setUserId] = useState('');
    const [posInfo, setPosInfo] = useState({});
    const [display, setDisplay] = useState(false);
    const [approvedPos, setApprovedPos] = useState({})
    const [filteredResults, setFilteredResults] = useState([]);
    const [categoryType, setCategoryType] = useState("motor");
    const [rejectReason, setRejectReason] = useState('');
    const [userIds, setUserIds] = useState('');
    const [userDetail, setUserDetail] = useState({});
    const [QuotationType, setQuotationType] = useState("quotation")
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userData, setUserData] = useState([]);
    const [load, setLoad] = useState(false);
    const [renewMenu, setRenewMenu] = useState(false);
    const [policyMenu, setPolicyMenu] = useState(false);
    const [searchName, setSearchName] = useState();
    const [selectedTab, setSelectedTab] = useState("summary");
    const [policyStartDate, setPolicyStartDate] = useState('');
    const [policyEndDate, setPolicyEndDate] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [rmPosName, setRmPosName] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [assignedRmId, setAssignedRmId] = useState("");
    const [currentPos, setCurrentPos] = useState("");
    const [changeUserId, setChangeUserId] = useState("");
    const [filteredRole, setFilteredRole] = useState('');
    const [range, setRange] = useState(false);

    const queryParameter = new URLSearchParams(window.location.search);
    const id = queryParameter.get("id")

    const onClickAddPosHandler = () => { 
        localStorage.setItem('addPos', true)
        localStorage.setItem('sourcePage', "adminDash");
        navigate('/partner')
    }

    const getFirstLetter = (str) => {
        return str ? str[0].toUpperCase() : '';
    };

  const handleTdClick = (item) => {
    setIsPolicyModalOpen(true); // Open the modal
    setPolicyEnquiryId(item);
  };

    useEffect(() => {
        var Authorization = localStorage.getItem('userDetails');
        // console.log("auth is ", JSON.stringify(Authorization));
        if (Authorization) {
            Authorization = JSON.parse(Authorization)
            dispatch(updateUserDetails(Authorization))
        }
        else {
            navigate('/')
        }
        dispatch(resetMotorQuoteRedux(''))
        // localStorage.setItem('no-header', true)
    }, [])
    // Changes by Shubham
    useEffect(() => {
        setFilteredResults(usersList?.data)
        // console.log("filteredResults are", filteredResults);
    }, [usersList])
    // console.log("pos approved data", posApproved);
    const handleChange = async (e) => {
        setChangeUserId(e.target.value);
        setIsModalOpen(true);
        // changeRole(e.target.value)
        return (null)
    };

    useEffect(() => {
        setUserData(usersList)
    }, [userData])

    const togglerIcon = (user) => {
        setUserId(user._id);
    }
    //whenever search value gets updated, we will update patience list
    //initialize search value
    const [search, setSearch] = useState('');
    //whenever search value gets updated, we will update patience list       
    const searchRole = async (searchValue) => {
        if (searchValue !== '' || searchValue == -1) {
            getUserList(selectedMenu, searchValue)

        }

    }

    const searchItems = async (searchValue) => {
        setSearch(searchValue);
        console.log("searchValue", searchValue, selectedMenu);
        // if (searchValue !== '') {
        //     const newData = await usersList.data?.filter((value) => {
        //         return value?.fullName?.toLowerCase()?.includes(searchValue?.toLowerCase())
        //     })
        if (selectedMenu == 'customer') {
            getUserList("rmpos", searchValue);
            // setCurrentPage(currentPage)
        }
        if (selectedMenu == 'rmpos') {
            getUserList("employee", searchValue);
            // setCurrentPage(currentPage)
        }
        if (selectedMenu == 'pos') {
            getRomList("Approved", currentPage, undefined, undefined, searchValue);
            // setCurrentPage(currentPage)
        }
        else if (selectedMenu == 'userList') {
            getUserList("user", searchValue);
            // setCurrentPage(currentPage)
        }
        else if (selectedMenu == 'rmPos') {
            var Authorization = JSON.parse(localStorage.getItem('userDetails'));
            getRomList("Approved", currentPage, "", Authorization.userId, searchValue);
        }
        else {
            setFilteredResults(usersList.data);
        }
    }

    const [fullName, setFullName] = useState('');
    useEffect(() => {
        setFullName(userDetails?.fullName)
        setPhone(userDetails?.phone)
    }, [userDetails])

    function sumAndFormat(...args) {
        // Calculate the sum of the arguments
        const sum = args.reduce((acc, curr) => acc + curr, 0);
        
        // Format the sum to two decimal places
        return parseFloat(sum).toFixed(2);
    }

    const handleMenu = (item) => {
        console.log("🚀 ~ handleMenu ~ item:", item)
        setPopUpData(item)

    }

    const handleRM = (enquiryId, e, type, lead, userId) => {
        // setLoad(true);
        dispatch(motorEnquiryAPI({ enquiryId: enquiryId, rmId: e.value, type: type, editUser: true })).then(() => {
            if (insurer == "all" || !insurer) {
                getCommonData(currentPage, leadStatus);
                getRmList("employee")

            }
            else if (insurer == "life") {
                getCommonData(currentPage, leadStatus);
                getRmList("employee")

            }
            else if (insurer == "motor") {
                getCommonData(currentPage, leadStatus);
                getRmList("employee")
            }
        })


    };

    const getPosUsers = () => {
        const obj = {};
        if (selectedRole == "posCustomer") {
            const queryParameter = new URLSearchParams(window.location.search);
            const id = queryParameter.get("id");
            obj.posId = id;
        }
        dispatch(getPosUser(obj))
    }


    const handleOwnerSearch = e => {
        console.log("search value", e)
        setSearchName(e);

        // },2000)

    }

    const logOutFun = () => {
        let user = localStorage.getItem("userDetails");
        if (user) {
            const  isConfirmed =  window.confirm("Are you sure you want to logout?");
           if(!isConfirmed)
           return;
            user = JSON.parse(user)
            axiosPOSTCall1('/user/logout', { userId: user.userId }, callback => {
                console.log("token verified", callback);
            }
            )
        }
        localStorage.clear();
        // reload screen if user is logged out
        window.location.reload();
        navigate('/')
        dispatch(resetRedux({}))
        dispatch(resetReduxes({}))
        dispatch(resetReduxAuth({}))
    };





    const downLoadCSV = (url) => {
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'data.csv');
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    const exportCSV = async () => {
        var Authorization = localStorage.getItem('userDetails');
        Authorization = JSON.parse(Authorization);
         if ((selectedRole == "posCustomer" || selectedRole == "rmCustomer") && selectedMenu == "quote") {
            const obj =  getPosCommonData(currentPage, leadStatus, true);
            obj.csv = true;
            const res = await apiGETCall1(`/lead/enquiry/quote/` + Authorization.userId, obj);
            downLoadCSV(res.data.data.url)
        }
        else if (selectedMenu == 'quote') {
            const obj = getCommonData(currentPage, leadStatus, "", true);
            obj.csv = true;
            const res = await apiGETCall1(`/lead/enquiry/quote/` + Authorization.userId, obj);
            downLoadCSV(res.data.data.url)
            // console.log("🚀 ~ exportCSV ~ res:", res)
        }
        else if (selectedMenu == 'healthPolicy') {
            const obj = getHealthPolicyData(currentPage, policyStatus, true);
            const res = await apiGETCall1(`/health/enquiry/list/` + Authorization.userId, obj);
            downLoadCSV(res.data.data.url)
            // console.log("🚀 ~ exportCSV ~ res:", res)
        }
         else if (selectedMenu == "renew" || selectedMenu == "policy") {
             const obj = getPolicyData(currentPage, policyStatus, "", "", true);
             obj.csv = true
             delete obj.pageSize
            axiosGETCall1(`/motor/enquiry/list/` + Authorization.userId, obj, (callBack => {
                if (callBack.status === "Success") {
                    const link = document.createElement('a');
                    link.setAttribute('href', callBack.data.url);
                    link.setAttribute('download', 'data.csv');
                    document.body.appendChild(link);

                    link.click();

                    document.body.removeChild(link);
                }
            }));
        }

    }

    const TitleComponent = ({ title, searchIcon, noIcons, downloadIcon }) => {
        return (
            <div className="flex apart mb-1">

                <div className="flex align-center title-container">
                    <h4 className="title">{title}</h4>
                </div>

                <div className="flex align-center search" style={{ gap: "10px" }}>
                    {searchIcon ?
                        (
                            <div className="flex align-center search-box">
                                <img
                                    src={SearchImg}
                                    className='search-img'
                                    alt="images for agent dashboard 2"
                                />
                                <input
                                    className="search-input"
                                    placeholder="Search"

                                    onChange={(e) => handleOwnerSearch(e.target.value)}


                                />
                            </div>
                        )
                        : null}
                    {noIcons ? null :
                        <>
                            <div className="download icons" onClick={() => navigate("/")}>
                                <img
                                    src={policyImage}
                                    alt="images for agent dashboard 4"
                                    style={{ height: "25px", width: "25px" }}
                                />
                                <p style={{ fontWeight: "bold" }} className="download-text">Buy Now</p>
                            </div>
                            {downloadIcon && <div className="download icons" onClick={() => exportCSV()}>
                                <img
                                    src={excelImage}
                                    alt="images for agent dashboard 4"
                                    style={{ height: "25px", width: "25px" }}
                                />
                                <p style={{ fontWeight: "bold" }} className="download-text">Download</p>
                            </div>}
                        </>
                    }
                </div>

            </div>
        )
    }

    // s3URL

    const PosQuote = ({ item, light, users, expired, isQuotation, index }) => {
        console.log("expired", expired, users, isQuotation, selectedMenu, index)
        return (
            <tr>

                <td className="t-e">
                    <p>{(item?.policyType == "life" && item?.interestedPolicy?.appStatus == "Lead") ? "lead" : item?.status == 'paymentPending' ? 'Pending Payment' : item?.status == 'paymentCompleted' ? 'Payment Completed' : item?.status}</p>
                </td>
                <td className="t-e">
                    <p>{item?.insuranceType}</p>
                    {/* <p className="data1">Policy From Date : {data?.newPolicyData?.policyType == 'privateCarComprehensive'? 'Comprehensive':data?.newPolicyData?.policyType == 'privateCarOD'?'Standalone OD Policy':data?.newPolicyData?.policyType == 'privateCarTP'?'Standalone TP Policy':null}</p> */}
                </td>
                <td className="t-f ">
                    <p>{isQuotation == 1 ? moment(item?.insurance?.insurancePolicyExpiryDate).format('DD-MM-YYYY') : moment(item?.createdAt).format('DD-MM-YYYY')}</p>
                </td>
                <td className="t-e ">
                    <p>{isQuotation == 1 ? moment(item?.insurance?.insurancePolicyExpiryDate).add(1, "y").subtract(1, "days").format('DD-MM-YYYY') : moment(item?.createdAt).add(7, "days").format('DD-MM-YYYY')}</p>
                </td>
                <td className="t-f ">
                    <p>{item?.insuranceType == "motor" ? (item?.motorData?.ownerDetails?.fullName || item?.user?.fullName) : item?.lifeData?.userDetail?.firstName ? `${item?.lifeData?.userDetail?.firstName} ${item?.lifeData?.userDetail?.lastName}` : item?.user?.fullName}</p>
                </td>
                <td className="t-e">
                    {/* <p>N/A</p> */}
                    <p className="pric">{item?.insuranceType == "motor" ? (item?.motorData?.ownerDetails?.mobileNo || item?.user?.phone) : item?.lifeData?.userDetail?.phone ? `${item?.lifeData?.userDetail?.phone}` : item?.user?.phone}</p>
                </td>
                <td className="t-f ">
                    <p>{item?.insuranceType == "motor" ? (item?.motorData?.ownerDetails?.email || item?.user?.email) : item?.lifeData?.userDetail?.email ? `${item?.lifeData?.userDetail?.email}` : item?.user?.email}</p>
                </td>
                {userDetails?.roles?.includes("admin") &&
                    <td className="t-e ">
                        <p className="m-1">{item?.user?.roles?.includes("pos") ? item?.user?.fullName : ""}</p>
                    </td>
                }
                {userDetails?.roles?.includes("admin") && filteredRole == "user" && selectedMenu == "quote" &&
                    <td className=" lead">

                        <Select className="dropdown-select-rm pk" placeholder={item?.rmDetails?.fullName ? item.rmDetails.fullName : "Assign"} onChange={(e) => handleRM(item?.enquiryId, e, item?.insuranceType, item?.rmDetails?.lead, item?.userId)} options={rmList?.data?.map(val => { return ({ value: val?._id, label: val?.fullName ? `${val?.fullName} (${val?.total})` : "N/A" }) })} />
                        <span className="total-lead">{rmList?.data?.filter(val => val?._id == item?.rmId)[0]?.total || 0}</span>
                    </td>
                }
                <td className="t-l" style={{ marginLeft: ".5rem" }}>
                    <p className="pric">{item?.motorData?.quickQuote?.price || item?.lifeData?.interestedPolicy?.premium || "NA"}</p>
                </td>
                {
                    isQuotation == 1 ?
                        item.quotationURL &&
                        <td className="flex align-center j-center details1 title-container">
                            <a href={item.quotationURL} target="_blank" rel="noreferrer" download="file.pdf">{/* <td onClick={()=>downloadPDF(item.quotationURL)} className="flex align-center title-container"> */}
                                <img
                                    src={PdfDownloadImg}
                                    style={{ width: "20px", marginLeft: "5px" }}
                                    className='prev'
                                    alt="prev btn"
                                />
                                {/* </td> */}
                            </a>
                        </td>
                        :
                        <td onClick={() => {
                            handleMenu(item);
                            // setUserIds(item?.userId); setSelectedMenu("userQuote")
                        }
                        } className="flex align-center j-center details1 title-container">
                            <img
                                src={PrevIcon}
                                alt="images for agent dashboard 5"
                                className='prev pric1'
                            />
                        </td>

                }

            </tr>
        )
    }

    const PosPolicy = ({ item, light, users, expired, isQuotation, index }) => {
        return (
            <tr>
                {selectedMenu !== "quote" && <td className="t-e">
                    <p>{item?.customerID || item?.motorData?.customerID}</p>
                </td>}
                <td className="t-e">
                    <p>{item?.company?.name || item?.raw?.Insurer?.substring(0, 20)}</p>
                </td>
                <td className="t-e">
                    <p>{item?.status == 'paymentPending' ? 'Pending Payment' : item?.status == 'paymentCompleted' ? 'Payment Completed' : item?.status == "quotationGenerated" ? "Policy Generated" : item?.status}</p>
                </td>
                <td className="t-l pk">
                    <p>{selectedMenu == "quote" ? "motor" : item?.newPolicyData?.policyType == 'privateCarComprehensive' ? 'COMP' : item?.newPolicyData?.policyType == 'privateCarOD' ? 'SAOD' : item?.newPolicyData?.policyType == 'privateCarTP' ? 'SATP' : item?.newPolicyData?.policyType == 'privateCarODTP' ? ' 3+1' : item?.newPolicyData?.policyType}</p>
                    {/* <p className="data1">Policy From Date : {data?.newPolicyData?.policyType == 'privateCarComprehensive'? 'Comprehensive':data?.newPolicyData?.policyType == 'privateCarOD'?'Standalone OD Policy':data?.newPolicyData?.policyType == 'privateCarTP'?'Standalone TP Policy':null}</p> */}
                </td>
                <td className="t-l ">
                    <p>{isQuotation == 1 ? (moment(item?.quickQuote?.policyStartDate).format('DD-MM-YYYY') || moment(item?.insurance?.insurancePolicyExpiryDate).format('DD-MM-YYYY') || item.raw.StartDate) : item?.raw?.StartDate ? item.raw.StartDate : moment(item?.createdAt).format('DD-MM-YYYY')}</p>
                </td>
                <td className="t-e ">
                    <p>{isQuotation == 1 ? (moment(item?.quickQuote?.policyEndDate).format('DD-MM-YYYY') || moment(item?.insurance?.insurancePolicyExpiryDate).add(1, "y").subtract(1, "days").format('DD-MM-YYYY')) : item?.raw?.StartDate ? item.raw.StartDate : moment(item?.createdAt).add(7, "days").format('DD-MM-YYYY')}</p>
                </td>
                <td className="t-f ">
                    <p>{item?.ownerDetails?.fullName || item?.ownerName || ""}</p>
                </td>
                <td className="t-l ">
                    {/* <p>N/A</p> */}
                    <p>{item?.ownerDetails?.mobileNo || "N/A"}</p>
                </td>
                <td className="t-l ">
                    <p>{item?.ownerDetails?.email || "N/A"}</p>
                </td>
                {userDetails?.roles?.includes("admin") &&
                    <td className="t-e">
                        <p className="">{item?.user?.roles?.includes("pos") ? item?.user?.fullName : ""}</p>
                    </td>

                }
                {userDetails?.roles?.includes("admin") && selectedMenu == "quote" &&
                    <td className="t-e lead">
                        <Select className="dropdown-select-rm pk" placeholder={item?.rmDetails?.fullName ? item.rmDetails.fullName : "Assign"} onChange={(e) => handleRM(item?.enquiryId, e, item?.policyType, item?.rmDetails?.lead)} options={rmList?.data?.map(val => { return ({ value: val?._id, label: val?.fullName ? `${val?.fullName} (${val?.total})` : "N/A" }) })} />
                        <span className="total-lead">{rmList?.data?.filter(val => val?._id == item?.rmId)[0]?.total || 0}</span>
                    </td>
                }
                <td className="t-l" style={{ marginLeft: "1.2rem" }}>
                    <p>{item?.quickQuote?.price || "N/A"}</p>
                </td>
                {
                    isQuotation == 1 ?
                        item.quotationURL &&
                        <>
                            <a href={item.quotationURL} target="_blank" rel="noreferrer" download="file.pdf">{/* <div onClick={()=>downloadPDF(item.quotationURL)} className="flex align-center title-container"> */}
                                <img
                                    src={PdfDownloadImg}
                                    style={{ width: "20px", marginLeft: "1rem" }}
                                    className='prev'
                                    alt="prev icon 44"
                                />
                                {/* </div> */}
                            </a>
                        </>
                        :
                        <td onClick={() => {
                            // console.log("🚀 ~ file: agent-dashboard.js ~ line 229 ~ DataListItem ~ item", item)
                            handleMenu(item);
                            // setPopUpData(item)
                        }
                        } className="flex align-center title-container">
                            <img
                                src={PrevIcon}
                                className='prev'
                                style={{ marginLeft: "2rem" }}
                                alt="prev icon 33"
                            />
                        </td>

                }

            </tr>
        )
    }

    const RMQuoteList = ({ item, light, users, expired, isQuotation, index }) => {
        if (index == 0) {


        }
        return (
            // <div className={light ? "data-list flex bg-w relative" : "data-list flex apart relative "}>
            <tr>

                <td className="t-l">
                    <p>{(item?.policyType == "life" && item?.interestedPolicy?.appStatus == "Lead") ? "lead" : item?.status == 'paymentPending' ? 'Pending Payment' : item?.status == 'paymentCompleted' ? 'Payment Completed' : item?.status}</p>
                </td>
                <td className="t-l">
                    <p>{item?.insuranceType}</p>
                    {/* <p className="data1">Policy From Date : {data?.newPolicyData?.policyType == 'privateCarComprehensive'? 'Comprehensive':data?.newPolicyData?.policyType == 'privateCarOD'?'Standalone OD Policy':data?.newPolicyData?.policyType == 'privateCarTP'?'Standalone TP Policy':null}</p> */}
                </td>
                <td className="t-l ">
                    <p>{isQuotation == 1 ? moment(item?.insurance?.insurancePolicyExpiryDate).format('DD-MM-YYYY') : moment(item?.createdAt).format('DD-MM-YYYY')}</p>
                </td>
                <td className="t-l ">
                    <p>{isQuotation == 1 ? moment(item?.insurance?.insurancePolicyExpiryDate).add(1, "y").subtract(1, "days").format('DD-MM-YYYY') : moment(item?.createdAt).add(7, "days").format('DD-MM-YYYY')}</p>
                </td>
                <td className="t-l ">
                    <p>{item?.insuranceType == "motor" ? (item?.motorData?.ownerDetails?.fullName || item?.user?.fullName) : item?.lifeData?.userDetail?.firstName ? `${item?.lifeData?.userDetail?.firstName} ${item?.lifeData?.userDetail?.lastName}` : item?.insuranceType == "health" ? item?.healthData?.proposal_details?.first_name : item?.user?.fullName}</p>
                </td>
                <td className="t-l m">
                    {/* <p>N/A</p> */}
                    <p>{item?.insuranceType == "motor" ? (item?.motorData?.ownerDetails?.mobileNo || item?.user?.phone) : item?.lifeData?.userDetail?.phone ? `${item?.lifeData?.userDetail?.phone}` : item?.insuranceType == "health" ? item?.healthData?.proposal_details?.MobileNo : item?.user?.phone}</p>
                </td>
                <td className="t-l ">
                    <p>{item?.insuranceType == "motor" ? (item?.motorData?.ownerDetails?.email || item?.user?.email) : item?.lifeData?.userDetail?.email ? `${item?.lifeData?.userDetail?.email}` : item?.insuranceType == "health" ? item?.healthData?.proposal_details?.Email : item?.user?.email}</p>
                </td>
                {userDetails?.roles?.includes("admin") && (((filteredRole == "" && !id) || selectedRole == "rmCustomer" || filteredRole == "pos")) &&
                    <td className="t-l ">
                        <p className="">{item?.user?.roles?.includes("pos") ? item?.user?.fullName : ""}</p>
                    </td>
                }
                {userDetails?.roles?.includes("employee") && ["pos"].includes(filteredRole) &&
                    <td className="t-l ">
                        <p className="">{item?.user?.roles?.includes("pos") ? item?.user?.fullName : ""}</p>
                    </td>
                }
                {userDetails?.roles?.includes("admin") && (((filteredRole == "" && !id) || filteredRole == "employee")) &&
                    <td className="t-l ">
                        <p className="">{item?.user?.roles?.includes("employee") ? item?.user?.fullName : ""}</p>
                    </td>
                }
                {(userDetails?.roles?.includes("admin") && filteredRole == "user" && !id) && selectedMenu == "quote" &&
                    <td className=" lead">

                        <Select className="dropdown-select-rm pk" placeholder={item?.rmDetails?.fullName ? item.rmDetails.fullName : "Assign"} onChange={(e) => handleRM(item?.enquiryId, e, item?.insuranceType, item?.rmDetails?.lead, item?.userId)} options={rmList?.data?.map(val => { return ({ value: val?._id, label: val?.fullName ? `${val?.fullName} (${val?.total})` : "N/A" }) })} />

                        <span className="total-lead">{rmList?.data?.filter(val => val?._id == item?.rmId)[0]?.total || 0}</span>
                    </td>
                }
                <td className="t-l">
                    <p className="price">{item?.motorData?.quickQuote?.price || item?.lifeData?.interestedPolicy?.premium || "NA"}</p>
                </td>
                {
                    (isQuotation == 1 || selectedMenu == "userPolicy") ?
                        (item.quotationURL || item?.motorData?.quotationURL) &&
                        <td className="flex align-center j-center details1 title-container">
                            <a href={item.quotationURL} target="_blank" rel="noreferrer" download="file.pdf">{/* <div onClick={()=>downloadPDF(item.quotationURL)} className="flex align-center title-container"> */}
                                <img
                                    src={PdfDownloadImg}
                                    style={{ width: "20px", marginLeft: "1px" }}
                                    className='prev'
                                    alt="prev icon 22"
                                />
                                {/* </div> */}
                            </a>
                        </td>
                        :
                        <td onClick={() => {
                            handleMenu(item);
                            // setUserIds(item?.userId); setSelectedMenu("userQuote")
                        }
                        } className="flex align-center j-center details1 title-container">
                            <img
                                src={PrevIcon}
                                className='prev'
                                alt="prev icon"
                            />
                        </td>

                }

            </tr>

        )
    }


    const LifeDataListItem = ({ item, light, users, expired, isQuotation, index }) => {
        return (
          <tr>
            {
              <td className="t-l">
                <p>{item?.customerID || item?.motorData?.customerID}</p>
              </td>
            }
            {selectedMenu !== 'quote' && (
              <td className="t-l">
                <p>
                  {item?.company?.name || item?.raw?.Insurer?.substring(0, 20)}
                </p>
              </td>
            )}
            <td className="t-l">
              <p>
                {item?.status == 'paymentPending'
                  ? 'Pending Payment'
                  : item?.status == 'paymentCompleted'
                  ? 'Payment Completed'
                  : item?.status == 'quotationGenerated'
                  ? 'Policy Generated'
                  : item?.status}
              </p>
            </td>
            <td className="t-l">
              <p>
                {item?.raw?.StartDate
                  ? moment(item?.raw?.StartDate).format('DD-MM-YYYY')
                  : moment(item?.createdAt).format('DD-MM-YYYY')}
              </p>
            </td>
            <td className="t-l">
              <p>
                {item?.raw?.ExpDate
                  ? moment(item?.raw?.ExpDate).format('DD-MM-YYYY')
                  : moment(item?.createdAt)
                      .add(1, 'year')
                      .subtract(1, 'days')
                      .format('DD-MM-YYYY')}
              </p>
            </td>
            <td className="t-l">
              <p>{item?.policyHolderName || item?.ownerName || ''}</p>
            </td>
            <td className="t-l ">
              {/* <p>N/A</p> */}
              <p>{item?.ownerDetails?.mobileNo}</p>
            </td>
            <td className="t-l ">
              <p>{item?.ownerDetails?.email}</p>
            </td>
            {userDetails?.roles?.includes('admin') &&
              selectedMenu == 'quote' && (
                <td className="t-l lead">
                  <Select
                    className="dropdown-select-rm pk"
                    placeholder={
                      item?.rmDetails?.fullName
                        ? item.rmDetails.fullName
                        : 'Assign'
                    }
                    onChange={(e) =>
                      handleRM(
                        item?.enquiryId,
                        e,
                        item?.policyType,
                        item?.rmDetails?.lead
                      )
                    }
                    options={rmList?.data?.map((val) => {
                      return {
                        value: val?._id,
                        label: val?.fullName
                          ? `${val?.fullName} (${val?.total})`
                          : 'N/A',
                      };
                    })}
                  />
                  <span className="total-lead">
                    {rmList?.data?.filter((val) => val?._id == item?.rmId)[0]
                      ?.total || 0}
                  </span>
                </td>
              )}
            <td className="t-l">
              <p style={{ marginLeft: '1rem' }}>
                {item?.quickQuote?.price || item?.price || 'N/A'}
              </p>
            </td>
            {isQuotation == 1 ? (
              item.quotationURL ? (
                <td>
                  <a
                    href={item.quotationURL}
                    target="_blank"
                    rel="noreferrer"
                    download="file.pdf"
                  >
                    <img
                      src={PdfDownloadImg}
                      style={{ width: '20px', marginLeft: '1rem' }}
                      className="prev"
                      alt="pdf"
                    />
                    {/* </div> */}
                  </a>
                </td>
              ) : (
                <td className="t-l" onClick={() => handleTdClick(item.enquiryId)}>
                  <GoPencil height={15} width={15} />
                </td>
              )
            ) : (
              <td
                onClick={() => {
                  // console.log("🚀 ~ file: agent-dashboard.js ~ line 229 ~ DataListItem ~ item", item)
                  handleMenu(item);
                  // setPopUpData(item)
                }}
                className="flex align-center title-container"
              >
                <img
                  src={PrevIcon}
                  className="prev"
                  style={{ marginLeft: '2rem' }}
                  alt="prev"
                />
              </td>
            )}
            {/* <td
              className="t-l"
              onClick={() => {
                navigate(
                  `/dashPolicy/${item?.policyType}/${item?.enquiryId}`
                );
              }}
            >
              <GoPencil height={15} width={15} />
            </td> */}
          </tr>
        );
    }


    // motor policy ---
    const DataListItem = ({ item, light, users, expired, isQuotation, index }) => {
        console.log("🚀 ~ DataListItem ~ item:", item)
        return (
          <tr>
            {
              <td className="t-l">
                <p>{item?.customerID || item?.motorData?.customerID}</p>
              </td>
            }
            {selectedMenu !== 'quote' && (
              <td className="t-l">
                <p>
                  {item?.company?.name || item?.raw?.Insurer?.substring(0, 20)}
                </p>
              </td>
            )}
            <td className="t-">
              <p>
                {item?.status == 'paymentPending'
                  ? 'Pending Payment'
                  : item?.status == 'paymentCompleted'
                  ? 'Payment Completed'
                  : item?.status == 'quotationGenerated'
                  ? 'Policy Generated'
                  : item?.status}
              </p>
            </td>
            <td className="t-l">
              <p>
                {selectedMenu == 'quote'
                  ? 'motor'
                  : item?.newPolicyData?.policyType == 'privateCarComprehensive'
                  ? 'COMP'
                  : item?.newPolicyData?.policyType == 'privateCarOD'
                  ? 'SAOD'
                  : item?.newPolicyData?.policyType == 'privateCarTP'
                  ? 'SATP'
                  : item?.newPolicyData?.policyType == 'privateCarODTP'
                  ? ' 3+1'
                  : item?.newPolicyData?.policyType}
              </p>
            </td>
            <td className="t-l">
              <p>
                {item?.quickQuote?.policyStartDate
                  ? moment(item?.quickQuote?.policyStartDate).format(
                      'DD-MM-YYYY'
                    )
                  : moment(item?.createdAt).format('DD-MM-YYYY')}
              </p>
            </td>
            <td className="t-l">
              <p>
                {item?.quickQuote?.policyEndDate
                  ? moment(item?.quickQuote?.policyEndDate).format('DD-MM-YYYY')
                  : moment(item?.createdAt)
                      .add(1, 'year')
                      .subtract(1, 'days')
                      .format('DD-MM-YYYY')}
              </p>
            </td>
            <td className="t-l">
              <p>{item?.ownerDetails?.fullName || item?.ownerName || ''}</p>
            </td>
            <td className="t-l ">
              {/* <p>N/A</p> */}
              <p>{item?.ownerDetails?.mobileNo}</p>
            </td>
            <td className="t-l ">
              <p>{item?.ownerDetails?.email}</p>
            </td>
            {userDetails?.roles?.includes('admin') &&
              (selectedRole == 'rmCustomer' || filteredRole == 'pos') && (
                <td className="t-l ">
                  <p className="">
                    {item?.user?.roles?.includes('pos')
                      ? item?.user?.fullName
                      : ''}
                  </p>
                </td>
              )}
            {userDetails?.roles?.includes('admin') &&
              filteredRole == 'employee' && (
                <td className="t-l ">
                  <p className="">
                    {item?.user?.roles?.includes('employee')
                      ? item?.user?.fullName
                      : ''}
                  </p>
                </td>
              )}
            {userDetails?.roles?.includes('admin') &&
              selectedMenu == 'quote' && (
                <td className="t-l lead">
                  <Select
                    className="dropdown-select-rm pk"
                    placeholder={
                      item?.rmDetails?.fullName
                        ? item.rmDetails.fullName
                        : 'Assign'
                    }
                    onChange={(e) =>
                      handleRM(
                        item?.enquiryId,
                        e,
                        item?.policyType,
                        item?.rmDetails?.lead
                      )
                    }
                    options={rmList?.data?.map((val) => {
                      return {
                        value: val?._id,
                        label: val?.fullName
                          ? `${val?.fullName} (${val?.total})`
                          : 'N/A',
                      };
                    })}
                  />
                  <span className="total-lead">
                    {rmList?.data?.filter((val) => val?._id == item?.rmId)[0]
                      ?.total || 0}
                  </span>
                </td>
              )}
            <td className="t-l">
              <p style={{ marginLeft: '1rem' }}>
                {item?.quickQuote?.price || 'N/A'}
              </p>
            </td>
            {isQuotation == 1 ? (
              item.quotationURL ? (
                <td>
                  <a
                    href={item.quotationURL}
                    target="_blank"
                    rel="noreferrer"
                    download="file.pdf"
                  >
                    {/* <div onClick={()=>downloadPDF(item.quotationURL)} className="flex align-center title-container"> */}
                    <img
                      src={PdfDownloadImg}
                      style={{ width: '20px', marginLeft: '1rem' }}
                      className="prev"
                      alt="pdf"
                    />
                    {/* </div> */}
                  </a>
                </td>
              ) : (
                <td
                  className="t-l"
                  onClick={() => handleTdClick(item.enquiryId)}
                >
                  <GoPencil height={15} width={15} />
                </td>
              )
            ) : (
              <td
                onClick={() => {
                  // console.log("🚀 ~ file: agent-dashboard.js ~ line 229 ~ DataListItem ~ item", item)
                  handleMenu(item);
                  // setPopUpData(item)
                }}
                className="flex align-center title-container"
              >
                <img
                  src={PrevIcon}
                  className="prev"
                  style={{ marginLeft: '2rem' }}
                  alt="prev"
                />
              </td>
            )}
            <PolicyUploadAdmin
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />{' '}
          </tr>
        );
    }
    const HealthDataListItem = ({ item, light, users, expired, isQuotation, index }) => {
        console.log("🚀 ~ HealthDataListItem ~ item:", item)
        if (index == 0) {
            // console.log("🚀 ~ file: agent-dashboard.js ~ line 186 ~ DataListItem ~ item", item)
            // console.log("🚀 ~ file: agent-dashboard.js ~ line 186 ~ DataListItem ~ item", moment(item.createdAt).format('DD-MM-YYYY'))

        }
        return (
          <tr>
            <td className="t-l">
              <p>{item?.customerID}</p>
            </td>
            {selectedMenu !== 'quote' && (
              <td className="t-l">
                <p>{item?.plan_details?.ic_name}</p>
              </td>
            )}
            {/* <td className="t-l">
                        <p>{item?.quickQuote?.vPolicyHolderType}</p>
                    </td> */}

            <td className="t-l">
              <p>
                {item?.policy_status == 'paymentPending'
                  ? 'Pending Payment'
                  : item?.status == 'paymentCompleted'
                  ? 'Payment Completed'
                  : item?.policy_status == 'Policy Done'
                  ? 'Policy Generated'
                  : item?.policy_status}
              </p>
            </td>
            <td className="t-l">
              <p>
                {isQuotation == 1
                  ? item?.raw?.StartDate ||
                    moment(item?.insurance?.insurancePolicyExpiryDate).format(
                      'DD-MM-YYYY'
                    )
                  : moment(item?.createdAt).format('DD-MM-YYYY')}
              </p>
            </td>
            <td className="t-l">
              <p>
                {isQuotation == 1
                  ? item?.raw?.ExpDate ||
                    moment(item?.insurance?.insurancePolicyExpiryDate)
                      .add(1, 'y')
                      .subtract(1, 'days')
                      .format('DD-MM-YYYY')
                  : moment(item?.createdAt).add(7, 'days').format('DD-MM-YYYY')}
              </p>
            </td>
            <td className="t-l">
              <p>
                {item?.proposal_details?.fullName ||
                  item?.proposal_details?.first_name +
                    '' +
                    item?.proposal_details?.last_name}
              </p>
            </td>
            <td className="t-l ">
              {/* <p>N/A</p> */}
              <p>{item?.proposal_details?.MobileNo || 'N/A'}</p>
            </td>
            <td className="t-l ">
              <p>{item?.proposal_details?.Email || 'N/A'}</p>
            </td>
            {userDetails?.roles?.includes('admin') &&
              selectedMenu == 'quote' && (
                <td className="t-l">
                  <p className="">
                    {item?.user?.roles?.includes('pos')
                      ? item?.user?.fullName
                      : ''}
                  </p>
                </td>
              )}
            {userDetails?.roles?.includes('admin') &&
              selectedMenu == 'quote' && (
                <td className="t-l lead">
                  <Select
                    className="dropdown-select-rm pk"
                    placeholder={
                      item?.rmDetails?.fullName
                        ? item.rmDetails.fullName
                        : 'Assign'
                    }
                    onChange={(e) =>
                      handleRM(
                        item?.enquiryId,
                        e,
                        item?.policyType,
                        item?.rmDetails?.lead
                      )
                    }
                    options={rmList?.data?.map((val) => {
                      return {
                        value: val?._id,
                        label: val?.fullName
                          ? `${val?.fullName} (${val?.total})`
                          : 'N/A',
                      };
                    })}
                  />
                  <span className="total-lead">
                    {rmList?.data?.filter((val) => val?._id == item?.rmId)[0]
                      ?.total || 0}
                  </span>
                </td>
              )}
            <td className="t-l">
              <p>{item?.payment_details?.policy_amount || 'N/A'}</p>
            </td>
            {isQuotation == 1 ? (
              item.payment_details?.policy_path ? (
                <td>
                  <a
                    href={item.payment_details?.policy_path}
                    target="_blank"
                    rel="noreferrer"
                    download="file.pdf"
                  >
                    {/* <div onClick={()=>downloadPDF(item.quotationURL)} className="flex align-center title-container"> */}
                    <img
                      src={PdfDownloadImg}
                      style={{ width: '20px', marginLeft: '1rem' }}
                      className="prev"
                      alt="pdf 2"
                    />
                    {/* </div> */}
                  </a>
                </td>
              ) : (
                <td
                  className="t-l"
                  onClick={() => handleTdClick(item.enquiryId)}
                >
                  <GoPencil height={15} width={15} />
                </td>
              )
            ) : (
              <td
                onClick={() => {
                  // console.log("🚀 ~ file: agent-dashboard.js ~ line 229 ~ DataListItem ~ item", item)
                  handleMenu(item);
                  // setPopUpData(item)
                }}
                className="flex align-center title-container"
              >
                <img
                  src={PrevIcon}
                  className="prev"
                  style={{ marginLeft: '2rem' }}
                  alt="prev 2"
                />
              </td>
            )}
          </tr>
        );
        
    }
    const PosCustomers = ({ item, light, users, expired, isQuotation, index }) => {
        return (
            <div className={"data-list flex bg-w relative apart"} key={index}>
                <div className="t-l">
                    <p>{item?.customerID || item?.motorData?.customerID}</p>
                </div>
                <div className="t-l">
                    <p>{item?.ownerDetails?.fullName}</p>
                </div>
                <div className="t-e">
                    <p>{item?.ownerDetails?.email}</p>
                </div>
                <div className="t-e">
                    <p>{"+91"} {item?.ownerDetails?.mobileNo}</p>
                </div>
                <div className="t-e">
                    <p>{selectedMenu == "quote" ? "motor" : item?.newPolicyData?.policyType == 'privateCarComprehensive' ? 'COMP' : item?.newPolicyData?.policyType == 'privateCarOD' ? 'SAOD' : item?.newPolicyData?.policyType == 'privateCarTP' ? 'SATP' : item?.newPolicyData?.policyType == 'privateCarODTP' ? ' 3+1' : item?.newPolicyData?.policyType}</p>
                </div>
                <div className="t-e">
                    <p>{isQuotation == 1 ? (moment(item?.quickQuote?.policyStartDate).format('DD-MM-YYYY') || moment(item?.insurance?.insurancePolicyExpiryDate).format('DD-MM-YYYY')) : moment(item?.createdAt).format('DD-MM-YYYY')}</p>
                </div>
                <div className="t-e">
                    <p>{isQuotation == 1 ? (moment(item?.quickQuote?.policyEndDate).format('DD-MM-YYYY') || moment(item?.insurance?.insurancePolicyExpiryDate).add(1, "y").subtract(1, "days").format('DD-MM-YYYY')) : moment(item?.createdAt).add(7, "days").format('DD-MM-YYYY')}</p>
                </div>
            </div>
        )
    }


    const handleSelectMenu = (e) => {
        if (selectedTab == "direct" || selectedTab == "posCustomer" || selectedTab == "rmCustomer") {
            setSelectedTab("summary")
        }
        setFilteredRole("")
        // if(["customer","qo"])
        if (!id && selectedRole)
            setSelectedRole("");
        setCurrentPage(1)
        setSelectedMenu(e);
        if(search)
            setSearch("")

    }

    const handleRenewal = (filter) => {
        console.log("policy", policyStartDate, policyEndDate);
        var obj = {
            isQuotation: true,
            status: 'quotationGenerated',
            userId: userIds,
            pageSize: pageSize,
            renewType: "renew",
            pageNo: currentPage,
            renew: true,
            policyStartDate,
            policyEndDate,

        }
        if (filter == "remove") {
            delete obj.policyStartDate;
            delete obj.policyEndDate;
            setPolicyStartDate("");
            setPolicyEndDate("")
        }
        dispatch(GetQuotations(obj))
        // getPolicyData(currentPage, "quotationGenerated", true, selectedMenu);
    }

    const filterAnalytics = (firstDate, lastDate) => {
        const filter = {
            userId
        }

        if ((selectedRole == "posCustomer" || selectedRole == "rmCustomer") && id) {
            filter.posId = id
            // dispatch(getStatics({ userId, posId: id }))
        }
        if (selectedRole == "rmCustomer") {
            filter.attachedRmId = id
        }
        if (firstDate, lastDate) {
            filter.startDate = policyStartDate;
            filter.endDate = policyEndDate
        }
        dispatch(getStatics(filter))
    }

    useEffect(() => {
        console.log("selected role", selectedRole, "selected tab", selectedTab, "selected menu", selectedMenu, "filtered role", filteredRole);
        if (selectedMenu) {
            if (selectedMenu != "renew" && policyStartDate && policyEndDate) {
                setPolicyStartDate("");
                setPolicyEndDate("");
            }
            if (selectedMenu == 'dash') {
                // getCommonData(currentPage, leadStatus);
                // console.log("start data",policyStartDate,policyEndDate);
                filterAnalytics()
            }
            else if ((selectedRole == "posCustomer" || selectedRole == "rmCustomer") && selectedMenu == "quote") {
                getPosCommonData(currentPage, leadStatus);
                setCurrentPage(currentPage)

            }
            else if (selectedMenu == 'quote') {
                getCommonData(currentPage, leadStatus);
                getRmList("employee");
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == "userQuote") {
                getUserData(currentPage, leadStatus);
                setCurrentPage(currentPage)

            }
            else if (selectedMenu == "userPolicy") {
                getUserPolicy(currentPage, leadStatus);
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'policy') {
                getPolicyData(currentPage, policyStatus);
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'bike') {
                getBikePolicyData(currentPage, policyStatus);
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'marinePolicy') {
                getMarinePolicyData(currentPage, policyStatus);
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'othersPolicy') {
                getOtherPolicyData(currentPage, policyStatus);
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'healthPolicy') {
                getHealthPolicyData(currentPage, policyStatus);
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'lifePolicy') {
                getLifePolicyData(currentPage, policyStatus);
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'pos') {
                getRomList("Approved", currentPage,"","",search);
                getUserList("employee","","all");
                // getUserList("employee")
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'requests') {
                getRomList("Requested", currentPage,"","",search);
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'rmpos') {
                getUserList("employee",search);
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'renew' || selectedMenu == 'lifeRenew' || selectedMenu == 'healthRenew') {
                
                handleRenewal();
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'userList') {
                getUserList("user",search);
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'customer' && userDetails?.roles?.includes('pos')) {
                getPolicyData(currentPage, "quotationGenerated");
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'communication') {
                getPosUsers();
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'customer') {
                getCommonData(currentPage, leadStatus);
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'lifeQuote') {
                getLifeData(currentPage, leadStatus);
                setCurrentPage(currentPage)
            }
            else if (selectedMenu == 'rmPos') {
                var Authorization = JSON.parse(localStorage.getItem('userDetails'));
                getRomList("Approved", currentPage, "", Authorization.userId);
                setCurrentPage(currentPage);
            }
        }
    }, [selectedMenu, currentPage, leadStatus, policyStatus, insurer, selectedRole, filteredRole])

    useEffect(() => {
        if (userDetails?.roles?.length > 0 && (userDetails?.roles?.includes('admin')) || userDetails?.roles?.includes('pos')) {
            setSelectedMenu('dash')
            return
        }
        else if (userDetails?.roles?.length > 0 && (userDetails?.roles?.includes('employee') || userDetails?.roles?.includes('admin'))) {
            setSelectedTab("direct")
        }
        setSelectedMenu('quote')
        // localStorage.setItem('no-header',1);
    }, [])

    const getUserList = (role, name, total) => {
        let obj = {
            role: role,
            pageSize: pageSize,
            pageNo: currentPage,
            name: name ? name : ""
        }
        if (total)
            delete obj.pageSize;
        dispatch(GetUserList(obj)).then(()=>console.log("user",usersList))
    }
    const getRmList = (role) => {
        let obj = { role: role }
        dispatch(GetRmList(obj))
    }

    const getRomList = (irdaStatus, page, assignment, rmId, searchRM) => {
        let obj = {
            irdaStatus: irdaStatus,
            pageSize: pageSize,
            pageNo: page || currentPage,
            rmAssigned: assignment,
            rmId: rmId,
            fullName: searchRM
        }
        if (!searchRM)
            delete obj.fullName

        dispatch(PosApprovedData(obj))
    }

    const getRMRequests = (status) => {
        let obj = {
            status: status || "",
            pageSize: pageSize,
            pageNo: currentPage
        }
        dispatch(RMApprovedData(obj))
    }

    const getUserData = (page, status) => {
        var obj = {
            details: true,
            userId: userIds,
            status: status ? status : '',
            pageSize: pageSize,
            pageNo: currentPage
        }
        dispatch(GetCommonQuotations(obj))
    }
    const getUserPolicy = (page, status) => {
        var obj = {
            isQuotation: true,
            details: true,
            userId: userIds,
            status: status ? status : '',
            pageSize: pageSize,
            pageNo: currentPage
        }
        dispatch(GetCommonQuotations(obj))
    }

    const getCommonData = (page, status, searchVal, download) => {
        console.log("status", status)
        var obj = {
            // status: 'paymentCompleted',
            // status: 'quotationGenerated',
            status: status ? status : '',
            pageSize: pageSize,
            pageNo: page,
            insurer: insurer,
            name: searchVal ? searchVal : "",
            role: filteredRole
        }
        if (selectedMenu == "customer")
            obj.isQuotation = true
        if (download) {
            return obj;
        }
        else dispatch(GetCommonQuotations(obj))
    }

    const getPosCommonData = (page, status, download) => {
        const queryParameter = new URLSearchParams(window.location.search);
        const id = queryParameter.get("id")
        var obj = {
            // status: 'paymentCompleted',
            // status: 'quotationGenerated',
            status: status ? status : '',
            pageSize: pageSize,
            pageNo: page,
            insurer: insurer,
            name: searchName,
        }
        if (id)
            obj.posId = id
        if (selectedRole == "rmCustomer") {
            obj.attachedRmId = id
        }
        if (download)
            return obj
        dispatch(GetCommonQuotations(obj))
    }

    const getData = (page, status) => {
        console.log("status", status)
        var obj = {
            // status: 'paymentCompleted',
            // status: 'quotationGenerated',
            status: status ? status : '',
            pageSize: pageSize,
            pageNo: page,
            insurer: insurer,
            name: searchName
        }
        dispatch(GetQuotations(obj))
    }


    const getLifeData = (page, status) => {
        console.log("status", status)
        var obj = {
            // status: 'paymentCompleted',
            // status: 'quotationGenerated',
            status: status ? status : '',
            pageSize: pageSize,
            pageNo: page
        }
        dispatch(GetLifeQuotations(obj))
    }

    const getNotificationsList = (limit) => {
        let obj = {
            pageSize: pageSize,
            pageNo: limit,
        }
        dispatch(GetNotifications(obj))
    }

    const showModal = () => {
        setIsModalVisible(true);
        console.log('Opening modal');
    };


    const handleInputChange = (e) => {
        const value = e.target.value;
        setRejectReason(value);
    };

    const handleOk = () => {
        console.log('Submitting with reason:', rejectReason);
        rejectPos(rejectReason);
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const filterCommonData = (insureType) => {
        console.log("status",)
        var obj = {
            // status: 'paymentCompleted',
            // status: 'quotationGenerated',
            status: '',
            pageSize: 10,
            pageNo: 1,
            insurer: insureType,
            name: "",
            role: filteredRole
        }
        if (selectedMenu == "customer")
            obj.isQuotation = true
        else dispatch(GetCommonQuotations(obj))
    }

    const  handleSection = tab => {
            console.log("🚀 ~ AgentDashboard ~ tab:", tab)
            setFilteredRole("");
            setLeadStatus("");
            setInsurer("")
            if (tab == "posCustomer") {
                setSelectedRole("posCustomer")
                setSelectedMenu((userDetails?.roles?.includes("admin")) ? "pos" : "rmPos")
            }
            else if (tab == "direct") {
                setSelectedRole("");
                setSelectedMenu("quote");
                setRmPosName("");
                navigate("");
                return;
            }
            else if (tab == "rmCustomer") {
                setSelectedRole("rmCustomer");
                setSelectedMenu("rmpos");
            }
            if(search){
                setSearch("")
            }
            setSelectedTab(tab)
        }

    const rejectPos = (reason) => {
        console.log('Rejection Reason:', reason);
        let obj = {
            id: posInfo._id,
            irdaStatus: 'Rejected',
            posStatus: 'rejected',
            irdaRejectionComments: reason,
        };
        dispatch(updateStatus(obj));
        modalCloses();
    };


    const approvePos = () => {
        let obj = {
          id: posInfo._id,
          irdaStatus: 'Approved',
          // status: 'approved',
          posStatus: 'approved',
          kycStatus: 'Approved',
          roles: ['pos']
        };
        // console.log("🚀 ~ file: agent-dashboard.js:456 ~ approvePos ~ true:", true)
        dispatch(updateStatus(obj)).then(() => { getRomList("Requested", currentPage); })
        modalCloses()
    }
    const getPolicyData = (page, status, renew, renewType,download) => {
        if (selectedRole == "posCustomer" || selectedRole == "rmCustomer") {
            var queryParameter = new URLSearchParams(window.location.search);
            var id = queryParameter.get("id")
            console.log("id444", id, window.location.search)
        }
        var obj = {
            isQuotation: true,
            status: status ? status : '',
            userId: userIds,
            pageSize: pageSize,
            renewType: renewType,
            pageNo: page,
            renew: renew ? true : false,
            role: filteredRole
        }
        if (selectedRole == "rmCustomer" && id) {
            obj.attachedRmId = id;
        }
        if (selectedRole == "posCustomer" && id)
            obj.posId = id;
        if(download)
            return obj
        dispatch(GetQuotations(obj))
    }

    const getMarinePolicyData = (page, status, renew, renewType,download) => {
        if (selectedRole == "posCustomer" || selectedRole == "rmCustomer") {
            var queryParameter = new URLSearchParams(window.location.search);
            var id = queryParameter.get("id")
            console.log("id444", id, window.location.search)
        }
        var obj = {
            isQuotation: true,
            status: status ? status : '',
            userId: userIds,
            pageSize: pageSize,
            renewType: renewType,
            pageNo: page,
            renew: renew ? true : false,
            role: filteredRole
        }
        if (selectedRole == "rmCustomer" && id) {
            obj.attachedRmId = id;
        }
        if (selectedRole == "posCustomer" && id)
            obj.posId = id;
        if(download)
            return obj
        dispatch(GetMarineQuotations(obj))
    }

    const getBikePolicyData = (page, status, renew, renewType,download) => {
        console.log("called");
        if (selectedRole == "posCustomer" || selectedRole == "rmCustomer") {
            var queryParameter = new URLSearchParams(window.location.search);
            var id = queryParameter.get("id")
            console.log("id444", id, window.location.search)
        }
        var obj = {
            isQuotation: true,
            status: status ? status : '',
            userId: userIds,
            pageSize: pageSize,
            renewType: renewType,
            pageNo: page,
            renew: renew ? true : false,
            role: filteredRole
        }
        if (selectedRole == "rmCustomer" && id) {
            obj.attachedRmId = id;
        }
        if (selectedRole == "posCustomer" && id)
            obj.posId = id;
        if(download)
            return obj
        dispatch(GetBikeQuotations(obj))
    }

    const getOtherPolicyData = (page, status, renew, renewType,download) => {
        if (selectedRole == "posCustomer" || selectedRole == "rmCustomer") {
            var queryParameter = new URLSearchParams(window.location.search);
            var id = queryParameter.get("id")
            console.log("id444", id, window.location.search)
        }
        var obj = {
            isQuotation: true,
            status: status ? status : '',
            userId: userIds,
            pageSize: pageSize,
            renewType: renewType,
            pageNo: page,
            renew: renew ? true : false,
            role: filteredRole
        }
        if (selectedRole == "rmCustomer" && id) {
            obj.attachedRmId = id;
        }
        if (selectedRole == "posCustomer" && id)
            obj.posId = id;
        if(download)
            return obj
        dispatch(GetOtherQuotations(obj))
    }

    const getHealthPolicyData = (page, status, download) => {
        if (selectedRole == "posCustomer" || selectedRole == "rmCustomer") {
            var queryParameter = new URLSearchParams(window.location.search);
            var id = queryParameter.get("id")
        }
        var obj = {
            isQuotation: true,
            status: status ? status : '',
            userId: userIds,
            pageSize: pageSize,
            pageNo: page,
        }
        if (selectedRole == "rmCustomer" && id) {
            obj.attachedRmId = id;
        }
        if (selectedRole == "posCustomer" && id)
            obj.posId = id;
        if (download) {
            obj.csv = true
            return obj
        }
        dispatch(GetHealthQuotations(obj))
    }

    const getLifePolicyData = (page, status, download) => {
        if (selectedRole == "posCustomer" || selectedRole == "rmCustomer") {
            var queryParameter = new URLSearchParams(window.location.search);
            var id = queryParameter.get("id")
        }
        var obj = {
            isQuotation: true,
            status: status ? status : '',
            userId: userIds,
            pageSize: pageSize,
            pageNo: page,
        }
        if (selectedRole == "rmCustomer" && id) {
            obj.attachedRmId = id;
        }
        if (selectedRole == "posCustomer" && id)
            obj.posId = id;
        if (download) {
            obj.csv = true
            return obj
        }
        dispatch(GetLifeQuotations(obj))
    }

    const changeRole = (value) => {
        var obj = {
            userId: userId,
            roles: [value]
        }
        dispatch(updateUserRoles(obj)).then(() => { getUserList("user"); });
    }


    const [openRmpos, setOpenRmpos] = useState(false);

    const showOption = (e) => {
        setSelectedMenu(e);
        setOpenRmpos(!openRmpos);
    }
    const showRenewOptions = (e) => {
        setSelectedMenu(e);
        setRenewMenu(!renewMenu);
    }
    const showPolicyOptions = (e) => {
        if (!id && selectedRole)
            setSelectedRole("");
        setSelectedMenu(e);
        setPolicyMenu(!policyMenu);
    }

    const handleRedirection = (element) => {
        if (userDetails?.roles?.includes("employee")) {
            navigate(`?id=${element?.userId}`);
            setRmPosName(element?.fullName);
            setSelectedMenu("quote");
            return;
        }
        console.log("element", element);
        // return
        if (userDetails?.roles?.includes("admin")) {
            if (!selectedRole) {
                selectedMenu == "rmpos" ? setSelectedRole("rmCustomer") : setSelectedRole("posCustomer")
            }
            navigate(`?id=${element?.userId || element?._id}`);
            setRmPosName(element?.fullName);
            setSelectedMenu("quote");
            return;
        }
    }

    const modelDisplay = (element) => {
        axiosGETCall1(`/pospInfo/getPosInfo`,{id:element._id},callBack=>{
            if(callBack.status == "Success"){
                setPosInfo(callBack.data)
                setDisplay(true)
            }
        })
    }



    const modalCloses = (element) => {
        setPosInfo(element)
        setDisplay(false)
    }

    const handleRMRequest = e => {
        dispatch(getRMRequests(e.target.value));
    }

    const handlePos = e => {
        dispatch(getRomList(e.target.value, currentPage));
    }

    const closePopUp = () => {
        setIsModalOpen(false)
    }
    const handlePopModel = (e, posId) => {
        console.log("🚀 ~ handlePopModel ~ e:", e, posId)
        setIsModalOpen(true);
        setCurrentPos(posId);
        setAssignedRmId(e.value)
    }
    const assignRM = () => {
        console.log("userList", assignedRmId, currentPos);
        let obj = {
            rmId: assignedRmId,
        };

        axiosPUTCall1(`/pospInfo/${currentPos}`, obj, (callBack) => {
            if (callBack.status === "Success") {
                console.log("result99".callBack)
            }
        });
        setIsModalOpen(false);
    }
    const changeUserRole = () => {
        changeRole(changeUserId);
        setIsModalOpen(false);
    }
    const RmModelData = () => {
        return (
            <div>
                <div id="myModal" className="modalPos">
                    <div className="modal-content main-model-data">
                        <div className="close-pos-model" onClick={modalCloses}>
                            &times;
                        </div>
                        <div className="inside_box">
                            <div className="profile_div">
                                <img src="abcd" className="profile_photo" />
                            </div>
                            <div className="content_popup">
                                <div className="popup_head">
                                    <h3 className="heading">RM Details :</h3>
                                    <h4>Edit Role</h4>
                                </div>
                                <div className="rm_basic">
                                    <div className="rm_right">
                                        <div>
                                            <h3>
                                                Member Since : <span>20/01/2020</span>
                                            </h3>
                                            <h3>
                                                RM ID :<span>#321431</span>
                                            </h3>
                                        </div>
                                        <div>
                                            <h3>
                                                RM Name :{' '}
                                                <span>{posInfo?.informationRM?.name}</span>
                                            </h3>
                                            <h3>
                                                RM Email :<span>example@gmail.com</span>
                                            </h3>
                                        </div>
                                        <div>
                                            <h3>
                                                RM Mobile No. :{' '}
                                                <span>+91 {posInfo?.informationRM?.phone}</span>
                                            </h3>
                                            <h3>
                                                Sr. RM/No. :
                                                <span>Nitin Kumar B/+91 - 98765432210</span>
                                            </h3>
                                        </div>
                                        <div>
                                            <h3>
                                                House No. : <span>Number 419/B</span>
                                            </h3>
                                            <h3>
                                                Street :<span>Nelgulli After Sonamahalli Gate</span>
                                            </h3>
                                        </div>
                                        <div>
                                            <h3>
                                                Town : <span>Bengaluru</span>
                                            </h3>
                                            <h3>
                                                District :
                                                <span>{`${posInfo.district} (${posInfo.area})`}</span>
                                            </h3>
                                        </div>
                                        <div>
                                            <h3>
                                                State : <span>{posInfo?.state}</span>
                                            </h3>
                                            <h3>
                                                Pincode :<span>{posInfo?.pincode}</span>
                                            </h3>
                                        </div>
                                        <div>
                                            <h3>
                                                Area : <span>{posInfo.area}</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <hr className="popUp_line" />

                                <h3 className="heading">Other Details :</h3>
                                <div className="rm_basic">
                                    <div className="rm_right">
                                        <div>
                                            <h3>
                                                Gender :<span>{posInfo?.gender}</span>
                                            </h3>
                                            <h3>
                                                D.O.B :
                                                <span>
                                                    {moment(posInfo?.dob).format('DD/MM/YYYY')}
                                                </span>
                                            </h3>
                                        </div>
                                        <div>
                                            <h3>
                                                Pan Card :<span>{posInfo?.documents?.pancard}</span>
                                            </h3>
                                            <h3>
                                                Basic Quilification :
                                                <span>{posInfo?.qualification}</span>
                                            </h3>
                                        </div>
                                        <div>
                                            <h3>
                                                IRDA Number :
                                                <span>
                                                    {posInfo?.irdaNumber
                                                        ? posInfo?.irdaNumber
                                                        : 'Not Available'}
                                                </span>
                                            </h3>
                                            <h3 style={{ cursor: 'pointer' }}>
                                                IRDA Document :{' '}
                                                {posInfo?.irdaImg ? (
                                                    <a href={posInfo.irdaImg} download>
                                                        Download
                                                    </a>
                                                ) : (
                                                    <span>No document available</span>
                                                )}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <hr className="popUp_line" />

                                <h3 className="heading">Policy Details :</h3>
                                <div className="rm_basic">
                                    <div className="rm_right">
                                        <div>
                                            <h3>
                                                Policy Sold :<span>50</span>
                                            </h3>
                                            <h3>
                                                Type of policy sold :
                                                <span>Health, Life, Motar(Bike & Car)</span>
                                            </h3>
                                        </div>
                                        <div>
                                            <h3>
                                                Last Policy Sold :<span>23/08/2022</span>
                                            </h3>
                                            <h3>
                                                Status :<span>Active</span>
                                            </h3>
                                        </div>
                                        <div>
                                            <h3>
                                                Business last year (2021) :<span>₹ 16,32,018</span>
                                            </h3>
                                            <h3>
                                                Business this year (2022) :<span>₹ 32,012</span>
                                            </h3>
                                        </div>
                                        <div>
                                            <h3>
                                                KYC Status :<span>Approved</span>
                                            </h3>
                                            <h3>
                                                No. of POS :<span>34</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <hr className="popUp_line" />

                                <h3 className="heading">Document Details :</h3>
                                <div className="rm_basic">
                                    <div className="rm_details">
                                        <div className="details_front">
                                            <div>
                                                <h3>Aadhar card Front:</h3>
                                                <img
                                                    src={
                                                        posInfo?.documents?.aadharFront || placeholder
                                                    }
                                                    alt="Aadhar Front"
                                                />
                                            </div>

                                            <div>
                                                <h3>Pan card Front :</h3>
                                                <img src={posInfo?.documents?.pancard} />
                                            </div>
                                            <div>
                                                <h3>Education Quilification Proof :</h3>
                                                <img src={posInfo?.documents?.education} />
                                            </div>
                                        </div>

                                        <div className="details_back">
                                            <div>
                                                <h3>Aadhar card back :</h3>
                                                <img src={posInfo?.documents?.aadharBack} />
                                            </div>
                                            <div>
                                                <h3> </h3>
                                            </div>
                                            {/* <div><h3>Pan card back :</h3><img src={posInfo?.documents?.pancard} /></div> */}
                                            <div>
                                                <h3>Signature Proof :</h3>
                                                <img src={posInfo?.documents?.signature} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const rmDetails = (filteredResults?.length != 0) ? filteredResults?.map((element) => (
        <tr key={element.name} className="list_content" onClick={() => { console.log("helo", element); }}>
            {/* <td className="list_content">{element?.posIncId}</td> */}
            <td className="list_content">{element?.fullName}</td>
            <td className="list_content">{element?.phone}</td>
            <td className="list_content">{element?.email}</td>
            {/* <td className="list_content">0</td> */}
            {/* <td className="list_content">{element?.documents ? "Submitted" : "Approved"}</td> */}
            {/* <td className="list_content">{"Active"}</td> */}
            <td className="list_content">

                <button onClick={() => handleRedirection(element)} className="button_modal"><img src={ArrowRight} /></button>

            </td>
        </tr>
    )) : null

    const newrow = (posApproved.data) ? posApproved.data.map((element) => (
        <tr key={element.name} className="list_content">
            <td className="list_content clickable" onClick={() => modelDisplay(element)}>{element?.posIncId || "POS0012"}</td>
            <td className="list_content">{element?.fullName}</td>
            <td className="list_content">{element?.phone}</td>
            <td className="list_content">{element?.email}</td>
            {/* <td className="list_content">0</td> */}
            <td className="list_content">{element?.kycStatus === "Approved" ? "Approved" : "Pending"}</td>
            {!(selectedMenu == "rmPos") && <td className="list_content">
                <Select className="" placeholder={element?.rmDetails?.fullName ? element.rmDetails.fullName : "Assign"} onChange={(e) => handlePopModel(e, element?._id)} options={usersList?.data?.map(val => { return ({ value: val?._id, label: val?.fullName ? `${val?.fullName}` : "N/A" }) })} />
            </td>
            }
            <td className="list_content">{element?.kycStatus == "Submitted" ? "Active" : element?.kycStatus}</td>
            <td className="list_content">

                <button onClick={() => handleRedirection(element)} className="button_modal"><img src={ArrowRight} /></button>

            </td>
        </tr>
    )) : null

    const requestRow = (posApproved.data) ? posApproved.data.map((element) => (
        <>
            <tr key={element.name} className="list_content">
                {/* <td className="list_content">{element.posIncId}</td> */}
                <td className="list_content">{element.fullName}</td>
                <td className="list_content">{element?.phone}</td>
                <td className="list_content">{element?.email}</td>
                <td className="list_content">{element?.irdaStatus}</td>
                <td className="list_content">
                    <button onClick={() => modelDisplay(element)} className="button_modal"><img src={ArrowRight} /></button>
                </td>
            </tr>
        </>
    )) : null

    return (
      <>
        {isModalOpen && (
          <PopUp
            show={isModalOpen}
            onClose={closePopUp}
            title="Confirmation"
            onOk={selectedMenu == 'userList' ? changeUserRole : assignRM}
          >
            <p>Do you want to Change role</p>
          </PopUp>
        )}
        {popUpData?.motorData?.hasOwnProperty('quickQuote') && (
          <>
            <Opacity />
            <ViewDetailsPopup
              data={popUpData.motorData}
              setPopUpData={setPopUpData}
              type="motor"
              user={popUpData?.user}
            />
          </>
        )}

        {popUpData?.lifeData && (
          <>
            <Opacity />
            <ViewDetailsPopup
              data={popUpData.lifeData}
              setPopUpData={setPopUpData}
              type="life"
              user={popUpData?.user}
            />
          </>
        )}

        {popUpData?.healthData && (
          <>
            <Opacity />
            <ViewDetailsPopup
              data={popUpData.healthData}
              setPopUpData={setPopUpData}
              type="health"
            />
          </>
        )}
        <MainContainer style={{ marginTop: 25 }}>
          <div className="flex main-container">
            <div className="flex column ">
              <hr style={{ position: 'relative', borderColor: '#F5F5F9' }} />

              <div className="p-1 brand-container">
                <img src={BrandImg} className="brand-logo" />
              </div>
              {(selectedRole == 'posCustomer' ||
                selectedRole == 'rmCustomer') &&
              rmPosName ? (
                <div className="posName">
                  <h4>{selectedRole == 'rmCustomer' ? 'RM: ' : 'POS: '} </h4>{' '}
                  <h4>{rmPosName}</h4>
                </div>
              ) : null}
              <div className="menu-container">
                {userDetails?.roles?.length > 0 &&
                  !userDetails?.roles?.includes('user') && (
                    <>
                      <MenuItem
                        title="Dashboard"
                        img={HomeImg}
                        selected={selectedMenu === 'dash' ? true : false}
                        onSelect={handleSelectMenu}
                        item="dash"
                      />
                    </>
                  )}
                <>
                  <MenuItem
                    title="Quotations"
                    img={QuotationImg}
                    selected={selectedMenu === 'quote' ? true : false}
                    onSelect={handleSelectMenu}
                    item="quote"
                  />
                </>

                <MenuItem
                  title="Policy"
                  img={PoliciesImg}
                  selected={
                    [
                      'policy',
                      'lifePolicy',
                      'healthPolicy',
                      'bike',
                      'marinePolicy',
                      'othersPolicy',
                    ].includes(selectedMenu)
                      ? true
                      : false
                  }
                  onSelect={showPolicyOptions}
                  item="policy"
                />
                {policyMenu && (
                  <>
                    <SubMenu
                      subtitle=" • Motor"
                      selected={selectedMenu === 'policy' ? true : false}
                      onSelect={handleSelectMenu}
                      item="policy"
                    />
                    <SubMenu
                      subtitle=" • Life"
                      selected={selectedMenu === 'lifePolicy' ? true : false}
                      onSelect={handleSelectMenu}
                      item="lifePolicy"
                    />
                    <SubMenu
                      subtitle=" • Health"
                      selected={selectedMenu === 'healthPolicy' ? true : false}
                      onSelect={handleSelectMenu}
                      item="healthPolicy"
                    />
                    <SubMenu
                      subtitle=" • Two Wheeler"
                      selected={selectedMenu === 'bike' ? true : false}
                      onSelect={handleSelectMenu}
                      item="bike"
                    />
                    <SubMenu
                      subtitle=" • Marine"
                      selected={selectedMenu === 'marinePolicy' ? true : false}
                      onSelect={handleSelectMenu}
                      item="marinePolicy"
                    />
                    <SubMenu
                      subtitle=" • Others"
                      selected={selectedMenu === 'othersPolicy' ? true : false}
                      onSelect={handleSelectMenu}
                      item="othersPolicy"
                    />
                  </>
                )}

                {userDetails?.roles?.length > 0 &&
                  userDetails?.roles?.includes('admin') && (
                    <>
                      <MenuItem
                        title="RM & POS"
                        img={RmImg}
                        selected={
                          ['rmpos', 'pos', 'request'].includes(selectedMenu)
                            ? true
                            : false
                        }
                        onSelect={showOption}
                        item="rmpos"
                        menu="rmpos"
                      />
                    </>
                  )}

                {openRmpos && (
                  <>
                    <SubMenu
                      subtitle=" • RM"
                      selected={selectedMenu === 'rmpos' ? true : false}
                      onSelect={handleSelectMenu}
                      item="rmpos"
                    />
                    <SubMenu
                      subtitle=" • POS"
                      selected={selectedMenu === 'pos' ? true : false}
                      onSelect={handleSelectMenu}
                      item="pos"
                    />
                    <SubMenu
                      subtitle=" • POS Requests"
                      selected={selectedMenu === 'requests' ? true : false}
                      onSelect={handleSelectMenu}
                      item="requests"
                    />
                  </>
                )}

                {userDetails?.roles?.length > 0 &&
                  userDetails?.roles?.includes('admin') && (
                    <>
                      <MenuItem
                        title="Users"
                        img={CustomerImg}
                        selected={selectedMenu === 'userList' ? true : false}
                        onSelect={handleSelectMenu}
                        item="userList"
                      />
                    </>
                  )}
                {userDetails?.roles?.length > 0 &&
                  !userDetails?.roles?.includes('user') && (
                    <>
                      <MenuItem
                        title="Renewals"
                        img={RmImg}
                        selected={
                          [
                            'renew',
                            'lifeRenew',
                            'healthRenew',
                            'twoWheelerRenew',
                            'marineRenew',
                            'otherRenew',
                          ].includes(selectedMenu)
                            ? true
                            : false
                        }
                        onSelect={showRenewOptions}
                        item="renew"
                        menu="renew"
                      />
                    </>
                  )}

                {renewMenu && (
                  <>
                    <SubMenu
                      subtitle=" • Motor"
                      selected={selectedMenu === 'renew' ? true : false}
                      onSelect={handleSelectMenu}
                      item="renew"
                    />
                    <SubMenu
                      subtitle=" • Life"
                      selected={selectedMenu === 'lifeRenew' ? true : false}
                      onSelect={handleSelectMenu}
                      item="lifeRenew"
                    />
                    <SubMenu
                      subtitle=" • Health"
                      selected={selectedMenu === 'healthRenew' ? true : false}
                      onSelect={handleSelectMenu}
                      item="healthRenew"
                    />
                    <SubMenu
                      subtitle=" • Two Wheeler"
                      selected={
                        selectedMenu === 'twoWheelerRenew' ? true : false
                      }
                      onSelect={handleSelectMenu}
                      item="twoWheelerRenew"
                    />
                    <SubMenu
                      subtitle=" • Marine"
                      selected={selectedMenu === 'marineRenew' ? true : false}
                      onSelect={handleSelectMenu}
                      item="marineRenew"
                    />
                    <SubMenu
                      subtitle=" • Others"
                      selected={selectedMenu === 'otherRenew' ? true : false}
                      onSelect={handleSelectMenu}
                      item="otherRenew"
                    />
                  </>
                )}
                {userDetails?.roles?.length > 0 &&
                  (userDetails?.roles?.includes('admin') ||
                    userDetails?.roles?.includes('pos')) && (
                    <>
                      <MenuItem
                        title="Customers"
                        img={CustomerImg}
                        selected={selectedMenu === 'customer' ? true : false}
                        onSelect={handleSelectMenu}
                        item="customer"
                      />
                    </>
                  )}
                {userDetails?.roles?.length > 0 &&
                  !userDetails?.roles?.includes('user') && (
                    <MenuItem
                      title="Communication"
                      img={RenewalImg}
                      selected={selectedMenu === 'communication' ? true : false}
                      onSelect={handleSelectMenu}
                      item="communication"
                    />
                  )}
                <MenuItem
                  title="Profile"
                  img={ProfileImg}
                  selected={selectedMenu === 'profile' ? true : false}
                  onSelect={handleSelectMenu}
                  item="profile"
                />

                <hr className="separator mt-1" />
                <MenuItem
                  title="Logout"
                  img={LogoutImg}
                  selected={selectedMenu === 'logout' ? true : false}
                  onSelect={() => {
                    logOutFun();
                  }}
                />
                <hr className="separator" />
              </div>
            </div>
            <div className="right">
              {(userDetails?.roles?.includes('employee') ||
                userDetails?.roles?.includes('admin')) && (
                <div className="tabs-container flex align-center">
                  <div
                    className={`tab-item ${
                      selectedRole == '' ? 'selected-tab' : ''
                    }`}
                    value="direct"
                    onClick={() => handleSection('direct')}
                  >
                    {userDetails?.roles?.includes('admin') ? 'All' : 'Direct'}
                  </div>
                  {userDetails?.roles?.includes('admin') && (
                    <div
                      className={`tab-item ${
                        selectedRole == 'rmCustomer' ? 'selected-tab' : ''
                      }`}
                      value="rmCustomer"
                      onClick={() => handleSection('rmCustomer')}
                    >
                      RM
                    </div>
                  )}
                  <div
                    className={`tab-item ${
                      selectedRole == 'posCustomer' ? 'selected-tab' : ''
                    }`}
                    value="posCustomer"
                    onClick={() => handleSection('posCustomer')}
                  >
                    POS
                  </div>
                </div>
              )}

              {selectedMenu === 'dash' ? (
                <TitleComponent title="Dashboard" />
              ) : null}
              {selectedMenu === 'policies' ? (
                <TitleComponent
                  title={'Policies' + '/Motor Insurance'}
                  searchIcon={true}
                  downloadIcon={true}
                />
              ) : null}
              {selectedMenu === 'quote' ? (
                <>
                  <TitleComponent
                    title={'Quotations'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'policy' ? (
                <>
                  <TitleComponent
                    title={'Motor Policies'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'healthPolicy' ? (
                <>
                  <TitleComponent
                    title={'Health Policies'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'lifePolicy' ? (
                <>
                  <TitleComponent
                    title={'Life Policies'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'bike' ? (
                <>
                  <TitleComponent
                    title={'Two Wheeler Policies'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'marinePolicy' ? (
                <>
                  <TitleComponent
                    title={'Marine Policies'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'othersPolicy' ? (
                <>
                  <TitleComponent
                    title={'Others Policies'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'rmpos' ? (
                <>
                  <TitleComponent title={'RM Data'} searchIcon={false} />
                </>
              ) : null}
              {selectedMenu === 'rmPos' ? (
                <>
                  <TitleComponent
                    title={'Select your POS'}
                    searchIcon={false}
                    noIcons={true}
                  />
                </>
              ) : null}

              {selectedMenu === 'pos' ? (
                <>
                  <TitleComponent title={'POS Data'} searchIcon={false} />
                </>
              ) : null}

              {selectedMenu === 'requests' ? (
                <>
                  <TitleComponent title={'POS Requests'} searchIcon={false} />
                </>
              ) : null}

              {selectedMenu === 'renew' ? (
                <>
                  <TitleComponent
                    title={'Motor Renewal'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'lifeRenew' ? (
                <>
                  <TitleComponent
                    title={'Life Renewal'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'healthRenew' ? (
                <>
                  <TitleComponent
                    title={'Health Renewal'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'twoWheelerRenew' ? (
                <>
                  <TitleComponent
                    title={'Two Wheeler Renewal'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'marineRenew' ? (
                <>
                  <TitleComponent
                    title={'Marine Renewal'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'otherRenew' ? (
                <>
                  <TitleComponent
                    title={'Others Renewal'}
                    searchIcon={false}
                    downloadIcon={true}
                  />
                </>
              ) : null}
              {selectedMenu === 'customer' ? (
                <>
                  <TitleComponent title={'Customers'} searchIcon={false} />
                </>
              ) : null}

              {selectedMenu === 'communication' ? (
                <TitleComponent title={'Communication'} noIcons={true} />
              ) : null}

              {selectedMenu === 'profile' ? (
                <TitleComponent title={'Profile'} noIcons={true} />
              ) : null}

              {selectedMenu === 'dash' ? (
                <>
                  <div className="table_body">
                    <div className="tabs-container flex align-center">
                      <div
                        className={`tab-item ${
                          selectedTab == 'summary' ? 'selected-tab' : ''
                        }`}
                        value="summary"
                        onClick={() => handleSection('summary')}
                      >
                        Summary
                      </div>
                      <div
                        className={`tab-item ${
                          selectedTab == 'motor' ? 'selected-tab' : ''
                        }`}
                        onClick={() => handleSection('motor')}
                      >
                        Car
                      </div>
                      <div
                        className={`tab-item ${
                          selectedTab == 'life' ? 'selected-tab' : ''
                        }`}
                        onClick={() => handleSection('life')}
                      >
                        Life
                      </div>
                      <div
                        className={`tab-item ${
                          selectedTab == 'health' ? 'selected-tab' : ''
                        }`}
                        onClick={() => handleSection('health')}
                      >
                        Health
                      </div>
                      <div
                        className={`tab-item ${
                          selectedTab == 'bike' ? 'selected-tab' : ''
                        }`}
                        onClick={() => handleSection('bike')}
                      >
                        Two Wheeler
                      </div>
                      <div
                        className={`tab-item ${
                          selectedTab == 'marine' ? 'selected-tab' : ''
                        }`}
                        onClick={() => handleSection('marine')}
                      >
                        Marine
                      </div>
                      <div
                        className={`tab-item ${
                          selectedTab == 'others' ? 'selected-tab' : ''
                        }`}
                        onClick={() => handleSection('others')}
                      >
                        Others
                      </div>
                    </div>
                    <div className="flex">
                      <div className="" style={{ marginLeft: '1rem' }}>
                        <button
                          className="dropdown-select fil"
                          onClick={() => {
                            filterAnalytics();
                            setRange(false);
                          }}
                        >
                          Inception
                        </button>
                      </div>
                      <div className="">
                        <button
                          className="dropdown-select fil"
                          onClick={() => setRange(true)}
                        >
                          Range
                        </button>
                      </div>
                      {range && (
                        <div
                          className="flex column"
                          style={{ marginLeft: '1.5rem' }}
                        >
                          <label className="f-w600">Start Date</label>
                          <input
                            type="Date"
                            className="dropdown-select-date"
                            onChange={(e) => setPolicyStartDate(e.target.value)}
                          />
                        </div>
                      )}
                      {range && (
                        <div
                          className="flex column"
                          style={{ marginLeft: '1.5rem' }}
                        >
                          <label className="f-w600">End Date</label>
                          <input
                            type="Date"
                            min={policyStartDate}
                            className="dropdown-select-date"
                            onChange={(e) => setPolicyEndDate(e.target.value)}
                          />
                        </div>
                      )}
                      {range && (
                        <div className="">
                          <button
                            className="dropdown-select fil"
                            onClick={() =>
                              filterAnalytics(policyStartDate, policyEndDate)
                            }
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                    {isFetching == false ? (
                      <div className="tracker-cards-container grid">
                        {selectedTab == 'summary' ? (
                          <>
                            {userDetails?.roles?.includes('admin') && (
                              <>
                                {/* combine summary */}
                                <TrackerCard
                                  title={'Quotations'}
                                  icon={QuotationsImg}
                                  month={
                                    statics?.currentYearResult?.reduce(
                                      (acc, obj) => acc + obj.count,
                                      0
                                    ) || 0
                                  }
                                />
                                <TrackerCard
                                  title={'Policies'}
                                  icon={PolicyImg}
                                  month={
                                    (statics?.currentYearEQResult?.filter(
                                      (val) => val?._id == 'life'
                                    )[0]?.count || 0) +
                                    (statics?.currentYearEQResult?.filter(
                                      (val) => val?._id == 'motor'
                                    )[0]?.count || 0) +
                                    (parseInt(
                                      statics?.currentMontHealthPolicy?.[0]
                                        ?.count
                                    ) || 0) +
                                    (statics?.currentYearEQResult?.filter(
                                      (val) => val?._id == 'twoWheeler'
                                    )[0]?.count || 0) +
                                    (statics?.currentYearEQResult?.filter(
                                      (val) => val?._id == 'marine'
                                    )[0]?.count || 0) +
                                    (statics?.currentYearEQResult?.filter(
                                      (val) => val?._id == 'others'
                                    )[0]?.count || 0)
                                  }
                                />
                                <TrackerCard
                                  title={'Renewals'}
                                  icon={RenewalsImg}
                                  month={0}
                                  fYear={0}
                                  monthPercent="0%"
                                  fyPercent={'0%'}
                                />
                                <TrackerCard
                                  title={'Expired'}
                                  icon={ExpiredImg}
                                  month={
                                    statics?.currentYearExpiredLead?.reduce(
                                      (acc, obj) => acc + obj.count,
                                      0
                                    ) || 0
                                  }
                                />
                                <TrackerCard
                                  title={'Total Premium'}
                                  icon={RevenueImg}
                                  month={sumAndFormat(
                                    parseFloat(
                                      statics?.currentMontPolicy?.reduce(
                                        (acc, obj) => acc + obj.price,
                                        0
                                      )
                                    ) +
                                      parseFloat(
                                        statics?.currentMontHealthPolicy?.reduce(
                                          (acc, obj) => acc + obj.price,
                                          0
                                        )
                                      ) +
                                      parseFloat(
                                        statics?.lifePrice?.reduce(
                                          (acc, obj) => acc + obj.price,
                                          0
                                        )
                                      ) +
                                      parseFloat(
                                        statics?.twoWheelerPrice?.reduce(
                                          (acc, obj) => acc + obj.price,
                                          0
                                        )
                                      ) +
                                      parseFloat(
                                        statics?.marinePrice?.reduce(
                                          (acc, obj) => acc + obj.price,
                                          0
                                        )
                                      ) +
                                      parseFloat(
                                        statics?.otherPrice?.reduce(
                                          (acc, obj) => acc + obj.price,
                                          0
                                        )
                                      ) || 0
                                  )}
                                />
                              </>
                            )}
                            {!userDetails?.roles?.includes('admin') && (
                              <>
                                <TrackerCard
                                  title={'Quotations'}
                                  icon={QuotationsImg}
                                  month={statics?.currentYearResult?.reduce(
                                    (acc, obj) => acc + obj.count,
                                    0
                                  )}
                                />
                                <TrackerCard
                                  title={'Policies'}
                                  icon={PolicyImg}
                                  month={
                                    (statics?.currentYearEQResult?.filter(
                                      (val) => val?._id == 'life'
                                    )[0]?.count || 0) +
                                    (statics?.currentYearEQResult?.filter(
                                      (val) => val?._id == 'motor'
                                    )[0]?.count || 0) +
                                    (parseInt(
                                      statics?.currentMontHealthPolicy?.[0]
                                        ?.count
                                    ) || 0) +
                                    (statics?.currentYearEQResult?.filter(
                                      (val) => val?._id == 'motwoWheelertor'
                                    )[0]?.count || 0) +
                                    (statics?.currentYearEQResult?.filter(
                                      (val) => val?._id == 'marine'
                                    )[0]?.count || 0) +
                                    (statics?.currentYearEQResult?.filter(
                                      (val) => val?._id == 'others'
                                    )[0]?.count || 0)
                                  }
                                />
                                <TrackerCard
                                  title={'Renewals'}
                                  icon={RenewalsImg}
                                  month={0}
                                  fYear={0}
                                  monthPercent="0%"
                                  fyPercent={'0%'}
                                />
                                <TrackerCard
                                  title={'Expired'}
                                  icon={ExpiredImg}
                                  month={statics?.currentYearExpiredLead?.reduce(
                                    (acc, obj) => acc + obj.count,
                                    0
                                  )}
                                />
                                <TrackerCard
                                  title={'Total Premium'}
                                  icon={RevenueImg}
                                  month={sumAndFormat(
                                    parseFloat(
                                      statics?.currentMontPolicy?.reduce(
                                        (acc, obj) => acc + obj.price,
                                        0
                                      )
                                    ) +
                                      parseFloat(
                                        statics?.currentMontHealthPolicy?.reduce(
                                          (acc, obj) => acc + obj.price,
                                          0
                                        )
                                      ) +
                                      parseFloat(
                                        statics?.lifePrice?.reduce(
                                          (acc, obj) => acc + obj.price,
                                          0
                                        )
                                      ) +
                                      parseFloat(
                                        statics?.twoWheelerPrice?.reduce(
                                          (acc, obj) => acc + obj.price,
                                          0
                                        )
                                      ) +
                                      parseInt(
                                        statics?.marinePrice?.reduce(
                                          (acc, obj) => acc + obj.price,
                                          0
                                        )
                                      ) +
                                      parseFloat(
                                        statics?.otherPrice?.reduce(
                                          (acc, obj) => acc + obj.price,
                                          0
                                        )
                                      ) || 0
                                  )}
                                />
                              </>
                            )}

                            {userDetails?.roles?.includes('admin') &&
                              selectedRole == '' && (
                                <>
                                  <TrackerCard
                                    title={'Relationship Manager Count'}
                                    icon={RmsImg}
                                    month={
                                      statics?.currentYearUser?.filter(
                                        (val) => val?._id == 'employee'
                                      )[0]?.count || 0
                                    }
                                  />
                                  <TrackerCard
                                    title={'POS Count'}
                                    icon={PosImg}
                                    month={
                                      statics?.currentYearUser?.filter(
                                        (val) => val?._id == 'pos'
                                      )[0]?.count
                                    }
                                  />
                                  {/* <TrackerCard miniTitle={'Employee Count'} icon={EmployeeImg} month={11} fYear={14} monthPercent='-14%' fyPercent={'-26%'} viewDetails={true} /> */}
                                  <TrackerCard
                                    title={'Inactive Customers'}
                                    icon={InactiveCustomerImg}
                                    month={0}
                                    fYear={0}
                                    monthPercent="0%"
                                    fyPercent={'0%'}
                                  />
                                </>
                              )}
                          </>
                        ) : selectedTab == 'motor' ? (
                          <>
                            <TrackerCard
                              title={'MotorQuotations'}
                              icon={QuotationsImg}
                              month={
                                statics?.currentYearResult?.filter(
                                  (val) => val?._id == 'motor'
                                )[0]?.count || 0
                              }
                            />
                            <TrackerCard
                              title={'Policies'}
                              icon={PolicyImg}
                              month={
                                statics?.currentYearEQResult?.filter(
                                  (val) => val?._id == 'motor'
                                )[0]?.count || 0
                              }
                            />
                            <TrackerCard
                              title={'Renewals'}
                              icon={RenewalsImg}
                              month={0}
                              fYear={0}
                              monthPercent="0%"
                              fyPercent={'0%'}
                            />
                            <TrackerCard
                              title={'Expired'}
                              icon={ExpiredImg}
                              month={
                                statics?.currentYearExpiredLead?.filter(
                                  (val) => val?._id == 'motor'
                                )[0]?.count || 0
                              }
                            />
                            <TrackerCard
                              title={'Total Premium'}
                              icon={RevenueImg}
                              month={sumAndFormat(
                                statics?.currentMontPolicy?.reduce(
                                  (acc, obj) => acc + obj.price,
                                  0
                                )
                              )}
                            />
                          </>
                        ) : selectedTab == 'life' ? (
                          <>
                            <TrackerCard
                              title={'LifeQuotations'}
                              icon={QuotationsImg}
                              month={
                                statics?.currentYearResult?.filter(
                                  (val) => val?._id == 'life'
                                )[0]?.count || 0
                              }
                            />
                            <TrackerCard
                              title={'Policies'}
                              icon={PolicyImg}
                              month={
                                statics?.currentYearEQResult?.filter(
                                  (val) => val?._id == 'life'
                                )[0]?.count || 0
                              }
                            />
                            <TrackerCard
                              title={'Renewals'}
                              icon={RenewalsImg}
                              month={0}
                              fYear={0}
                              monthPercent="0%"
                              fyPercent={'0%'}
                            />
                            <TrackerCard
                              title={'Expired'}
                              icon={ExpiredImg}
                              month={
                                statics?.currentYearExpiredLead?.filter(
                                  (val) => val?._id == 'life'
                                )[0]?.count || 0
                              }
                            />
                            <TrackerCard
                              title={'Total Premium'}
                              icon={RevenueImg}
                              month={sumAndFormat(
                                statics?.lifePrice?.reduce(
                                  (acc, obj) => acc + obj.price,
                                  0
                                )
                              )}
                            />
                          </>
                        ) : selectedTab == 'health' ? (
                          <>
                            <TrackerCard
                              title={'HealthQuotations'}
                              icon={QuotationsImg}
                              month={
                                statics?.currentMonthResult?.filter(
                                  (val) => val?._id == 'health'
                                )[0]?.count || 0
                              }
                            />
                            <TrackerCard
                              title={'Policies'}
                              icon={PolicyImg}
                              month={
                                statics?.currentMontHealthPolicy?.[0]?.count ||
                                0
                              }
                            />
                            <TrackerCard
                              title={'Renewals'}
                              icon={RenewalsImg}
                              month={0}
                              fYear={0}
                              monthPercent="0%"
                              fyPercent={'0%'}
                            />
                            <TrackerCard
                              title={'Expired'}
                              icon={ExpiredImg}
                              month={
                                statics?.currentYearExpiredLead?.filter(
                                  (val) => val?._id == 'health'
                                )[0]?.count || 0
                              }
                            />
                            <TrackerCard
                              title={'Total Premium'}
                              icon={PolicyImg}
                              month={sumAndFormat(
                                statics?.currentMontHealthPolicy?.[0]?.price?.toFixed(
                                  2
                                ) || 0
                              )}
                            />
                          </>
                        ) : selectedTab == 'bike' ? (
                          <>
                            <TrackerCard
                              title={'TwoWheelerQuotations'}
                              icon={QuotationsImg}
                              month={0}
                            />
                            <TrackerCard
                              title={'Policies'}
                              icon={PolicyImg}
                              month={
                                statics?.currentYearEQResult?.filter(
                                  (val) => val?._id == 'twoWheeler'
                                )[0]?.count || 0
                              }
                            />
                            <TrackerCard
                              title={'Renewals'}
                              icon={RenewalsImg}
                              month={0}
                              fYear={0}
                              monthPercent="0%"
                              fyPercent={'0%'}
                            />
                            <TrackerCard
                              title={'Expired'}
                              icon={ExpiredImg}
                              month={0}
                            />
                            <TrackerCard
                              title={'Total Premium'}
                              icon={PolicyImg}
                              month={sumAndFormat(
                                statics?.twoWheelerPrice?.reduce(
                                  (acc, obj) => acc + obj.price,
                                  0
                                ) || 0
                              )}
                            />
                          </>
                        ) : selectedTab == 'marine' ? (
                          <>
                            <TrackerCard
                              title={'MarineQuotations'}
                              icon={QuotationsImg}
                              month={0}
                            />
                            <TrackerCard
                              title={'Policies'}
                              icon={PolicyImg}
                              month={
                                statics?.currentYearEQResult?.filter(
                                  (val) => val?._id == 'marine'
                                )[0]?.count || 0
                              }
                            />
                            <TrackerCard
                              title={'Renewals'}
                              icon={RenewalsImg}
                              month={0}
                              fYear={0}
                              monthPercent="0%"
                              fyPercent={'0%'}
                            />
                            <TrackerCard
                              title={'Expired'}
                              icon={ExpiredImg}
                              month={0}
                            />
                            <TrackerCard
                              title={'Total Premium'}
                              icon={PolicyImg}
                              month={sumAndFormat(
                                statics?.marinePrice?.reduce(
                                  (acc, obj) => acc + obj.price,
                                  0
                                ) || 0
                              )}
                            />
                          </>
                        ) : selectedTab == 'others' ? (
                          <>
                            <TrackerCard
                              title={'OtherQuotations'}
                              icon={QuotationsImg}
                              month={0}
                            />
                            <TrackerCard
                              title={'Policies'}
                              icon={PolicyImg}
                              month={
                                statics?.currentYearEQResult?.filter(
                                  (val) => val?._id == 'others'
                                )[0]?.count || 0
                              }
                            />
                            <TrackerCard
                              title={'Renewals'}
                              icon={RenewalsImg}
                              month={0}
                              fYear={0}
                              monthPercent="0%"
                              fyPercent={'0%'}
                            />
                            <TrackerCard
                              title={'Expired'}
                              icon={ExpiredImg}
                              month={0}
                            />
                            <TrackerCard
                              title={'Total Premium'}
                              icon={PolicyImg}
                              month={sumAndFormat(
                                statics?.otherPrice?.reduce(
                                  (acc, obj) => acc + obj.price,
                                  0
                                ) || 0
                              )}
                            />
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                          <SkeletonComponent />
                        ))}
                      </>
                    )}
                  </div>
                </>
              ) : null}

              {selectedMenu === 'userQuote' || selectedMenu == 'userPolicy' ? (
                <>
                  <div className="table_body">
                    <div
                      className="flex back"
                      onClick={() => setSelectedMenu('quote')}
                    >
                      <img className="arrow" src={PrevArrowImg} />
                      <p className="back-to-quotes">Back to Quotes</p>
                    </div>
                    <div className="tabs-container flex align-center">
                      <div
                        className={
                          selectedMenu == 'userQuote'
                            ? 'tab-item selected-tab'
                            : 'tab-item'
                        }
                        onClick={() => {
                          setLeadStatus('');
                          setSelectedMenu('userQuote');
                        }}
                      >
                        Quotations
                      </div>
                      <div
                        className={
                          selectedMenu == 'userPolicy'
                            ? 'tab-item selected-tab'
                            : 'tab-item'
                        }
                        onClick={() => {
                          setLeadStatus('');
                          setSelectedMenu('userPolicy');
                        }}
                      >
                        Policies
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {selectedMenu === 'policies' ? (
                <>
                  <div className="flex apart align-center mb-1">
                    <div className="flex">
                      <select defaultValue={-1} className="dropdown-select">
                        <option value={-1}>Policy Status :</option>
                        <option>All</option>
                        <option>Expired</option>
                        <option>Request</option>
                        <option>Claimed</option>
                        <option>Renewal</option>
                      </select>

                      <select defaultValue={-1} className="dropdown-select">
                        <option value={-1}>Payment Status :</option>
                        <option className="green">Complete</option>
                        <option className="yellow">Pending</option>
                        <option className="red">Failed</option>
                      </select>

                      <select defaultValue={-1} className="dropdown-select">
                        <option value={-1}>Expiry Days :</option>
                        <option>0-7 Days</option>
                        <option>7-15 Days</option>
                        <option>15-30 Days</option>
                      </select>
                      {/* <h3 className="clear">Clear Filters</h3> */}
                    </div>
                  </div>
                </>
              ) : null}

              {selectedMenu === 'profile' ? (
                <>
                  <Profile setEditTrigger={setEditTrigger} />
                </>
              ) : null}

              {selectedMenu === 'userList' ? (
                <>
                  {/* <Profile setEditTrigger={setEditTrigger}/> */}

                  {
                    <div className="flex align-center apart ">
                      <div className="flex align-center search-box">
                        <img src={SearchImg} className="search-img" />
                        <input
                          className="search-input"
                          placeholder="Search By Name"
                          onChange={(e) => searchItems(e.target.value)}
                          value={search}
                        />
                      </div>
                    </div>
                  }
                  {isFetching == true ? (
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                      <SkeletonComponent />
                    ))
                  ) : (
                    <>
                      <p className="index">
                        Showing {usersList?.data?.length} of{' '}
                        {usersList?.totalCount}
                      </p>
                      <div className="entries-container flex apart">
                        <div className="t-l">
                          <p>Full Name</p>
                        </div>
                        <div className="t-e">
                          <p>Email</p>
                        </div>
                        <div className="t-l">
                          <p>Role</p>
                        </div>
                        <div className="t-e">
                          <p>Phone Number</p>
                        </div>
                        <div className="t-e">
                          <p>Created At</p>
                        </div>
                      </div>
                      {filteredResults?.map((item, index) => (
                        <div className={'data-list flex bg-w relative apart'}>
                          <div className="t-l">
                            <p>{item?.fullName}</p>
                          </div>
                          <div className="t-e">
                            <p>{item?.email}</p>
                          </div>
                          <div className="t-l">
                            {/* <p>{item?.roles[0]}</p> */}
                            {item?.roles?.length > 0 &&
                              item?.roles?.map((items, index) => (
                                <>
                                  <div
                                    className="flex align-center j-center"
                                    onClick={() => togglerIcon(item)}
                                  >
                                    <select
                                      name=""
                                      defaultValue={-1}
                                      className="dropdown-select"
                                      onChange={handleChange}
                                    >
                                      <option>{items?.toUpperCase()}</option>
                                      {/* <option value={"pos"}>pos</option> */}
                                      <option value={'employee'}>RM</option>
                                      {/* <option value={"user"}>user</option> */}
                                      <option value={'admin'}>ADMIN</option>
                                    </select>
                                  </div>
                                </>
                              ))}
                          </div>
                          <div className="t-e">
                            <p>
                              {item?.countryCode} {item?.phone}
                            </p>
                          </div>
                          <div className="t-e">
                            <p>
                              {moment(item?.createdAt).format('DD-MM-YYYY')}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              ) : null}
              {selectedMenu === 'customer' &&
              userDetails?.roles?.includes('admin') ? (
                <>
                  <p className="index">
                    Showing {quotationsList?.data?.length} of{' '}
                    {quotationsList?.count}
                  </p>
                  {
                    <div className="flex align-center apart mb-1">
                      <div className="flex align-center j-center">
                        <p className="f-w600">Filter By Role: </p>
                        <select
                          name=""
                          defaultValue={-1}
                          className="dropdown-select"
                          onChange={(e) => {
                            setFilteredRole(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={''}>All</option>
                          <option value={'pos'}>POS</option>
                          <option value={'employee'}>RM</option>
                          <option value={'user'}>Direct</option>
                          <option value={'admin'}>Others</option>
                        </select>
                      </div>
                    </div>
                  }
                  {
                    <>
                      <div className="entries-container flex apart">
                        <div className="t-l">
                          <p>Customer ID</p>
                        </div>
                        <div className="t-l">
                          <p>Full Name</p>
                        </div>
                        <div className="t-e">
                          <p>Phone Number</p>
                        </div>
                        <div className="t-e">
                          <p>Email</p>
                        </div>
                        {filteredRole != 'user' && (
                          <div className="t-l">
                            <p>RM/POS</p>
                          </div>
                        )}
                        <div className="t-e">
                          <p>Created At</p>
                        </div>
                      </div>
                      {quotationsList?.count > 0 &&
                        quotationsList?.data?.map((item, index) => (
                          <div
                            className={'data-list flex bg-w relative apart'}
                            key={index}
                          >
                            <div className="t-l">
                              <p>
                                {item?.customerID ||
                                  item?.motorData?.customerID}
                              </p>
                            </div>
                            <div className="t-l">
                              <p>
                                {item?.policyHolderName ||
                                  item?.motorData?.ownerDetails?.fullName ||
                                  item?.healthData?.policyHolderName ||
                                  item?.lifeData?.policyHolderName}
                              </p>
                            </div>
                            <div className="t-e">
                              <p>
                                {item?.motorData?.ownerDetails?.mobileNo ||
                                  item?.healthData?.proposal_details
                                    ?.MobileNo ||
                                  'N/A'}
                              </p>
                            </div>
                            <div className="t-e">
                              <p>
                                {item?.motorData?.ownerDetails?.email ||
                                  item?.healthData?.proposal_details?.Email ||
                                  'N/A'}
                              </p>
                            </div>
                            {filteredRole != 'user' && (
                              <div className="t-l">
                                {item?.user?.roles?.[0] == 'employee' ? (
                                  <p>{item?.user?.fullName}</p>
                                ) : item?.user?.roles?.[0] == 'pos' ? (
                                  <p>{item?.user?.fullName}</p>
                                ) : (
                                  <p>{''}</p>
                                )}
                              </div>
                            )}
                            <div className="t-e">
                              <p>
                                {moment(item?.createdAt).format('DD-MM-YYYY')}
                              </p>
                            </div>
                          </div>
                        ))}
                    </>
                  }
                </>
              ) : null}

              {editTrigger ? (
                <EditModal onClose={() => setEditTrigger(false)} />
              ) : null}

              {selectedMenu === 'customer' &&
              !userDetails?.roles?.includes('admin') ? (
                <>
                  <p className="index">
                    Showing {quotationsList?.data?.length} of{' '}
                    {quotationsList?.count}
                  </p>
                  {
                    <>
                      <div className="entries-container flex apart">
                        <div className="t-l">
                          <p>Customer ID</p>
                        </div>
                        <div className="t-l">
                          <p>Full Name</p>
                        </div>
                        <div className="t-e">
                          <p>Email</p>
                        </div>
                        <div className="t-e">
                          <p>Phone Number</p>
                        </div>
                        <div className="t-e">
                          <p>Policy Type</p>
                        </div>
                        <div className="t-e">
                          <p>Created Date</p>
                        </div>
                        <div className="t-e">
                          <p>Expiry Date</p>
                        </div>
                      </div>
                      {isFetching == false ? (
                        quotationsList?.data?.length > 0 &&
                        quotationsList?.data?.map((item, index) => (
                          <>
                            <PosCustomers
                              item={item}
                              index={index}
                              light={true}
                              selectedMenu={selectedMenu}
                              isQuotation={1}
                            />
                          </>
                        ))
                      ) : (
                        <>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                            <SkeletonComponent />
                          ))}
                        </>
                      )}
                    </>
                  }
                </>
              ) : null}

              {[
                'quote',
                'policy',
                'healthPolicy',
                'lifePolicy',
                'bike',
                'marinePolicy',
                'othersPolicy',
              ].includes(selectedMenu) ? (
                <div className="flex align-center apart right-inner">
                  <div className="flex">
                    <select
                      name=""
                      defaultValue={-1}
                      style={{
                        display: [
                          'policy',
                          'healthPolicy',
                          'lifePolicy',
                          'bike',
                          'marinePolicy',
                          'othersPolicy',
                          'posCustomer',
                        ].includes(selectedMenu)
                          ? 'none'
                          : 'block',
                      }}
                      className="dropdown-select"
                      onChange={(e) => {
                        setLeadStatus(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={-1} disabled>
                        Status :
                      </option>
                      <option className="active1" value={''}>
                        Active
                      </option>
                      <option value={'paymentPending'}>Payment Pending</option>
                      <option value={'expired'}>Expired</option>
                    </select>
                    <select
                      name=""
                      defaultValue={-1}
                      style={{
                        display: [
                          'policy',
                          'healthPolicy',
                          'lifePolicy',
                          'bike',
                          'marinePolicy',
                          'othersPolicy',
                        ].includes(selectedMenu)
                          ? 'none'
                          : 'block',
                      }}
                      className="dropdown-select"
                      onChange={(e) => {
                        setInsurer(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={-1} disabled>
                        Insurance Type :{' '}
                      </option>
                      <option value={'all'}>All</option>
                      <option value={'motor'}>Motor</option>
                      <option value={'life'}>Life</option>
                      <option value={'health'}>Health</option>
                      <option value={'health'}>Two Wheeler</option>
                      <option value={'health'}>Home Insurance</option>
                      <option value={'other'}>Others</option>
                    </select>
                    <select
                      name=""
                      defaultValue={-1}
                      style={{
                        display: selectedMenu === 'quote' ? 'none' : 'block',
                      }}
                      className="dropdown-select"
                      onChange={(e) => {
                        setPolicyStatus(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={-1} disabled>
                        Status :{' '}
                      </option>
                      <option value={''}>All</option>
                      {/* <option value={"paymentPending"}>Payment Pending</option>  */}
                      <option value={'paymentCompleted'}>
                        Payment Completed
                      </option>
                      <option value={'quotationGenerated'}>
                        Policy Generated
                      </option>
                      <option value={'cancelled'}>Cancelled</option>
                      <option value={'refunded'}>Refunded</option>
                    </select>
                    {/* </div> */}
                    {userDetails?.roles?.includes('admin') && (
                      <select
                        name=""
                        defaultValue={-1}
                        style={{
                          display:
                            ['quote'].includes(selectedMenu) && !id
                              ? 'block'
                              : 'none',
                        }}
                        className="dropdown-select"
                        onChange={(e) => {
                          setFilteredRole(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value={-1} disabled>
                          Filter By Role :{' '}
                        </option>
                        <option value={''}>All</option>
                        <option value={'employee'}>RM</option>
                        <option value={'pos'}>POS</option>
                        <option value={'user'}>Direct</option>
                        <option value={'admin'}>Others</option>
                      </select>
                    )}
                    {userDetails?.roles?.includes('admin') && (
                      <select
                        name=""
                        defaultValue={-1}
                        style={{
                          display:
                            [
                              'policy',
                              'bike',
                              'marinePolicy',
                              'othersPolicy',
                            ].includes(selectedMenu) && !id
                              ? 'block'
                              : 'none',
                        }}
                        className="dropdown-select"
                        onChange={(e) => {
                          setFilteredRole(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value={-1} disabled>
                          Filter By Role :{' '}
                        </option>
                        <option value={''}>
                          {userDetails?.roles?.includes('admin')
                            ? 'All'
                            : 'Direct'}
                        </option>
                        {userDetails?.roles?.includes('admin') && (
                          <option value={'employee'}>RM</option>
                        )}
                        <option value={'pos'}>POS</option>
                        {userDetails?.roles?.includes('admin') && (
                          <option value={'user'}>Direct</option>
                        )}
                        <option value={'admin'}>Others</option>
                      </select>
                    )}
                  </div>
                </div>
              ) : null}

              {[
                'renew',
                'lifeRenew',
                'healthRenew',
                'twoWheelerRenew',
                'marineRenew',
                'otherRenew',
              ].includes(selectedMenu) ? (
                <div className="flex align-center apart right-inner">
                  <div className="flex">
                    <div className="flex column">
                      <label className="f-w600">Start Date</label>
                      <input
                        type="Date"
                        className="dropdown-select-date"
                        value={policyStartDate}
                        onChange={(e) => setPolicyStartDate(e.target.value)}
                      />
                    </div>
                    <div className="flex column">
                      <label className="f-w600">End Date</label>
                      <input
                        type="Date"
                        value={policyEndDate}
                        className="dropdown-select-date"
                        onChange={(e) => setPolicyEndDate(e.target.value)}
                      />
                    </div>
                    <div className="">
                      <button
                        className="dropdown-select fil"
                        onClick={handleRenewal}
                      >
                        Filter
                      </button>
                    </div>
                    <div className="">
                      <button
                        className="dropdown-select fil"
                        onClick={() => handleRenewal('remove')}
                      >
                        Default
                      </button>
                    </div>
                  </div>
                  <div className="flex"></div>
                </div>
              ) : null}

              {selectedMenu === 'rmpos' ? (
                <>
                  <div className="flex align-center apart mb-1">
                    <div className="flex"></div>
                    <div className="flex align-center apart POS mb-1">
                      <div className="flex align-center search-box">
                        <img src={SearchImg} className="search-img" />
                        <input
                          className="search-input"
                          placeholder="Search By Name"
                          onChange={(e) => searchItems(e.target.value)}
                          value={search}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {selectedMenu === 'pos' ? (
                <div className="flex align-center apart mb-1">
                  <div className="flex">
                    {
                      <select
                        name=""
                        defaultValue={-1}
                        className="dropdown-select"
                        onChange={(e) =>
                          getRomList('Approved', currentPage, e.target.value)
                        }
                      >
                        <option value={''}>All </option>
                        <option className="" value={'assigned'}>
                          Assigned
                        </option>
                        <option className="pening" value={'unassigned'}>
                          Unassigned
                        </option>
                      </select>
                    }

                    <Select
                      className="dropdown-select ps"
                      placeholder={'Filter By RM'}
                      onChange={(e) =>
                        getRomList('Approved', currentPage, '', e.value)
                      }
                      options={[
                        { value: '', label: 'All' },
                        ...(usersList?.data?.map((val) => ({
                          value: val?._id,
                          label: val?.fullName ? `${val?.fullName}` : 'N/A',
                        })) || []),
                      ]}
                    />
                  </div>
                  <div className="flex align-center apart ">
                    <div className="flex align-center search-box">
                      <img src={SearchImg} className="search-img" />
                      <input
                        className="search-input"
                        placeholder="Search By Name"
                        onChange={(e) => searchItems(e.target.value)}
                        value={search}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {selectedMenu === 'requests' ? (
                <div className="flex align-center apart">
                  <div className="flex">
                    <select
                      name=""
                      defaultValue={-1}
                      className="dropdown-select"
                      onChange={handlePos}
                    >
                      <option value={-1}>Status : </option>
                      <option className="" value={'Requested'}>
                        All
                      </option>
                      <option className="inactive" value={'Decline'}>
                        Rejected
                      </option>
                      <option className="pending" value={'Pending'}>
                        Pending
                      </option>
                    </select>
                  </div>
                </div>
              ) : null}

              {selectedMenu == 'userQuote' || selectedMenu == 'userPolicy' ? (
                <>
                  <div className="flex c-g4">
                    <h3 className="title t-2 ">Quotations</h3>
                    <div className="t-3">
                      <h4>
                        Name:{' '}
                        <span className="det1">{userDetail?.fullName}</span>
                      </h4>
                    </div>
                    <div className="t-3">
                      <h4>
                        Email: <span className="det1">{userDetail?.email}</span>
                      </h4>
                    </div>
                    <div className="t-3">
                      <h4>
                        Phone: <span className="det1">{userDetail?.phone}</span>
                      </h4>
                    </div>
                  </div>
                  <p className="index">
                    Showing{' '}
                    {selectedMenu === 'policy'
                      ? quotationsList?.data?.length
                      : quotationsList?.data?.length}{' '}
                    of {quotationsList?.count}
                  </p>
                  <table className="fit_content">
                    <thead>
                      <tr className="entries-container ">
                        <th className="">
                          <p>Policy Status</p>
                        </th>
                        <th className="entries-container">
                          <p>Policy Type</p>
                        </th>
                        <th className="l entries-container">
                          <p>Created Date</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Expiry Date</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Customer</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Mobile No.</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Email Id</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Pos</p>
                        </th>
                        <th className="t-l entries-container">
                          <p className="t-c">Amount</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>
                            {selectedMenu === 'userQuote'
                              ? 'Details'
                              : 'Policy'}
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isFetching == false ? (
                        quotationsList?.data?.length > 0 &&
                        quotationsList?.data?.map((item, index) => (
                          <>
                            <RMQuoteList
                              item={item}
                              index={index}
                              light={true}
                              selectedMenu={selectedMenu}
                              isQuotation={selectedMenu === 'policy' ? 1 : 0}
                            />
                          </>
                        ))
                      ) : (
                        <>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                            <SkeletonComponent />
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </>
              ) : null}

              {selectedMenu === 'policies' ? (
                <>
                  <div className={'data-list flex apart relative space-evenly'}>
                    <div
                      className="t-l"
                      style={{ backgroundColor: 'blue', alignItems: 'center' }}
                    >
                      <p>Insurer</p>
                    </div>
                    <div className="t-l">
                      <p>Policy</p>
                    </div>
                    <div className="t-l">
                      <p>No./Status</p>
                    </div>
                    <div className="t-l">
                      <p>Exp. Date</p>
                    </div>
                    <div className="t-l">
                      <p>Customer</p>
                    </div>
                    <div className="t-l">
                      <p>Mobile No.</p>
                    </div>
                    <div className="t-l">
                      <p>Email Id</p>
                    </div>
                    <div className="t-l pr-1">
                      <p>Rm/Pos</p>
                    </div>
                    <div className="t-l pr-1">
                      <p>Amount</p>
                    </div>
                  </div>
                </>
              ) : null}

              {/* expiring policies */}

              {/*========= Quotations Section of the dashboard========= */}

              {selectedMenu === 'quote' &&
              !userDetails?.roles?.includes('pos') ? (
                <>
                  <p className="index">
                    Showing{' '}
                    {selectedMenu === 'policy'
                      ? quotationsList?.data?.length
                      : quotationsList?.data?.length}{' '}
                    of {quotationsList?.count}
                  </p>
                  {/* <table className="fit_content">
                                    <thead>
                                        <tr className="entries-container ">
                                            <th className="fit_heading"><p>Policy Status</p></th>
                                            <th className="fit_heading"><p>Policy Type</p></th>
                                            <th className="fit_heading"><p>MOBILE NO.</p></th>
                                            <th className="fit_heading"><p>EMAIL ID</p></th>
                                          
                                            <th className="fit_heading"><p>KYC Status</p></th>
                                           {!(selectedMenu == "rmPos") && <th className="fit_heading"><p>Assigned RM</p></th>}
                                            <th className="fit_heading"><p>STATUS</p></th>
                                            <th className="fit_heading"><p></p></th>
                                        </tr>
                                    </thead> */}
                  <table className="fit_content">
                    <thead>
                      <tr className="entries-container ">
                        <th className="t-l entries-container">
                          <p>Policy Status </p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Policy Type</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Created Date</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Expiry Date</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Customer</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Mobile No.</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Email Id</p>
                        </th>
                        {userDetails?.roles?.includes('admin') &&
                          ((filteredRole == '' && !id) ||
                            selectedRole == 'rmCustomer' ||
                            filteredRole == 'pos') && (
                            <th className="t-l entries-container">
                              <p>Pos</p>
                            </th>
                          )}
                        {userDetails?.roles?.includes('employee') &&
                          ['pos'].includes(filteredRole) && (
                            <th className="t-l entries-container">
                              <p>Pos</p>
                            </th>
                          )}
                        {userDetails?.roles?.includes('admin') &&
                          ((filteredRole == '' && !id) ||
                            filteredRole == 'employee') && (
                            <th className="t-l entries-container">
                              <p>RM</p>
                            </th>
                          )}
                        {userDetails?.roles?.includes('admin') &&
                          filteredRole == 'user' &&
                          !id && (
                            <th className="t-m2 entries-container">
                              <p>Assign Rm</p>
                            </th>
                          )}
                        <th className="t-l entries-container">
                          <p className="t-c">Amount</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>
                            {selectedMenu === 'quote' ? 'Details' : 'Policy'}
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isFetching == false ? (
                        quotationsList?.data?.length > 0 &&
                        quotationsList?.data?.map((item, index) => (
                          <>
                            {item ? (
                              <RMQuoteList
                                item={item}
                                index={index}
                                light={true}
                                selectedMenu={selectedMenu}
                                isQuotation={selectedMenu === 'policy' ? 1 : 0}
                              />
                            ) : (
                              'Data Not Found'
                            )}
                          </>
                        ))
                      ) : (
                        <>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                            <SkeletonComponent />
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </>
              ) : null}

              {selectedMenu === 'quote' &&
              userDetails?.roles?.includes('pos') ? (
                <>
                  <p className="index">
                    Showing{' '}
                    {selectedMenu === 'policy'
                      ? quotationsList?.data?.length
                      : quotationsList?.data?.length}{' '}
                    of {quotationsList?.count}
                  </p>
                  <table className="fit_content">
                    <thead>
                      <th className="t-e">
                        <p>Policy Status</p>
                      </th>
                      <th className="t-e">
                        <p>Policy Type</p>
                      </th>
                      <th className="t-e">
                        <p>Created Date</p>
                      </th>
                      <th className="t-e">
                        <p>Expiry Date</p>
                      </th>
                      <th className="t-e">
                        <p>Customer</p>
                      </th>
                      <th className="t-e">
                        <p>Mobile No.</p>
                      </th>
                      <th className="t-e">
                        <p>Email Id</p>
                      </th>
                      {userDetails?.roles?.includes('admin') && (
                        <th className="t-l">
                          <p>Pos</p>
                        </th>
                      )}
                      {userDetails?.roles?.includes('admin') && (
                        <th className="t-e">
                          <p>Rm</p>
                        </th>
                      )}
                      <th className="t-e">
                        <p className="">Amount</p>
                      </th>
                      <th className="t-e">
                        <p>{selectedMenu === 'quote' ? 'Details' : 'Policy'}</p>
                      </th>
                    </thead>
                    <tbody>
                      {isFetching == false ? (
                        quotationsList?.data?.length > 0 &&
                        quotationsList?.data?.map((item, index) => (
                          <>
                            <PosQuote
                              item={item}
                              index={index}
                              light={true}
                              selectedMenu={selectedMenu}
                              isQuotation={selectedMenu === 'policy' ? 1 : 0}
                            />
                          </>
                        ))
                      ) : (
                        <>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                            <SkeletonComponent />
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </>
              ) : null}

              {/*========= policy Section of the dashboard========= */}
              {[
                'policy',
                'renew',
                'bike',
                'marinePolicy',
                'othersPolicy',
              ].includes(selectedMenu) &&
              !userDetails?.roles?.includes('pos') ? (
                <>
                  <p className="index">
                    Showing{' '}
                    {selectedMenu === 'policy'
                      ? quotationsList?.data?.length
                      : quotationsList?.data?.length}{' '}
                    of {quotationsList?.count}
                  </p>
                  <table className="fit_content">
                    <thead>
                      <tr className="entries-container ">
                        <th className="t-l entries-container">
                          <p>Customer ID</p>
                        </th>
                        {selectedMenu !== 'quote' && (
                          <th className="t-l entries-container">
                            <p>Insurer</p>
                          </th>
                        )}
                        <th className="t-l entries-container">
                          <p>
                            {selectedMenu === 'renew' ||
                            selectedMenu === 'healthRenew'
                              ? ' Previous Policy Status'
                              : 'Policy Status'}
                          </p>
                        </th>
                        <th className="t-l entries-container">
                          <p>
                            {selectedMenu === 'renew' ||
                            selectedMenu === 'healthRenew'
                              ? 'Previous Policy Type'
                              : 'Policy Type'}
                          </p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Created Date</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Expiry Date</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Customer</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Mobile No.</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Email Id</p>
                        </th>
                        {userDetails?.roles?.includes('admin') &&
                          (selectedRole == 'rmCustomer' ||
                            filteredRole == 'pos') && (
                            <th className="t-l entries-container">
                              <p>Pos</p>
                            </th>
                          )}
                        {userDetails?.roles?.includes('admin') &&
                          filteredRole == 'employee' && (
                            <th className="t-l entries-container">
                              <p>RM</p>
                            </th>
                          )}
                        <th className="t-l entries-container">
                          <p className="t-c">Amount</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>
                            {selectedMenu === 'quote' ? 'Details' : 'Policy'}
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isFetching == false ? (
                        quotationsList?.data?.length > 0 &&
                        quotationsList?.data?.map((item, index) => (
                          <>
                            {item ? (
                              <DataListItem
                                item={item}
                                index={index}
                                light={true}
                                selectedMenu={selectedMenu}
                                isQuotation={1}
                              />
                            ) : (
                              'Data Not Found'
                            )}
                          </>
                        ))
                      ) : (
                        <>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                            <SkeletonComponent />
                          ))}
                        </>
                      )}
                    </tbody>
                    <PolicyUploadAdmin
                      isModalOpen={isPolicyModalOpen}
                      setIsModalOpen={setIsPolicyModalOpen}
                      selectedItem={selectedMenu}
                      policyEnquiryId={policyEnquiryId}
                    />
                  </table>
                </>
              ) : null}

              {/* <=== User As a POS Table data ===> */}
              {[
                'policy',
                'renew',
                'bike',
                'marinePolicy',
                'othersPolicy',
              ].includes(selectedMenu) &&
              userDetails?.roles?.includes('pos') ? (
                <>
                  <p className="index">
                    Showing{' '}
                    {selectedMenu === 'policy'
                      ? quotationsList?.data?.length
                      : quotationsList?.data?.length}{' '}
                    of {quotationsList?.count}
                  </p>
                  <table className="fit_content">
                    <thead>
                      <tr className="entries-container ">
                        <th className="t-l entries-container">
                          <p>Customer ID</p>
                        </th>
                        {selectedMenu !== 'quote' && (
                          <th className="t-l entries-container">
                            <p>Insurer</p>
                          </th>
                        )}
                        <th className="t-l entries-container">
                          <p>
                            {selectedMenu === 'renew' ||
                            selectedMenu === 'healthRenew'
                              ? ' Previous Policy Status'
                              : 'Policy Status'}
                          </p>
                        </th>
                        <th className="t-l entries-container">
                          <p>
                            {selectedMenu === 'renew' ||
                            selectedMenu === 'healthRenew'
                              ? 'Previous Policy Type'
                              : 'Policy Type'}
                          </p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Created Date</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Expiry Date</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Customer</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Mobile No.</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Email Id</p>
                        </th>
                        <th className="t-l entries-container">
                          <p className="t-c">Amount</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>
                            {selectedMenu === 'quote' ? 'Details' : 'Policy'}
                          </p>
                        </th>
                      </tr>
                    </thead>
                    {isFetching == false ? (
                      quotationsList?.data?.length > 0 &&
                      quotationsList?.data?.map((item, index) => (
                        <>
                          <PosPolicy
                            item={item}
                            index={index}
                            light={true}
                            selectedMenu={selectedMenu}
                            isQuotation={1}
                          />
                        </>
                      ))
                    ) : (
                      <>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                          <SkeletonComponent />
                        ))}
                      </>
                    )}
                  </table>
                </>
              ) : null}

              {/* Health section of the dashboard */}

              {selectedMenu === 'healthPolicy' ? (
                <>
                  <p className="index">
                    Showing{' '}
                    {selectedMenu === 'healthPolicy'
                      ? quotationsList?.data?.length
                      : quotationsList?.data?.length}{' '}
                    of {quotationsList?.count}
                  </p>
                  <table className="fit_content">
                    <thead>
                      <tr className="entries-container ">
                        <th className="t-l entries-container">
                          <p>Customer ID</p>
                        </th>
                        {selectedMenu !== 'quote' && (
                          <th className="t-l entries-container">
                            <p>Plan Name</p>
                          </th>
                        )}
                        <th className="t-l entries-container">
                          <p>Policy Status</p>
                        </th>
                        <th className=" t-l entries-container">
                          <p>Created Date</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Expiry Date</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Customer</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Mobile No.</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Email Id</p>
                        </th>
                        {userDetails?.roles?.includes('admin') &&
                          selectedMenu == 'quote' && (
                            <th className="t-l entries-container">
                              <p>Pos</p>
                            </th>
                          )}
                        <th className="t-l entries-container">
                          <p className="t-c">Amount</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>
                            {selectedMenu === 'quote' ? 'Details' : 'Policy'}
                          </p>
                        </th>
                      </tr>
                    </thead>
                    {/* <tbody> */}
                    {isFetching == false ? (
                      quotationsList?.data?.length > 0 &&
                      quotationsList?.data?.map((item, index) => (
                        <>
                          <HealthDataListItem
                            item={item}
                            index={index}
                            light={true}
                            selectedMenu={selectedMenu}
                            isQuotation={
                              selectedMenu === 'healthPolicy' ? 1 : 0
                            }
                          />
                        </>
                      ))
                    ) : (
                      <>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                          <SkeletonComponent />
                        ))}
                      </>
                    )}
                    {/* </tbody> */}
                    <PolicyUploadAdmin
                      isModalOpen={isPolicyModalOpen}
                      setIsModalOpen={setIsPolicyModalOpen}
                      selectedItem={selectedMenu} // Pass the selected item's data
                      policyEnquiryId={policyEnquiryId}
                    />
                  </table>
                </>
              ) : null}

              {/* ==============life section of the dashboard=========== */}
              {[
                'lifePolicy',
                'twoWheelerRenew',
                'marineRenew',
                'otherRenew',
                'lifeRenew',
                'healthRenew',
              ].includes(selectedMenu) ? (
                <>
                  {selectedMenu === 'lifePolicy' && (
                    <p className="index">
                      Showing{' '}
                      {selectedMenu === 'lifePolicy'
                        ? quotationsList?.data?.length
                        : quotationsList?.data?.length}{' '}
                      of {quotationsList?.count}
                    </p>
                  )}
                  {selectedMenu !== 'lifePolicy' && (
                    <p className="index">Showing 0 of 0</p>
                  )}
                  <table className="fit_content">
                    <thead>
                      <tr className="entries-container ">
                        <th className="t-l entries-container">
                          <p>Customer ID</p>
                        </th>
                        {selectedMenu !== 'quote' && (
                          <th className="t-l entries-container">
                            <p>Plan Name</p>
                          </th>
                        )}
                        <th className="t-l entries-container">
                          <p>Policy Status</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Created Date</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Expiry Date</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Customer</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Mobile No.</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>Email Id</p>
                        </th>
                        <th className="t-l entries-container">
                          <p className="t-c">Amount</p>
                        </th>
                        <th className="t-l entries-container">
                          <p>
                            {selectedMenu === 'quote' ? 'Details' : 'Policy'}
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedMenu != 'lifePolicy' ? (
                        <>Data Not Found</>
                      ) : isFetching == false ? (
                        quotationsList?.data?.length > 0 &&
                        quotationsList?.data?.map((item, index) => (
                          <>
                            {item ? (
                              <LifeDataListItem
                                item={item}
                                index={index}
                                light={true}
                                selectedMenu={selectedMenu}
                                isQuotation={
                                  selectedMenu === 'lifePolicy' ? 1 : 0
                                }
                              />
                            ) : (
                              'Data Not Found'
                            )}
                          </>
                        ))
                      ) : (
                        <>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                            <SkeletonComponent />
                          ))}
                        </>
                      )}
                    </tbody>
                    <PolicyUploadAdmin
                      isModalOpen={isPolicyModalOpen}
                      setIsModalOpen={setIsPolicyModalOpen}
                      selectedItem={selectedMenu} // Pass the selected item's data
                      policyEnquiryId={policyEnquiryId}
                    />
                  </table>
                </>
              ) : null}

              {selectedMenu === 'rmpos' ? (
                <>
                  <div className="button_addrmpos">
                    {' '}
                    <h3>
                      Showing {usersList?.data?.length} of{' '}
                      {usersList?.totalCount} RM Data
                    </h3>
                    {/* <button className="add-rm"> ADD RM</button> */}
                  </div>

                  <div className="table_body">
                    <table className="fit_content">
                      <thead>
                        <tr className="entries-container ">
                          <th className="fit_heading">
                            <p>RM NAME</p>
                          </th>
                          <th className="fit_heading">
                            <p>MOBILE NO.</p>
                          </th>
                          <th className="fit_heading">
                            <p>EMAIL ID</p>
                          </th>
                          {/* <th className="fit_heading"><p>STATUS</p></th> */}
                          <th className="fit_heading">
                            <p>DETAILS</p>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {/* <p style={{ fontSize: "20px", margin: "0 auto" }}>Data Not Available</p> */}
                        {rmDetails}
                      </tbody>
                    </table>
                  </div>
                  {display === true && <RmModelData />}
                </>
              ) : null}

              {selectedMenu === 'pos' || selectedMenu === 'rmPos' ? (
                <>
                  <div className="button_addrmpos">
                    {' '}
                    <h3>
                      Showing {posApproved?.data?.length} of{' '}
                      {posApproved?.count} POS
                    </h3>{' '}
                    <button
                      style={{
                        display: selectedMenu == 'rmPos' ? 'none' : 'block',
                      }}
                      onClick={onClickAddPosHandler}
                    >
                      {' '}
                      ADD POS
                    </button>
                  </div>

                  <table className="fit_content">
                    <thead>
                      <tr className="entries-container ">
                        <th className="fit_heading">
                          <p>POS ID</p>
                        </th>
                        <th className="fit_heading">
                          <p>POS NAME</p>
                        </th>
                        <th className="fit_heading">
                          <p>MOBILE NO.</p>
                        </th>
                        <th className="fit_heading">
                          <p>EMAIL ID</p>
                        </th>
                        {/* <th className="fit_heading"><p>Policies Sold</p></th> */}
                        <th className="fit_heading">
                          <p>KYC Status</p>
                        </th>
                        {!(selectedMenu == 'rmPos') && (
                          <th className="fit_heading">
                            <p>Assigned RM</p>
                          </th>
                        )}
                        <th className="fit_heading">
                          <p>STATUS</p>
                        </th>
                        <th className="fit_heading">
                          <p></p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{newrow}</tbody>
                  </table>
                  {display === true ? (
                    <PosModelData
                      posData={posInfo}
                      modalCloses={modalCloses}
                      placeholder={placeholder}
                    />
                  ) : null}
                </>
              ) : null}

              {selectedMenu === 'requests' ? (
                <>
                  <div className="button_addrmpos">
                    {' '}
                    <h3>
                      Showing {posApproved?.data?.length} of{' '}
                      {posApproved?.count} POS Requests
                    </h3>{' '}
                    <button onClick={() => navigate('/partner')}>
                      {' '}
                      ADD POS
                    </button>
                  </div>

                  <table className="fit_content">
                    <thead>
                      <tr className="entries-container ">
                        {/* <th className="fit_heading"><p>POS ID</p></th> */}
                        <th className="fit_heading">
                          <p>POS NAME</p>
                        </th>
                        <th className="fit_heading">
                          <p>MOBILE NO.</p>
                        </th>
                        <th className="fit_heading">
                          <p>EMAIL ID</p>
                        </th>
                        <th className="fit_heading">
                          <p>STATUS</p>
                        </th>
                        <th className="fit_heading">
                          <p>Details</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{requestRow}</tbody>
                  </table>
                  {display === true && (
                    <RequestModelData
                      posInfo={posInfo}
                      isModalVisible={isModalVisible}
                      handleCancel={handleCancel}
                      rejectReason={rejectReason}
                      handleInputChange={handleInputChange}
                      handleOk={handleOk}
                      modalCloses={modalCloses}
                      downloadImage={downloadImage}
                      showModal={showModal}
                      approvePos={approvePos}
                      getFirstLetter={getFirstLetter}
                    />
                  )}
                </>
              ) : null}

              {selectedMenu === 'communication' ? (
                <div>
                  <EmailCommunication item={posUser?.data} />
                </div>
              ) : null}

              {selectedMenu === 'notification'
                ? notifications?.length > 0 &&
                  notifications.map((item, index) => {
                    if (index % 2 === 0) {
                      return <NotificationComp item={item} />;
                    } else {
                      return <NotificationComp item={item} light={true} />;
                    }
                  })
                : null}
              {(selectedMenu == 'quote' || selectedMenu == 'policy') &&
                quotationsList?.data?.length >= 0 && (
                  <Pagination
                    total={quotationsList.count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    getData={() =>
                      selectedMenu == 'policy' ? getPolicyData() : getData()
                    }
                  />
                )}
              {(selectedMenu == 'renew' ||
                selectedMenu == 'lifeRenew' ||
                selectedMenu == 'healthRenew') &&
                quotationsList?.data?.length >= 0 && (
                  <Pagination
                    total={quotationsList.count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPhealthRenewageSize={setPageSize}
                  />
                )}
              {['bike', 'marinePolicy', 'othersPolicy'].includes(
                selectedMenu
              ) &&
                quotationsList?.data?.length >= 0 && (
                  <Pagination
                    total={quotationsList.count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                  />
                )}
              {selectedMenu == 'healthPolicy' &&
                quotationsList?.data?.length >= 0 && (
                  <Pagination
                    total={quotationsList.count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                  />
                )}
              {selectedMenu == 'lifePolicy' &&
                quotationsList?.data?.length >= 0 && (
                  <Pagination
                    total={quotationsList.count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                  />
                )}
              {(selectedMenu == 'userQuote' || selectedMenu == 'userPolicy') &&
                quotationsList?.data?.length > 0 && (
                  <Pagination
                    total={quotationsList.count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    getData={() =>
                      selectedMenu == 'userQuote'
                        ? getUserData()
                        : getUserPolicy()
                    }
                  />
                )}

              {selectedMenu == 'userList' && filteredResults?.length > 0 && (
                <Pagination
                  total={usersList.totalCount}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              )}
              {selectedMenu == 'customer' &&
                quotationsList?.data?.length > 0 && (
                  <Pagination
                    total={quotationsList.count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                  />
                )}
              {selectedMenu == 'pos' && posApproved?.data?.length > 0 && (
                <Pagination
                  total={posApproved.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              )}
              {selectedMenu == 'rmpos' && usersList?.data?.length > 0 && (
                <Pagination
                  total={usersList.totalCount}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              )}
              {selectedMenu == 'requests' && posApproved?.data?.length > 0 && (
                <Pagination
                  total={posApproved.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              )}
              {selectedMenu == 'notification' && notifications.length > 0 && (
                <Pagination
                  total={0}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              )}
            </div>
          </div>
        </MainContainer>
      </>
    );
}
